/* eslint-disable */
import React from 'react';
import { Card, Table } from 'react-bootstrap'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'

const CommonTbl2 = () => {
    return (
        <div>
            <div className='custom-table-overview'>
                <Card>
                    {/* <div className='row'>
                        <div className='col-4 pe-0'>
                            <div className='header-1'>
                                <h6>Glass Cleaning</h6>
                            </div>
                            <div className='header-2'>
                                <h6>Glass Outside with Frame</h6>
                            </div>

                        </div>
                        <div className='col-4 p-0'>
                            <Table bordered className='mb-0'>

                                <thead className=''>
                                    <th colSpan={3} className='th-header '>Man Hour Cost Calculation</th>
                                    <tr>
                                        <th>Particular</th>
                                        <th>Hours</th>
                                        <th >Cost</th>
                                    </tr>
                                </thead>
                                <tbody className='table-with-radius'>

                                    <tr >
                                        <td>Each</td>
                                        <td>2</td>
                                        <td>€ 100</td>
                                    </tr>
                                    <tr >
                                        <td>Yearly </td>
                                        <td>24</td>
                                        <td>€ 20</td>
                                    </tr>


                                </tbody>
                            </Table>

                        </div>
                        <div className='col-4 p-0'>
                            <Table bordered className='mb-0 pe-0'>

                                <thead className=''>
                                    <th colSpan={2} className='th-header2 py-3 px-0'>{" "}</th>
                                 
                                </thead>
                                
                            </Table>

                        </div>

                    </div> */}
                    <div className='row mx-0'>
                        <div className='col-4 p-0'>
                            <Table bordered className='mb-0'>
                                <thead className=''>
                                    <th colSpan={2} className='th-header  px-2'>Interval Cleaning</th>

                                </thead>
                                <thead className=''>
                                    <th colSpan={2} className='th-header-3 py-3 px-0'>{" "}</th>

                                </thead>

                            </Table>
                        </div>
                        <div className='col-4 p-0'>
                            <Table bordered className='mb-0'>

                                <thead className=''>
                                    <th colSpan={3} className='th-header '>Man Hour Cost Calculation</th>
                                    <tr className='th-header-3'>
                                        <th>Particular</th>
                                        <th>Hours</th>
                                        <th >Cost</th>
                                    </tr>
                                </thead>
                                <tbody className='table-with-radius'>

                                    <tr >
                                        <td>Each</td>
                                        <td>2</td>
                                        <td>€ 100</td>
                                    </tr>
                                    <tr >
                                        <td>Yearly </td>
                                        <td>24</td>
                                        <td>€ 20</td>
                                    </tr>


                                </tbody>
                            </Table>
                        </div>
                        <div className='col-4 p-0'>
                            <Table bordered className='mb-0'>
                                <thead className=''>
                                    <th colSpan={2} className='th-header2 py-3 px-0'>{" "}</th>

                                </thead>
                                <thead className=''>
                                    <th colSpan={2} className='th-header-3 py-3 px-0'>{" "}</th>

                                </thead>

                            </Table>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default CommonTbl2