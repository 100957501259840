/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import Button from '../../../Common/Button';
import PrimeReactPage from '../../../Common/quill/PrimeReactPage';
import { toast } from 'react-toastify';
import Icon from '../../../Common/Icon';
import App_url from '../../../Common/Constants';
import { PostRequestAPI } from '../../../../Api/api/PostRequest';
import { useSelector } from 'react-redux';

export default function AttachmentEditor(props) {
    const [UploadFile, setUploadFile] = useState([]);
    const [loader, setLoader] = useState(false);
    const {access_token} = useSelector(App_url.userDataReducers);
    const [formData, setFormData] = useState({
        notes:""
    })
    const [error, setError] = useState({
        subject: "",
        msg: "",
        email: "",
    })
    const refs = useRef({
        refUpload: null,
        refBold: null,
        refItalic: null,
        refUnderline: null,
        refOrderedList: null,
        refUnOrderedList: null,
        refImage: null,
        refLink: null
    });



    const handleFileSelect = (event) => {
        const files = event.target.files;
        handleUploadedFiles(files);

    };

    const handleUploadedFiles = async (files) => {
        const validFileTypes = ['csv', 'xls', 'xlsx', 'pdf', 'pptx', 'png', 'jpg', 'jpeg'];

        Array.from(files).forEach(async (file) => {
            if (file) {
                const fileName = file.name;
                const fileType = fileName.split('.').pop().toLowerCase();

                if (validFileTypes.includes(fileType)) {
                    // Create a new FormData object
                    const formData = new FormData();
                    formData.append('file', file); // Append the file to the form data
                    // console.log("formDataS", [...formData]);

                    try {
                        // Send the form data using your API function
                        const response = await PostRequestAPI(App_url.api.FILE_UPLOAD, formData, access_token, true);
                        console.log("responseFileUpload", response);
                        if (response?.status === 200) {
                            setUploadFile(prevFiles => [...prevFiles, response?.data]);
                            props?.handleUploads(response?.data?.id);
                            // setUploadFileIds(preFileIds => [...preFileIds, response?.data?])
                        }
                    } catch (error) {
                        console.error("File upload failed", error);
                    }
                } else {
                    toast.error("Invalid file format");
                }
            }
        });
    };
    // console.log("uploads", UploadFile);

    const handleDeleteAttachment = (file) => {
        const filterUploadFile = UploadFile.filter((item) => item?.id !== file?.id);
        setUploadFile(filterUploadFile);
    };
    const callIcons = (attachment) =>{
        const extension = attachment?.split('.').pop();
        console.log("attachment", extension);
        if(extension === "pdf"){
            return(
                <Icon attrIcon={App_url.img.PdfIcon2} image className='sm me-1 ms-1 d-flex-center' />
            )
        }else if(extension === "jpg" || extension === "jpeg" || extension === "png"){
            return(
                <Icon attrIcon={App_url.img.GalleryIcon2} image className='sm me-1 ms-1 d-flex-center' />
            )
        }else if(extension === "xlsx" || extension === "csv"){
            return(
                <Icon attrIcon={App_url.img.ExcelIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        }else{
            return(
                <Icon attrIcon={App_url.img.TEXTIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        }
    }
    return (
        <React.Fragment>
            <div className='prime-react-container'>
                {loader && <h6 className='ms-2'>Please wait while the file is uploading...</h6>}
                {UploadFile?.length > 0 && (
                    <React.Fragment>
                        <h6 className='attachments-text'>Attachments</h6>
                      <div className='email-file-preview-box'>
                      {UploadFile.map((file, index) => (
                            <div key={index} className='email-file-preview mb-2'>
                                <div className='email-file-name'>
                                    {callIcons(file?.name || file?.document_type)}
                                    <span className='email-attachment-name-ellipsis'>{file?.name || file?.document_name || ""}</span>
                                </div>
                                <div
                                    className='email-delete-icon'
                                    onClick={() => handleDeleteAttachment(file)}
                                >
                                    <Icon
                                        image
                                        attrIcon={App_url.img.DeleteIcon}
                                        className='sm ms-4 me-1 d-flex-center'
                                    />
                                </div>
                            </div>
                        ))}
                      </div>
                    </React.Fragment>
                )}
                <PrimeReactPage handleQuillData={props?.handleQuillDataForNotes} refs={refs} />
            </div>
            <div className='prime-header-icon-box'>
                <input ref={el => refs.current.refUpload = el} type='file' onChange={handleFileSelect} id='input_file' hidden />
                <Button onClick={() => refs?.current?.refUpload?.click()} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon image className='' attrIcon={App_url.img.AttachmentIcon2} /></Button>
                <Button onClick={() => refs?.current?.refImage?.click()} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon image className='' attrIcon={App_url.img.ImageEditorIcon} /></Button>
                <Button onClick={() => refs?.current?.refBold?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.BoldIcon} /></Button>
                <Button onClick={() => refs?.current?.refItalic?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.ItalicIcon} /></Button>
                <Button onClick={() => refs?.current?.refUnderline?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.UnderlineIcon} /></Button>
                <Button onClick={() => refs?.current?.refOrderedList?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.OrderedListIcon} /></Button>
                <Button onClick={() => refs?.current?.refUnOrderedList?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.UnorderedListIcon} /></Button>

            </div>
        </React.Fragment>
    )
}
