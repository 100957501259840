/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Card, CardGroup, Col, Nav, Row } from 'react-bootstrap';
import Statistics from './Statistics';
import AppointmentCalls from './AppointmentCalls';
import App_url from '../Common/Constants';
import Icon from '../Common/Icon';
import StatsCard from './StatsCard';
import DatePicker from 'react-datepicker';
import { WebSocketContext } from '../../WSContext';
import { useDispatch, useSelector } from 'react-redux';
import wsSendRequest from '../../socketIO/wsSendRequest';
import Utils from '../Common/Utils';

export default function DashboardPage({ props }) {
  const ws = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const {device_id } = useSelector(App_url.userDataReducers);
  const [activeTab, setActiveTab] = useState('Today');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const handleSelect2 = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const callAppointmentList = () => {
    const param = {
      transmit: "broadcast",
      url: "appointment_list",
      request: {
        status: "",
        year_filter: "",
        limit: "500",
        page_no: "1",
        search: "",
        order_by: "-updated_at",
        // customer_id: "",
      },
    };
    wsSendRequest(ws, param, device_id)
  }
  const callScheduleList = () => {
    const param = {
      transmit: "broadcast",
      url: "schedule_list",
      request: {
        limit: "500",
        page_no: "1",
        search: "",
        order_by: "-updated_at",
        // customer_id: "",
        form_date: "",
        form_date: "",
        type: "",
      },
    };
    wsSendRequest(ws, param, device_id)
  }
  useEffect(() => {
    callAppointmentList();
    callScheduleList();

  }, [ws])


  const tabsData = [
    { eventKey: 'today', label: 'Today' },
    { eventKey: 'last-week', label: 'Last Week' },
    { eventKey: 'last-month', label: 'Last Month' },
    { eventKey: 'last-year', label: 'Last    Year' },
    { eventKey: 'tomorrow', label: 'Tomorrow' },
    { eventKey: 'next-week', label: 'Next Week' },
    { eventKey: 'next-month', label: 'Next Month' },
    { eventKey: 'next-year', label: 'Next Year' },
  ];
  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };



  return (
    <ContentLayout title={"User Management"} className='dashboard '>

      <div className='header'>
        <div>
          <Nav variant="" className="custom-nav-tabs " activeKey={activeTab} onSelect={handleSelect} >
            {tabsData.map((tab) => (
              <Nav.Item key={tab.eventKey} className="rounded">
                <Nav.Link eventKey={tab.eventKey} className="py-1">{tab.label}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <div className='header-data'>
          <div className='start-date'>
            <DatePicker
              selected={startDate}
              onChange={(dates) => {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              monthsShown={2}
              placeholderText={''}
              customInput={
                <div className="custom-input">
                  <span className=''><Icon className={"header-calendar-icon"} attrIcon={App_url.img.CalendarIcon} image />{startDate ? startDate.toLocaleDateString() : 'Start Date'}</span>
                  {' | '}
                  <span className=''><Icon className={"header-calendar-icon"} attrIcon={App_url.img.CalendarIcon} image /> {endDate ? endDate.toLocaleDateString() : 'End Date'}</span>
                </div>
              }
            />

          </div>
          {/* <div className='end-date'>
            <span><Icon className={"header-calendar-icon"} attrIcon={App_url.img.CalendarIcon} image /></span>

          </div> */}
        </div>

      </div>
      <CardGroup className="mt-3 rounded">

        <Card className=''>
          <Card.Body className="cardData">
            <div className='cardsubdata '>
              <h6 className="dashTitle mb-0">No Of Calls</h6>
              <span className="number">76 <span className='dashSub2'>+10</span></span>
              <p className="dashSub mb-0">compared to today</p>
            </div>
            <div className="chart-container2">
              <StatsCard
                data={data}
                backgroundColor="#FEEBE6"
                borderColor="#F9623E"
              />
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="cardData">
            <div className='cardsubdata '>
              <h6 className="dashTitle mb-0">No Of Appointment</h6>
              <span className="number">12 <span className='dashSub3'>-8</span></span>
              <p className="dashSub mb-0">compared to today</p>
              <img src="attachments/viva_insights_mail_unsubscribe_2x.png" alt="" />
            </div>
            <div className="chart-container2">
              <StatsCard
                data={data}
                backgroundColor="#EAFAEA"
                borderColor="#5EC15C"
              />
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="cardData">
            <div className='cardsubdata '>
              <h6 className="dashTitle mb-0">Share Brochure</h6>
              <span className="number">234 <span className='dashSub2'>+10</span></span>
              <p className="dashSub mb-0">compared to today</p>
            </div>
            <div className="chart-container2">
              <StatsCard
                data={data}
                backgroundColor="#EAE8FF"
                borderColor="#5F51FF"
              />
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="cardData">
            <div className='cardsubdata '>
              <h6 className="dashTitle mb-0">No Of Offers</h6>
              <span className="number">324 <span className='dashSub2'>+10</span></span>
              <p className="dashSub mb-0">compared to today</p>
            </div>

            <div className="">
              <StatsCard
                data={data}
                backgroundColor="#FFE0FA"
                borderColor="#FF61E6"
              />
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="cardData">
            <div className='cardsubdata '>
              <h6 className="dashTitle mb-0">No Of Mail</h6>
              <span className="number">124 <span className='dashSub2'>+10</span></span>

              <p className="dashSub mb-0">compared to today</p>
            </div>
            <div className="chart-container2">
              <StatsCard
                data={data}
                backgroundColor="#FFF2D8"
                borderColor="#FFA800"
              />
            </div>
          </Card.Body>

        </Card>
      </CardGroup>
      <Row className="mt-3">
        <Col md={7}>
          <Statistics />
        </Col>
        <Col md={5}>
          <AppointmentCalls />
        </Col>
      </Row>
    </ContentLayout>
  )
}
const data = {
  labels: ['', '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM'],
  datasets: [
    {
      label: 'Number of Calls',
      data: [0, 29, 20, 45, 40, 65, 55, 70],
    },
  ],
};

