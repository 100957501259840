
/* eslint-disable */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import App_url from '../../../Common/Constants';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import Icon from '../../../Common/Icon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Scrollbar from '../../../Common/Scrollbar';
import InputGroup from '../../../Common/InputGroup';
import Utils from '../../../Common/Utils';
import PrimeReactPage from '../../../Common/quill/PrimeReactPage';
import AttachmentEditor from './AttachmentEditor';
import { setNewAppointmentModal, setStoreInviteeInfoModal, setStoreInviteeModalTwoPopup } from '../../../../store/modules/modals/action';
import { PostRequestAPI } from '../../../../Api/api/PostRequest';
import { setStoreCreateAppointment, setStoreSelectedInvitees, setStoreUserOutlookDetails } from '../../../../store/modules/users_data/action';
import { useMsal } from '@azure/msal-react';
import ReactSelect from 'react-select';
import wsSendRequest from '../../../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../../../WSContext';
import { toast } from 'react-toastify';
import UtilsGetList from '../../../Common/UtilsGetList';

export default function NewAppointment(props) {
    const dispatch = useDispatch();
    const ws = useContext(WebSocketContext);
    const { device_id, access_token, currentUser} = useSelector(App_url.userDataReducers);
    const { ModalPopup } = useSelector(App_url.modalReducers);
    const { customerDetails, customersList } = useSelector(App_url.customerReducers);
    
    const [search, setSearch] = useState(props?.filter?.search);
    const [loader, setLoader] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [rangeEndDate, setRangeEndDate ] = useState(new Date());
    const [rangeEndTime, setRangeEndDateTime ] = useState(new Date());

    const [travelTime, setTravelTime] = useState('None');
    const [repeat, setRepeat] = useState('None');
    const [endRepeat, setEndRepeat] = useState('Never');
    const [alert, setAlert] = useState('None');
    const [secondAlert, setSecondAlert] = useState('None');
    const [selectedInvitees, setSelectedInvitees] = useState([]);

    
    const customersListOptions = customersList?.data?.map((item)=>{
        return {
            label:`${item?.customer_name} ${item?.last_name ? item?.last_name:""}`, 
            value:item?.id, 
            email:item?.email
        }
    })
    
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [formData, setFormData] = useState({
        subject: `Meeting with ${customerDetails[0]?.company_name}`,
        start_time: null,
        end_time: null,
        location: customerDetails[0]?.company_address,
        customer_id: customerDetails[0]?.id,
        is_all_day: false,
        is_reminder_on: false,
        reminder_minutes: 0,
        is_second_reminder_on:false,
        reminder_minutes_second_start: 0,
        notes: "",
        web_link: "",
        recurrence:null,
        attendees: [],
        assign_appointment: [],
        attachments_ids: [],
    })

    // console.log("formData", formData);

    const TravelTimeOptions = [
        { label: "None", value: "None" },
        { label: "5 minutes", value: "5 minutes" },
        { label: "15 minutes ", value: "15 minutes " },
        { label: "30 minutes", value: "30 minutes" },
        { label: "1 hour", value: "1 hour" },
        { label: "1 hour , 30 minutes", value: "1 hour , 30 minutes" },
        { label: "2 hours", value: "2 hours" },
    ]
    const RepeatOptions = [
        { label: "None", value: "None" },
        { label: "Every Day", value: "Every Day" },
        { label: "Every Week ", value: "Every Week" },
        { label: "Every 2 Week", value: "Every 2 Week" },
        { label: "Every Month", value: "Every Month" },
        { label: "Every Year", value: "Every Year" },
    ]
    const EndRepeatOptions = [
        { label: "Never", value: "Never" },
        { label: "On Date", value: "On Date" },
    ]
    const AlertOptions = [
        { label: "None", value: "None" },
        { label: "At time of event", value: "At time of event" },
        { label: "5 minutes before", value: "5 minutes before" },
        { label: "15 minutes before", value: "15 minutes before" },
        { label: "30 minutes before", value: "30 minutes before" },
        { label: "1 hour before", value: "1 hour before" },
        { label: "2 hours before", value: "2 hours before" },
        { label: "1 day before", value: "1 day before" },
        { label: "2 days before", value: "2 days before" },
        { label: "1 week before", value: "1 week before" },
    ]
    const handleTravelSelect = (eventKey) => {
        setTravelTime(eventKey);
    };
    const handleRepeatSelect = (eventKey) => {
        setRepeat(eventKey);
        switch (eventKey) {
            case "None": 
                setFormData((data)=>({...data, recurrence:null}));
                break;
            case "Every Day": 
                setFormData((data)=>({...data, recurrence:{...data?.recurrence, pattern:{type:"daily", interval:1, daysOfWeek:[]}, range:{type:"endDate", startDate: Utils.formatOnlyDate(startDate), endDate: Utils.formatOnlyDate(endDate)}}}));
                break;
            case "Every Week":{
                const daysOfWeek = new Date(Utils.formatOnlyDate(startDate)).toLocaleDateString('en-Us', { weekday: 'long' });
                setFormData((data)=>({...data, recurrence:{...data?.recurrence, pattern:{type:"weekly", interval:1, daysOfWeek:[daysOfWeek]}, range:{type:"endDate", startDate: Utils.formatOnlyDate(startDate), endDate: Utils.formatOnlyDate(endDate)}}}));
                break;
            }
            case "Every 2 Week": {
                const daysOfWeek = new Date(Utils.formatOnlyDate(startDate)).toLocaleDateString('en-Us', { weekday: 'long' });
                setFormData((data)=>({...data, recurrence:{...data?.recurrence, pattern:{type:"weekly", interval:2, daysOfWeek:[daysOfWeek]}, range:{type:"endDate", startDate: Utils.formatOnlyDate(startDate), endDate: Utils.formatOnlyDate(endDate)}}}));
                break;
            }
            case "Every Month": {
                const dayOfMonth = new Date(Utils.formatOnlyDate(startDate)).getDate();
                setFormData((data)=>({...data, recurrence:{...data?.recurrence, pattern:{type:"absoluteMonthly", interval:1, dayOfMonth:dayOfMonth}, range:{type:"endDate", startDate: Utils.formatOnlyDate(startDate), endDate: Utils.formatOnlyDate(endDate)}}}));
                break;
            }
            case "Every Year": {
                const dayOfMonth = new Date(Utils.formatOnlyDate(startDate)).getDate();
                const month = new Date(Utils.formatOnlyDate(startDate)).getMonth() + 1;
                setFormData((data)=>({...data, recurrence:{...data?.recurrence, pattern:{type:"absoluteYearly", interval:1, dayOfMonth:dayOfMonth, month:month}, range:{type:"endDate", startDate: Utils.formatOnlyDate(startDate), endDate: Utils.formatOnlyDate(endDate)}}}));
                break;
            }

            default:  setFormData((data)=>({...data, recurrence:null}));;
                break;
        }
    };
    const handleEndRepeatSelect = (eventKey) => {
        setEndRepeat(eventKey);
    };
    const handleAlertSelect = (eventKey) => {
        setAlert(eventKey);
        
        switch (eventKey) {
            case "None":
                setFormData((data) => ({ ...data, is_reminder_on: false, reminder_minutes: null, is_second_reminder_on:false, reminder_minutes_second_start:null }));
                setSecondAlert(AlertOptions[0]?.value);
                break;
            case "At time of event":
                setFormData((data) => ({ ...data, is_reminder_on: true, reminder_minutes: 0, }));
                break;
            case "5 minutes before":
                setFormData((data) => ({ ...data, is_reminder_on: true, reminder_minutes: 5, }));
                break;
            case "15 minutes before":
                setFormData((data) => ({ ...data, is_reminder_on: true, reminder_minutes: 15, }));
                break;
            case "30 minutes before":
                setFormData((data) => ({ ...data, is_reminder_on: true, reminder_minutes: 30, }));
                break;
            case "1 hour before":
                setFormData((data) => ({ ...data, is_reminder_on: true, reminder_minutes: 60, }));
                break;
            case "2 hours before":
                setFormData((data) => ({ ...data, is_reminder_on: true, reminder_minutes: 120, }));
                break;
            case "1 day before":
                setFormData((data) => ({ ...data, is_reminder_on: true, reminder_minutes: 24 * 60, }));
                break;
            case "2 days before":
                setFormData((data) => ({ ...data, is_reminder_on: true, reminder_minutes: 2 * 24 * 60, }));
                break;
            case "1 week before":
                setFormData((data) => ({ ...data, is_reminder_on: true, reminder_minutes: 7 * 24 * 60, }));
                break;
            default:
                setFormData((data) => ({ ...data, is_reminder_on: false, reminder_minutes: null, }));
                break;
        }
    };
    const handleSecondAlertSelect = (eventKey) => {
        setSecondAlert(eventKey);
        switch (eventKey) {
            case "None":
                setFormData((data) => ({ ...data, is_second_reminder_on: false, reminder_minutes_second_start: null, }));
                break;
            case "At time of event":
                setFormData((data) => ({ ...data, is_second_reminder_on: true, reminder_minutes_second_start: 0, }));
                break;
            case "5 minutes before":
                setFormData((data) => ({ ...data, is_second_reminder_on: true, reminder_minutes_second_start: 5, }));
                break;
            case "15 minutes before":
                setFormData((data) => ({ ...data, is_second_reminder_on: true, reminder_minutes_second_start: 15, }));
                break;
            case "30 minutes before":
                setFormData((data) => ({ ...data, is_second_reminder_on: true, reminder_minutes_second_start: 30, }));
                break;
            case "1 hour before":
                setFormData((data) => ({ ...data, is_second_reminder_on: true, reminder_minutes_second_start: 60, }));
                break;
            case "2 hours before":
                setFormData((data) => ({ ...data, is_second_reminder_on: true, reminder_minutes_second_start: 120, }));
                break;
            case "1 day before":
                setFormData((data) => ({ ...data, is_second_reminder_on: true, reminder_minutes_second_start: 24 * 60, }));
                break;
            case "2 days before":
                setFormData((data) => ({ ...data, is_second_reminder_on: true, reminder_minutes_second_start: 2 * 24 * 60, }));
                break;
            case "1 week before":
                setFormData((data) => ({ ...data, is_second_reminder_on: true, reminder_minutes_second_start: 7 * 24 * 60, }));
                break;
            default:
                setFormData((data) => ({ ...data, is_second_reminder_on: false, reminder_minutes_second_start: null, }));
                break;
        }
    };
    const handleInviteeModal = () => {
        dispatch(setStoreInviteeModalTwoPopup({
            show: "INVITEE_MODAL",
            callBackModal:(invitees)=>handleSelectedInvitees(invitees),
        }))
    }

    const handleSelectedInvitees = (invitees) =>{
        console.log("selectedInvitees",invitees)
        setSelectedInvitees(invitees);
        const InviteIds = invitees.map(invitee => invitee.id);
        setFormData((data)=>({
            ...data,
            assign_appointment:InviteIds,
            attendees:invitees.map((invitee)=>({
                emailAddress:{
                    address:invitee.email
                },
                type:"required",
            }))

        }))
    }
    const handleInviteeInfo = (item) => {
        dispatch(setStoreInviteeInfoModal({
            show: "INVITEE_INFO_MODAL",
            data:{item:item, startDate:startDate}
     
        }))
    }

    const checkAvailability = (startTime, endTime) =>{
        const param = {
          transmit: "broadcast",
          url: "user_availability",
          request: {
            start_time: startTime,
            end_time: endTime
          }, 
          toast:true,
        };
        wsSendRequest(ws, param, device_id);
    }

    const handleSelectedDateTime = (dateTime, type) =>{
        if(type === "startDate"){
            setSelectedInvitees([])
          if(formData?.is_all_day){
            setStartDate(dateTime);
            setEndDate(dateTime);
            setFormData((data)=>({
                ...data,
                start_time: Utils.formatToISOLocal(dateTime),
                end_time: Utils.formatToISOLocal(dateTime),
            }))
          }else{
            setStartDate(dateTime);
            setEndDate(dateTime);
            const endTime = new Date(dateTime);
            endTime.setTime(endTime.getTime() + 30 * 60000);
            setEndTime(endTime);
            checkAvailability(Utils.formatToISOLocal(dateTime), Utils.formatToISOLocal(endTime));
            setFormData((data)=>({
                ...data,
                start_time: Utils.formatToISOLocal(dateTime),
                end_time: Utils.formatToISOLocal(endTime),
            }))
          }
        }
        if (type === "startTime") {
            setSelectedInvitees([])
            setStartTime(dateTime);
            const endTime = new Date(dateTime);
            endTime.setTime(endTime.getTime() + 30 * 60000); 
            setEndTime(endTime);
            const start_date = Utils.formatOnlyDate(startDate);
            const start_time = Utils.formatOnlyTime(dateTime);
            const end_date = Utils.formatOnlyDate(endDate);
            const end_time = Utils?.formatOnlyTime(endTime);
            checkAvailability(Utils.concatDateTime(start_date, start_time), Utils.concatDateTime(end_date, end_time));
            setFormData((data) => ({
                ...data,
                start_time: Utils.concatDateTime(start_date, start_time),  
                end_time: Utils.concatDateTime(end_date, end_time),
            }));
        }
        if(type === "endDate"){
            const startTime = formData?.start_time;
            const dt = Utils.formatToISOLocal(dateTime);
           if(dt < startTime){
                toast.error("End date can't be less than Start date");
           }else{
              if(formData?.is_all_day){
                setEndDate(dateTime);
                setFormData((data)=>({
                    ...data,
                    end_time: Utils.formatToISOLocal(dateTime),
                   
                }))

              }else{
                setEndDate(dateTime);
                const end_date = Utils.formatOnlyDate(dateTime);
                const end_time = Utils.extractTime(formData?.end_time);
                setFormData((data)=>({
                    ...data,
                    end_time: Utils.concatDateTime(end_date, end_time),

                }))
              }
           }
        }
        if(type === "endTime"){
            setEndTime(dateTime);
            const end_date = Utils.formatOnlyDate(endDate);
            const end_time = Utils.formatOnlyTime(dateTime);
            setFormData((data)=>({
                ...data,
                end_time: Utils.concatDateTime(end_date, end_time),
            }))
        }
        if(type === "repeatEndDate"){
            setRangeEndDate(dateTime);
            const startTime = formData?.start_time;
            const dt = Utils.formatToISOLocal(dateTime);
           if(dt < startTime){
                toast.error("End date can't be less than Start date");
           }else{
               setFormData((data)=>({
                   ...data,
                   recurrence:{
                       ...data?.recurrence,
                       range:{
                           ...data?.recurrence?.range,
                           startDate: Utils.formatOnlyDate(startDate),
                           endDate: Utils.formatOnlyDate(dateTime),
                        }
                   }
               }))
           }
        }
        if(type === "repeatEndTime"){
            setRangeEndDateTime(dateTime);
            const end_time = Utils?.formatOnlyTime(dateTime);
            const end_date = Utils?.formatOnlyDate(rangeEndDate)
            setFormData((data)=>({
                ...data,
                recurrence:{
                    ...data?.recurrence,
                    range:{
                        ...data?.recurrence?.range,
                        endDate:Utils.concatDateTime(end_date, end_time),
                     }
                }
            }))
        }
    }

    const handleIsAllDay = (e) => {
        if (e.target.checked) {
            const end_date = endDate.setDate(endDate.getDate() + 1);
            const start_time = Utils.formatOnlyDate(startDate);
            const end_time = Utils.formatOnlyDate(end_date);
            setFormData((data) => ({
                ...data,
                is_all_day:true,
                start_time: `${start_time}T00:00:00Z`,
                end_time: `${end_time}T00:00:00Z`
            }))
        } else {
            setStartTime(new Date());
            setEndTime(new Date());
            setFormData((data) => ({
                ...data,
                is_all_day:false,
                start_time: Utils.formatToISOLocal(new Date()),
                end_time: Utils.formatToISOLocal(new Date()),
            }))
        }

    }   
    
    const onChangeFilter = (e) => {
        if (e.target.name === "search") {
            setSearch(e.target.value)
            setShowOptions(true);
        } else {
            props?.setFilter((data) => ({
                ...data,
                [e.target.name]: e.target.value
            }));
        }
    }
    const callSearch = (data) => {
        setSearch(data);
    }



    const callUserListApi = (startTime, endTime) =>{
        const param = {
          transmit: "broadcast",
          url: "user_list",
          request: {
            status: true,
            limit: "500",
            page_no: "1",
            search: "",
            order_by: "-updated_at",
            start_time: startTime,
            end_time: endTime
          }
        };
        wsSendRequest(ws, param, device_id)
    }
    useEffect(()=>{
        if(ws && ModalPopup?.show === "NEW_APPOINTMENT_MODAL" && formData?.start_time && formData?.end_time){
            callUserListApi(formData?.start_time, formData?.end_time);
        }
    }, [ws, ModalPopup?.show === "NEW_APPOINTMENT_MODAL", formData?.start_time, formData?.end_time])

    useEffect(()=>{
        if(ModalPopup?.show === "NEW_APPOINTMENT_MODAL"){
            setStartDate(new Date());
            setEndDate(new Date());
            setStartTime(new Date());
            setEndTime(new Date());
        }
    },[ModalPopup?.show === "NEW_APPOINTMENT_MODAL"])

    useEffect(()=>{
        if(ModalPopup?.data?.event === "meeting_event" && selectedCustomer){
            callCustomerDetailsApi(selectedCustomer)
        }
    },[ModalPopup?.data?.event === "meeting_event", selectedCustomer])

    const callCustomerDetailsApi = (customerId) => {
        const param = {
          transmit: "broadcast",
          url: "customer_detail",
          request: { customer_id: customerId }
        };
        wsSendRequest(ws, param, device_id);
      }

    const handleQuillDataForNotes = (message) =>{
        setFormData((data)=>({
            ...data,
            notes:message,
        }))
    }
    const handleUploads = (Ids) => {
        setFormData((data)=>({
            ...data,
            attachments_ids:[...data?.attachments_ids, Ids]
        }))
    }

    const validation = () =>{
        let val = true;

        return true;
    }

    const onSubmit = async (e) =>{
        e.preventDefault();
        if(validation()){
            setLoader(true);
            const payload = formData;
            if(ModalPopup?.data?.event === "personal_event" || ModalPopup?.data?.event === "meeting_event"){
                payload.customer_id = ""
            }
            console.log("payload", payload);
            const response = await PostRequestAPI(App_url.api.CREATE_APPOINTMENT, payload, access_token);
            setLoader(false);
            console.log("response", response);
            if(response?.status === 200){
                toast.success(response?.data?.message);
                UtilsGetList.callAppointmentList({ws, device_id, customerId:`${ModalPopup?.data?.event === "personal_event" ? "":customerDetails[0]?.id}`});
                CloseData();
            }else{
                Utils.AuthenticateResponse(response, dispatch);
                CloseData();
            }
        }else{
            console.log("validation error")
        }
    }

    const handleClose = async () => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(ModalPopup?.data);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setNewAppointmentModal())
        setSelectedInvitees([]);
        setTravelTime('None');
        setRepeat('None');
        setEndRepeat('None');
        setAlert('None');
        setSecondAlert('None');
        setFormData((data)=>({
            ...data,
            is_all_day:false,
        }))
    }


    if (ModalPopup?.show !== "NEW_APPOINTMENT_MODAL") {
        return null
    }


    return (
        <Modal show={true} centered size="lg" className='new-appointment-modal-dialog'>
            <Scrollbar style={{ height: "calc(100vh - 20px)" }}>
                <Modal.Body className='modal-body new-appointment-modal'>
                    <div className='header-container'>
                        <h6 className='header-title'>{ModalPopup?.data?.AptTitle ? ModalPopup?.data?.AptTitle : "New Appointment"}</h6>
                        <div className='header-button-save-close'>
                            <Button onClick={onSubmit} variant={"primary save-appointment-btn"}>{loader ? <span>Please wait <Icon className={"bg-white text-white"}  attrIcon={App_url.img.LoaderIcon} /></span>:"Save"}</Button>
                            <Icon onClick={CloseData} className={"ms-1 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                            {/* <Button onClick={handleClose} className='close-appointment-btn' variant={"primary"}><Icon className={"bg-white close-icon "} attrIcon={App_url.img.CloseIcon} /></Button> */}
                        </div>
                    </div>
                    {ModalPopup?.data?.event === "meeting_event" &&
                        <div className=' meetingevent '>
                            <InputGroup
                                placeholder={"Search by company, person name"}
                                name={"customer_company_name"}
                                formClassName={""}
                                value={selectedCustomer}
                                onChange={(e)=>setSelectedCustomer(e.target.value)}
                                option={customersListOptions}
                                reactSelectData={{ leftData:true, rightData:true }}
                                select 
                                type={"select"}
                            />
                        </div>
                    }

                    <div className='company-info-box'>
                        <div className='company-name'>
                            <div className='company-icon'><Icon image attrIcon={App_url.img.CirclePlusIcon} /></div>
                            <div className='company-title'><h6>{ModalPopup?.data?.event === "personal_event" ? currentUser?.user?.full_name : customerDetails[0]?.company_name}</h6></div>
                        </div>
                        {ModalPopup?.data?.AptTitle !== "Personal Event" &&
                            <div className='company-email'>
                                <div className='company-icon'><Icon image attrIcon={App_url.img.BuildingIcon} /></div>
                                <div className='company-title'><h6>{customerDetails[0]?.email}</h6></div>
                            </div>
                        }
                        {(customerDetails[0]?.company_address || ModalPopup?.data?.event === "meeting_event" || (ModalPopup?.data?.event === "personal_event"  && currentUser?.user?.address) ) && <div className='company-address'>
                            <div className='company-icon'><Icon image attrIcon={App_url.img.WorldLocationIcon} /></div>
                            <div className='company-title'><h6>{`${ModalPopup?.data?.event === "personal_event" ? currentUser?.user?.address : `${customerDetails[0]?.company_address}, ${customerDetails[0]?.street_no}, ${customerDetails[0]?.postal_code}`}`}</h6></div>
                        </div>}
                    </div>
                    {ModalPopup?.data?.AptTitle !== "Personal Event" &&
                        <div className='user-info-box'>
                            <div className='user-name'>
                                <div className='user-icon'><Icon image attrIcon={App_url.img.UserPlusIcon} /></div>
                                <div className='user-title'><h6>{customerDetails[0]?.customer_name}</h6></div>
                            </div>
                            <div className='user-email'>
                                <div className='user-icon'><Icon image attrIcon={App_url.img.EmailIcon} /></div>
                                <div className='user-title'><h6>{customerDetails[0]?.email}</h6></div>
                            </div>
                        </div>
                    }
                    <div className='date-time-info-box'>
                        <div className='date-time-first'>
                            <div className='date-time-icon'><Icon image attrIcon={App_url.img.CalendarTwoIcon} /></div>
                            <div className='date-time-start-box'>
                                <h6 className='title'>Starts</h6>
                                <div className='custom-date-box'>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => handleSelectedDateTime(date, "startDate")}
                                        dateFormat="dd MMM yyyy"
                                        placeholderText={"Start Date"}
                                        name={"startDate"}
                                        value={new Date()}
                                        className={"start-date "}
                                        wrapperClassName='custom-date-time-wrapper'
                                        minDate={new Date()}
                                    />
                                </div>
                                {!formData?.is_all_day && (
                                    <div className='custom-time-box'>
                                        <DatePicker
                                            name='start_time'
                                            selected={startTime}
                                            onChange={(time) => handleSelectedDateTime(time, "startTime")}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            placeholderText="Select Time"
                                            className="custom-time-picker"
                                            wrapperClassName="custom-time-picker-wrapper"
                                        />
                                    </div>
                                )}
                                <h6 className='ms-2'>All-day</h6>
                                <InputGroup type="switch" name={"status"} checked={formData?.is_all_day} onCheck={(e) => handleIsAllDay(e)} />
                            </div>
                        </div>
                        <div className='date-time-second'>
                            <div className='date-time-icon'><Icon image attrIcon={App_url.img.CalendarTwoIcon} /></div>
                            <div className='date-time-end-box'>
                                <h6 className='title'>Ends</h6>
                                <div className='custom-date-box'>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => handleSelectedDateTime(date, "endDate")}
                                        dateFormat="dd MMM yyyy"
                                        placeholderText={"Start Date"}
                                        name={"endDate"}
                                        value={new Date()}
                                        className={"start-date "}
                                        wrapperClassName='custom-date-time-wrapper'
                                        minDate={new Date()}
                                    />
                                </div>
                                {!formData?.is_all_day && (
                                    <div className='custom-time-box'>
                                        <DatePicker
                                            name='end_time'
                                            selected={endTime}
                                            onChange={(time) => handleSelectedDateTime(time, "endTime")}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            placeholderText="Select Time"
                                            className="custom-time-picker"
                                            wrapperClassName="custom-time-picker-wrapper"
                                        />
                                    </div>
                                )}
                                {/* <h6 className='ms-2'>Travel Time</h6>
                                <div className='date-time-custom-dropdown'>
                                    <Dropdown onSelect={handleTravelSelect}>
                                        <Dropdown.Toggle variant="light" className='custom-travel-time-dropdown-toggle'>
                                            <span>{travelTime}</span>
                                            <Icon className={"travel-time-arrow-icon"} image attrIcon={App_url.img.UpDownIcon} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {TravelTimeOptions && TravelTimeOptions.map(option => (
                                                <Dropdown.Item key={option?.value} eventKey={option?.value} style={{ paddingRight: '8px' }}>
                                                    <div className='check_menu' style={{ fontSize: '13px' }}>
                                                        {travelTime === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                        <span style={{ marginLeft: travelTime === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                    </div>
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='invitees-info-box'>
                        <div className='invitees-flex-box' onClick={handleInviteeModal}>
                            <div className='invitees-name'>
                                <div className='invitees-icon'><Icon image attrIcon={App_url.img.UserPlusIcon} /></div>
                                <div className='invitees-title'><h6>Invitees</h6></div>
                            </div>
                            <div className='invitees-count-box'>
                                {selectedInvitees?.length > 0 ? selectedInvitees?.length : "None"}
                                <Icon className={"ms-2"} attrIcon={App_url.img.UpDownIcon} />
                            </div>
                        </div>
                        {selectedInvitees?.length > 0 &&
                            <>
                                {selectedInvitees?.map((item) => (
                                    <div className='invitees-list-box'>
                                        <div className='invitees-name'>
                                            <h6 className='invitees-icon'>{Utils.formatNameSurnameFirstLetter(item?.full_name)}</h6>
                                        </div>
                                        <div className='invitees-list-i-box'>
                                            <div className='invitees-title'><h6>{item?.full_name}</h6></div>
                                            <Icon onClick={()=> handleInviteeInfo(item)} className={"ms-2 invitees-info-btn"} attrIcon={App_url.img.CircleInfoIcon} />
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                    </div>
                    <div className='repeat-info-box'>
                        <div className='repeat-flex-box'>
                            <div className='repeat-name'>
                                <div className='repeat-icon'><Icon image attrIcon={App_url.img.RepeatCircleIcon} /></div>
                                <div className='repeat-title'><h6>Repeat</h6></div>
                            </div>
                            <div className='repeat-dropdown-modal-container'>
                                <Dropdown onSelect={handleRepeatSelect}>
                                    <Dropdown.Toggle variant="light" className='custom-repeat-dropdown-toggle'>
                                        <span className='me-1'>{repeat === "None" ? "Never":repeat}</span>
                                        <Icon className={"repeat-arrow-icon"} attrIcon={App_url.img.UpDownIcon} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {RepeatOptions && RepeatOptions.map(option => (
                                            <Dropdown.Item key={option?.value} eventKey={option?.value}>
                                                <div className='check_menu' style={{ fontSize: '13px' }}>
                                                    {repeat === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                    <span style={{ marginLeft: repeat === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {repeat !== "None" &&
                            <>
                                <div className='repeat-hr-box text-center'>
                                    <hr className='repeat-hr' />
                                </div>

                                <div className='repeat-flex-box'>
                                    <div className='repeat-name'>
                                        <div className='repeat-icon'><Icon image attrIcon={App_url.img.RepeatCircleIcon} /></div>
                                        <div className='repeat-title'><h6>End Repeat</h6></div>
                                    </div>
                                    <div className='repeat-dropdown-modal-container'>
                                        <Dropdown onSelect={handleEndRepeatSelect}>
                                            <Dropdown.Toggle variant="light" className='custom-repeat-dropdown-toggle'>
                                                <span className='me-1'>{endRepeat === "None" ? "Never" : endRepeat}</span>
                                                <Icon className={"repeat-arrow-icon"} attrIcon={App_url.img.UpDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {EndRepeatOptions && EndRepeatOptions.map(option => (
                                                    <Dropdown.Item key={option?.value} eventKey={option?.value}>
                                                        <div className='check_menu' style={{ fontSize: '13px' }}>
                                                            {endRepeat === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                            <span style={{ marginLeft: endRepeat === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            {endRepeat === "On Date" &&
                                <>
                                    <div className='repeat-hr-box text-center'>
                                        <hr className='repeat-hr' />
                                    </div>
                                    <div className='repeat date-time-info-box'>
                                        <div className='date-time-first'>
                                            <div className='date-time-icon'><Icon image attrIcon={App_url.img.RepeatCircleIcon} /></div>
                                            <div className='date-time-start-box'>
                                                <h6 className='title'>Ends</h6>
                                                <div className='repeat-date-time-div-box'>
                                                <div className='custom-date-box'>
                                                    <DatePicker
                                                        selected={rangeEndDate}
                                                        onChange={(date) => handleSelectedDateTime(date, "repeatEndDate")}
                                                        dateFormat="dd MMM yyyy"
                                                        placeholderText={"Start Date"}
                                                        name={"repeatEndDate"}
                                                        value={new Date()}
                                                        className={"start-date "}
                                                        wrapperClassName='custom-date-time-wrapper'
                                                        minDate={new Date()}
                                                    />
                                                </div>
                                                {/* <div className='custom-time-box'>
                                                    <DatePicker
                                                        name='repeatEndTime'
                                                        selected={rangeEndTime}
                                                        onChange={(time) => handleSelectedDateTime(time, "repeatEndTime")}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={30}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                        placeholderText="Select Time"
                                                        className="custom-time-picker"
                                                        wrapperClassName="custom-time-picker-wrapper"
                                                    />
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            }
                            </>
                        }
                        
                    </div>
                    <div className='alert-info-box'>
                        <div className='alert-flex-box'>
                            <div className='alert-name'>
                                <div className='alert-icon'><Icon image attrIcon={App_url.img.AlertIcon} /></div>
                                <div className='alert-title'><h6>Alert</h6></div>
                            </div>
                            <div className='alert-dropdown-modal-container'>
                                <Dropdown onSelect={handleAlertSelect}>
                                    <Dropdown.Toggle variant="light" className='custom-alert-dropdown-toggle'>
                                        <span className='me-1'>{alert === "None" ? "Never": alert}</span>
                                        <Icon className={"alert-arrow-icon"} attrIcon={App_url.img.UpDownIcon} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {AlertOptions && AlertOptions.map(option => (
                                            <Dropdown.Item key={option?.value} eventKey={option?.value}>
                                                <div className='check_menu' style={{ fontSize: '13px' }}>
                                                    {alert === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                    <span style={{ marginLeft: alert === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                        {alert !== "None" &&
                            <>
                                <div className='alert-hr-box text-center'>
                                    <hr className='alert-hr' />
                                </div>

                                <div className='alert-flex-box'>
                                    <div className='alert-name'>
                                        <div className='alert-icon'><Icon image attrIcon={App_url.img.AlertIcon} /></div>
                                        <div className='alert-title'><h6>Second Alert</h6></div>
                                    </div>
                                    <div className='alert-dropdown-modal-container'>
                                        <Dropdown onSelect={handleSecondAlertSelect}>
                                            <Dropdown.Toggle variant="light" className='custom-alert-dropdown-toggle'>
                                                <span className='me-1'>{secondAlert === "None" ? "Never": secondAlert}</span>
                                                <Icon className={"alert-arrow-icon"} attrIcon={App_url.img.UpDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {AlertOptions && AlertOptions.map(option => (
                                                    <Dropdown.Item key={option?.value} eventKey={option?.value}>
                                                        <div className='check_menu' style={{ fontSize: '13px' }}>
                                                            {secondAlert === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                            <span style={{ marginLeft: secondAlert === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='attachments-editor-box'>
                        <AttachmentEditor handleQuillDataForNotes={handleQuillDataForNotes} handleUploads={handleUploads}  />
                    </div>

                </Modal.Body>
            </Scrollbar>
        </Modal>
    )
}