/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { ListGroupItem } from 'react-bootstrap'
import Icon from '../Common/Icon';
import { Link, NavLink, } from 'react-router-dom';
import Images from '../Common/Image';

export default function TabListImage(item) {
  const [activeButton, setActiveButton] = useState(null);
  return (
    <NavLink to={item?.to}>
      <ListGroupItem className='list-images ' >
        {/* <span className='ellipse ps-3'>{item?.title}</span> */}
        <div className="card">
          <Images width={30} height={20} src={item?.image} />
          <div className="card-img-overlay">
            <span className="img-text-size text-white text-nowrap">{item?.title}</span>
          </div>
        </div>
      </ListGroupItem>
    </NavLink>
  )
}
