/* eslint-disable */
import React from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Card } from 'react-bootstrap'
import CustomTableTwo from '../Common/CustomTableTwo'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'

const BrowseListPage = () => {
    const DocumentaionList = [
        { name: "Templates Doc", type: "FILE", file_size: "234 MB", last_edit: '09/09/2024 12:00 PM', action: "Action" },
        { name: "Document name here", type: "PDF", file_size: "12 MB", last_edit: '09/09/2024 12:00 PM', action: "Action" },
        { name: "Document name here", type: "TXT", file_size: "12 MB", last_edit: '09/09/2024 12:00 PM', action: "Action" },
        { name: "Document name here", type: "CSV", file_size: "12 MB", last_edit: '09/09/2024 12:00 PM', action: "Action" },
        { name: "Document name here", type: "XLS", file_size: "12 MB", last_edit: '09/09/2024 12:00 PM', action: "Action" },
        { name: "Document name here", type: "PPT", file_size: "12 MB", last_edit: '09/09/2024 12:00 PM', action: "Action" },
        { name: "Document name here", type: "DOC", file_size: "12 MB", last_edit: '09/09/2024 12:00 PM', action: "Action" },

    ]
    const RenderBody = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment className='dashTitle'>
                <td>{item?.name}</td>
                <td>{item?.type}</td>
                <td>{item?.file_size}</td>
                <td>{item?.last_edit}</td>
                <td className='d-flex gap-2'>
                    <span className='view-icon-box' /* onClick={callBack} */><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span>
                    <span className='view-icon-box' /* onClick={callBack} */><Icon className={"bg-primary"} attrIcon={App_url.img.EditIcon} /></span>
                    <span className='view-icon-box' /* onClick={callBack} */><Icon className={"bg-primary"} attrIcon={App_url.img.DeleteIcon} /></span>
                </td>
            </React.Fragment>
        )
    }
    return (
        <ContentLayout title={"Documentation Page"}>

            <CustomTableTwo
                title={{ text: "Browse", size: "fs-20", textTwo: "" }}
                AddButton={{ title: "+ Add Document", variant: "outline-primary", size: "md" }}
                columnNames={["Name ", "Type", "File Size", "Last Modified", "Action"]}
                recordData={DocumentaionList}
                renderBody={RenderBody}
                // callBack={callBack}
                // setFilter={setFilter}
                // filter={filter}
                search_placeholder={"Search By Name,City,Industry & Postcode"}
                typePlaceHolder={"Select Status"}


            />

        </ContentLayout>
    )
}

export default BrowseListPage