/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, Button, Image, Spinner } from 'react-bootstrap';
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import App_url from "../Common/Constants";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { setStoreCurrentUser, setStoreUserOutlookDetails } from "../../store/modules/users_data/action";
import GuestLayout from "../layout/GuestLayout";
import { loginRequest } from "../../authConfig";
import { PostRequestAPI } from '../../Api/api/PostRequest'
import LoaderPage from "../Common/LoaderPage";

const Login = () => {
    const isAuthenticated = useIsAuthenticated();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    // const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@?!])[A-Za-z\d@?!]{8,}$/; // eslint-disable-line
    const [PasswordShow, setPasswordShow] = useState(false);
    const [loaderStart, setLoaderStart] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { access_token, userOutlookDetails } = useSelector(App_url.userDataReducers);
    const { instance, inProgress } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    };

    console.log("isAuthenticated", isAuthenticated, inProgress, loaderStart, access_token);

    useEffect(() => {
        // Handle the response after redirect
        const handleRedirectResponse = async () => {
                setLoaderStart(true);
                const response = await instance.handleRedirectPromise();
                if (response) {
                    console.log("Redirect response", response);
                    dispatch(setStoreUserOutlookDetails(response))
                    instance.setActiveAccount(response);
                    const payload = {
                        access_token: response?.accessToken,
                    }
                    const loginResponse = await PostRequestAPI(App_url.api.USER_LOGIN, payload);
                    setLoaderStart(false);
                    console.log("loginResponse", loginResponse);
                    if (loginResponse?.status === 200) {
                        dispatch(setStoreCurrentUser(loginResponse?.data?.data));
                    }
                }
                setLoaderStart(false);  
                
        };
        if(isAuthenticated){
            handleRedirectResponse();
        }

    }, [isAuthenticated]);

    useEffect(() => {
        if (access_token) {
            navigate(App_url.link.dashboard);
        }
       
    }, [access_token])




    // sign in function
    // const SignInButton = async (e) => {
    //     e.preventDefault();
    //     console.log(email, password);
    //    if(email === "admin@appristine.in" && password === "Admin@123"){
    //     const data ={
    //         payload:{
    //             username:email,
    //             password:password
    //         },
    //         access_token:"abc123justLOgin",
    //     }
    //     dispatch(setStoreCurrentUser(data));
    //     navigate(App_url.link.dashboard);
    //    }else{
    //     toast.error("invalid credentials")
    //    }
    // };

    return (
        <React.Fragment>
            {((inProgress === "startup" || inProgress === "handleRedirect" || loaderStart) && !access_token) ? (
                <LoaderPage/>
            ):(
                 <GuestLayout>
                 <div className="loginWrapper">
                     <div className="logoheader">
                         <Image className="logoheader-img" src={App_url.img.MainLogo} alt="logo" />
                     </div>
                     {/* <Form onSubmit={SignInButton} className="loginMainblog">
                 <h4>Login to your account</h4>
                 <div  className="formloginset">
                     <Form.Group className="mb-3" controlId="formBasicEmail">
                         <Form.Label>Email address</Form.Label>
                         <input type="email" className="d-none" />
                         <Form.Control type="email" value={email}  onChange={(e) => setEmail(e.target.value)}  placeholder="Enter email address" autoComplete="new-email" disabled={LoaderStart} />
                         {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
                     </Form.Group>
                     <Form.Group className="" controlId="formBasicPassword">
                         <Form.Label>Password</Form.Label>
                         <div className="passwordfiled mb-3">
                             <input type="password" className="d-none" />
                             <Form.Control type={PasswordShow ? "text" : "password"} value={password}  onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" autoComplete="new-password"  disabled={LoaderStart}/>
                             <div className="showpassbtn" onClick={() => setPasswordShow(!PasswordShow)}>
                                 {PasswordShow ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>) :
                                 (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>)}
                             </div>
                         </div>
                         {errorPassword && <div className="danger-colorset">{errorPassword}</div>}
                     </Form.Group>
                     <Button onClick={SignInButton} className="signInbtn"  disabled={LoaderStart}>
                         {LoaderStart ? (<Spinner animation="border" role="status">
                             <span className="visually-hidden">Loading...</span>
                         </Spinner>) : "Sign In"}
                     </Button>
                 </div>
             </Form> */}
                     <Button variant="primary" size="lg" className="mt-4" onClick={(e) => handleLogin()}>
                         Login with Outlook
                     </Button>
                 </div>
                 <ToastContainer />
             </GuestLayout>
            )}
        </React.Fragment>
    )
}

export default Login;