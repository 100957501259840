/* eslint-disable */
import { toast } from "react-toastify";
import wsSendRequest from "./wsSendRequest";
import App_url from "../components/Common/Constants";
import { setStoreCurrentUser, setWebsocketReqRes } from "../store/modules/users_data/action";
import { setStoreAppointmentDetails, setStoreAppointmentList, setStoreCustomersDetails, setStoreCustomersList, setStoreDocumentsList, setStoreEmailHistoryList, setStoreInviteeAvailableDates, setStoreInviteesUserList, setStoreRemarkList, setStoreScheduleList, setStoreSentEmailHistoryList } from "../store/modules/customers/action";
import { Pagination } from "react-bootstrap";

export const Ws_OnMessage = ({ evt, ws, device_id, navigate }) => {
    return (async (dispatch, getState) => {
    const ws_onmessage = JSON.parse(evt.data);
    
    if(ws_onmessage?.device_id === device_id){
        const payload = {
            ...ws_onmessage,
            request: ws_onmessage?.request,
            response: ws_onmessage?.response,
            msg: ws_onmessage?.response?.msg,
            status: ws_onmessage?.response?.status,
            url: ws_onmessage?.url,
        }
        dispatch(setWebsocketReqRes(payload));
        if(ws_onmessage.toast){
            if(payload?.status === 200){
                if(typeof payload?.msg == "string"){
                    toast.success(payload?.msg)
                }
            }
            if(ws_onmessage.redirect){
                navigate(ws_onmessage.redirect);
            }
        }
    }
        console.log("ws_onmessage", ws_onmessage);
        switch (ws_onmessage.url) {
            case "unauthorized": {
                dispatch(setStoreCurrentUser())
                window.location.replace(`${process.env.REACT_APP_API_DOMAIN_URL}/${App_url.api.USER_DEL_LOGIN}/${window.location.hostname}`)
                break;
            }
            case "customer_list": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreCustomersList(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreCustomersList({}))
                }
                break;
            }
            case "remark_list": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreRemarkList(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreRemarkList({}))

                }
                break;
            }

            case "customer_detail": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreCustomersDetails(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreCustomersDetails([]))
                }
                break;
            }

            case "schedule_list": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreScheduleList(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreScheduleList([]))
                }
                break;
            }

            case "document_list": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreDocumentsList(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreDocumentsList({}))
                }
                break;
            }

            case "inbox_list": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreEmailHistoryList(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreEmailHistoryList({}))
                }
                break;
            }

            case "sent_list": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreSentEmailHistoryList(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreSentEmailHistoryList({}))
                }
                break;
            }

            case "appointment_list": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreAppointmentList(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreAppointmentList({data: [],pagination: {}}))
                }
                break;
            }
            case "user_list": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreInviteesUserList(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreInviteesUserList({data:[],Pagination:[]}))
                }
                break;
            }
            case "appointment_detail": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreAppointmentDetails(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreAppointmentDetails({data:[]}))
                }
                break;
            }
            case "appointments_for_month": {
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(setStoreInviteeAvailableDates(ws_onmessage?.response?.data))
                } else {
                    dispatch(setStoreInviteeAvailableDates([]))
                }
                break;
            }
            default:
                break;
        }
    }

)};
