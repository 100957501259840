/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import InputGroup from '../../Common/InputGroup';
import Button from '../../Common/Button';
import Icon from '../../Common/Icon';
import App_url from '../../Common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setStoreAddRemarkModal } from '../../../store/modules/modals/action';
import { WebSocketContext } from '../../../WSContext';
import wsSendRequest from '../../../socketIO/wsSendRequest';

export default function AddRemarkModal(props) {
    const dispatch = useDispatch();
    const ws = useContext(WebSocketContext)
    const { device_id } = useSelector(App_url.userDataReducers)
    const { ModalPopupTwo } = useSelector(App_url.modalReducers);

    // console.log("modulpop", ModalPopupTwo?.data);

    const [formData, setFormData] = useState({
        customer_id: "",
        message: ""
    });
    const [error, setError] = useState({
        customer_id: "",
        message: ""
    });
    const [loader, setLoader] = useState(false)

    const onChange = (e) => {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }))
        setError((data) => ({
            ...data,
            [e.target.name]: ""
        }))

    };

    useEffect(() => {
        if(ModalPopupTwo?.show === 'ADD_REMARK_MODAL' && ModalPopupTwo?.data?.item){
           console.log("DataEdit",ModalPopupTwo?.data?.item);
           setFormData((data)=>({
            ...data,
            message: ModalPopupTwo?.data?.item?.message
           }))
        }
    }, [ModalPopupTwo?.data?.item]);

    const validation = () => {
        let val = true;
        if (!formData?.message) {
            error.message = "Please enter message"
            val = false
        }
        // if(!formData?.description || formData?.description == "<p><br></p>"){
        //     error.description = "Please enter blog description"
        //     val = false
        // }
        setError((data) => ({
            ...data,
            ...error
        }))
        return val;
    }
    const handleClose = async (message, item) => {
        if (ModalPopupTwo?.callBackModal) {
            await ModalPopupTwo?.callBackModal(message, item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreAddRemarkModal())
    }

    if (ModalPopupTwo?.show !== "ADD_REMARK_MODAL") {
        return null
    }

      
    
    const onSubmit = (e) => {
        e.preventDefault()
        if (validation()) {
            // callAddRemark();
            setFormData({message:""});
            ModalPopupTwo?.data?.item ? handleClose(formData?.message, ModalPopupTwo?.data?.item) : handleClose(formData?.message)
        }else{
            console.log("error in validation...")
        }
    }

    return (
        <Modal show={true} variant="secondary" centered >
            <Modal.Body className='add-remark-modal'>
                <div className='header'>
                    <h6 className='title'>Add Remarks</h6>
                    <div >
                        <Icon onClick={CloseData} className={' ms-1'} image attrIcon={App_url.img.RedCrossIcon} />
                    </div>
                </div>
                <Form className='add-remark-text-box' onSubmit={onSubmit}>
                    <InputGroup
                        label={""}
                        placeholder={"Schedule a call"}
                        formClassName="mb-3 col-12 rounded"
                        name={"message"}
                        as={"textarea"}
                        rows='9'
                        className='textgrp'
                        value={formData?.message}
                        error={error?.message}
                        onChange={onChange}
                    />
                    <div className='text-end'>
                        <Button variant="primary" disable={loader} onClick={onSubmit} className='col-lg-3 '>
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal.Body>

        </Modal>
    );
};

AddRemarkModal.propTypes = {
    handleClose: PropTypes?.func,
    show: PropTypes?.bool,
}
AddRemarkModal.defaultProps = {
    handleClose: () => { },
    show: false,
}