import React from 'react'
import { Card, Modal } from 'react-bootstrap'
import Button from '../Common/Button'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'

const OfferModal = ({ isModalOpen, handleClose }) => {
    return (
        <div>
            <Modal show={isModalOpen} onHide={handleClose} centered className='offermodal'>

                <Modal.Body>
                    <div className='heading  '>
                        <h6>Offer Text</h6>
                        <div>
                            <Button size={'md'} variant={"primary"} >Add To Offer</Button>
                            <Icon onClick={handleClose} className={"ms-1 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                    <div className='text-1'>
                        <Card>
                            <Card.Body>
                                <h6>Text From Page 2</h6>
                                <p>
                                    First of all, we would like to thank you for your interest in our services and in our
                                    company Kümpel Building Cleaning GmbH. <br /><br />
                                    As requested, we will provide you with our offer for
                                    glass and frame cleaning as well as our hygiene items in the above-mentioned property.<br />
                                    Our services are calculated in such a way that we offer you a lasting, solid level of quality
                                    and thus long-term cooperation is guaranteed. <br /><br />
                                    Furthermore, your offer was differentiated according to
                                    the individual items in the list of services you provided.<br /><br />
                                    We are a master company and offer
                                    the highest level of quality and individual service. As a well-known building service provider that
                                    has been established for many years, our customers from industry,
                                    trade and municipalities trust our sophisticated service.
                                </p>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='text-1'>
                        <Card>
                            <Card.Body>
                                <h6>Text From Page 4</h6>
                                <p>
                                    Note: The services offered have been calculated so that one in each In terms of satisfactory execution quality will be achieved. <br /><br />
                                    Our prices do not include statutory sales tax. <br /><br />
                                    Cleaning materials and equipment are included in the price.
                                </p>
                            </Card.Body>
                        </Card>
                    </div>

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default OfferModal