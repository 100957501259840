/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import {  Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import App_url from '../../../Common/Constants';
import Icon from '../../../Common/Icon';
import Button from '../../../Common/Button';
import Scrollbar from '../../../Common/Scrollbar';
import InputGroup from '../../../Common/InputGroup';
import { setStoreInviteeModalTwoPopup } from '../../../../store/modules/modals/action';
import { setStoreSelectedInvitees } from '../../../../store/modules/users_data/action';
import wsSendRequest from '../../../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../../../WSContext';


export default function InviteeModal() {
    const dispatch = useDispatch();
    const ws = useContext(WebSocketContext);
    const { ModalPopupTwo } = useSelector(App_url.modalReducers);
    const {inviteesUserList} = useSelector(App_url.customerReducers);
    const {device_id} = useSelector(App_url.userDataReducers);
    // console.log("inviteesUserList", inviteesUserList)

    const [invitees, setInvitees] = useState([]);
    const [formData, setFormData] = useState({
        invitee_name:""
    })
    // console.log("formData", invitees);
    const [error, setError] = useState({
        invitee_name:""
    })

    const onChange = (e) => {
        setFormData((data)=>({
         ...data,
         [e.target.name]: e.target.value,
        }))
        setError((prevData)=>({
         ...prevData, 
         [e.target.name]:""
        }))
     }

    
     useEffect(()=>{
        if(ModalPopupTwo?.show === "INVITEE_MODAL"){
            setInvitees([])
        }
     },[inviteesUserList])
   

    const handleClose = async () => {
        if (ModalPopupTwo?.callBackModal) {
            await ModalPopupTwo?.callBackModal(invitees);
        }
        CloseData()
    };

    const handleSelectedValue = (selectedValue) =>{
        const removedInvitees = invitees?.filter((item) => item?.id !== selectedValue?.id)
        setInvitees(removedInvitees);
    }

    const handleAddSelectedInvitee = (item) =>{
        // console.log("item", item);
        setInvitees((prevInvitees) => {
            const newInvitees = [...prevInvitees];
            newInvitees.push(item);
            return newInvitees;
        });
    }
    
    const CloseData = () => {
        dispatch(setStoreInviteeModalTwoPopup())
    }


    if (ModalPopupTwo?.show !== "INVITEE_MODAL") {
        return null
    }

    return (
        <Modal show={true} centered className='invitee-modal-dialog' size={"lg"}>
            <Scrollbar style={{ height: "calc(100vh - 62px)" }}>
                <Modal.Body className='modal-body invitee-modal' >
                    <div className='invitee-header'>
                        <Icon className={"bg-primary invitee-header-back-icon"} attrIcon={App_url.img.LeftArrowIcon} onClick={CloseData} style={{ cursor: "pointer" }} />
                        <h6 className='invitee-header-title'>Add Invitees</h6>
                        <h6 onClick={handleClose} className={"invitee-header-title-done"}>Done</h6>
                    </div>
                    <div>
                        <InputGroup
                        placeholder={""}
                        formClassName={"mt-3 invitee-input-box"}
                        name={"invitee_name"}
                        value={formData?.invitee_name}
                        onChange={onChange}
                        error={error?.invitee_name}
                        />
                    </div>
                    
                    {/* Selected Invitees */}
                    <div className='mt-2 invitee-selected-value-box'>
                    {invitees?.length > 0 && invitees?.map((item) => (
                        <h6 className='invitee-selected-value-title' key={item?.id}>{item?.full_name} <Icon className={"invitee-selected-value-cross-icon"} onClick={()=>handleSelectedValue(item)} image attrIcon={App_url.img.CircleCross}/></h6>
                    ))}
                    </div>

                    {/* Invitee List */}
                    <div>
                        {inviteesUserList?.data?.filter((item) => !invitees?.some((inv)=>inv.id === item?.id) && item?.full_name)?.map((item)=>(
                            <div className='invitee-data'>
                                <h6>{item?.full_name}</h6>
                                {item?.is_available ? <h6 className='invitee-add-btn' onClick={()=>handleAddSelectedInvitee(item)}>+Add</h6>: <h6 className='invitee-add-btn text-secondary'>+Add</h6>}
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Scrollbar>
        </Modal>
    )
}
InviteeModal.propTypes = {
    handleClose: PropTypes?.func,
    show: PropTypes?.bool,
}
InviteeModal.defaultProps = {
    handleClose: () => { },
    show: false,
}