
export const ActionTypes = {
    SET_STORE_MODAL_POPUP:"SET_STORE_MODAL_POPUP",
    SET_STORE_NEW_APPOINTMENT_MODAL:"SET_STORE_NEW_APPOINTMENT_MODAL",
    SET_STORE_MODAL_CONFIRM_TWO:"SET_STORE_MODAL_CONFIRM_TWO",
    SET_STORE_NEW_INVITEE_MODAL:"SET_STORE_NEW_INVITEE_MODAL",
    SET_STORE_INVITEE_INFO_MODAL:"SET_STORE_INVITEE_INFO_MODAL",
    SET_STORE_MODAL_CONFIRM:"SET_STORE_MODAL_CONFIRM",
    SET_STORE_APPOINTMENT_VIEW_MODAL:"SET_STORE_APPOINTMENT_VIEW_MODAL",
    SET_STORE_CALL_SCHEDULED_SUCCESSFUL_MODAL:"SET_STORE_CALL_SCHEDULED_SUCCESSFUL_MODAL",
    SET_STORE_VIEW_SCHEDULED_CALL_MODAL:"SET_STORE_VIEW_SCHEDULED_CALL_MODAL",
    SET_STORE_RING_CALL_MODAL:"SET_STORE_RING_CALL_MODAL",
    SET_STORE_SCHEDULE_CALL_MODAL:"SET_STORE_SCHEDULE_CALL_MODAL",
    SET_STORE_ADD_REMARK_MODAL:"SET_STORE_ADD_REMARK_MODAL",
    SET_STORE_VIEW_REMARK_MODAL:"SET_STORE_VIEW_REMARK_MODAL",
    SET_STORE_DISCARD_CHANGES_MODAL:"SET_STORE_DISCARD_CHANGES_MODAL",
    SET_STORE_VIEW_MAIL_MODAL:"SET_STORE_VIEW_MAIL_MODAL",
    SET_STORE_VIEW_DOCUMENT_MODAL:"SET_STORE_VIEW_DOCUMENT_MODAL",
    SET_STORE_SHARE_DOCUMENT_MODAL:"SET_STORE_SHARE_DOCUMENT_MODAL",
    SET_STORE_COMPOSE_MAIL_MODAL:"SET_STORE_COMPOSE_MAIL_MODAL",
    SET_STORE_SEARCH_COMPANY_MODAL:"SET_STORE_SEARCH_COMPANY_MODAL",
    SET_STORE_EDIT_APPOINTMENT_MODAL:"SET_STORE_EDIT_APPOINTMENT_MODAL",
}

export const setConfirmModalPopup = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_MODAL_CONFIRM,
            payload: {
                ...token,
                show: "CONFIRM_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_MODAL_CONFIRM,
        }
    }
}
export const setNewAppointmentModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_NEW_APPOINTMENT_MODAL,
            payload: {
                ...token,
                show: "NEW_APPOINTMENT_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_NEW_APPOINTMENT_MODAL,
        }
    }
}
export const setEditAppointmentModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_EDIT_APPOINTMENT_MODAL,
            payload: {
                ...token,
                show: "EDIT_APPOINTMENT_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_EDIT_APPOINTMENT_MODAL,
        }
    }
}
export const setScheduleCallModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_SCHEDULE_CALL_MODAL,
            payload: {
                ...token,
                show: "SCHEDULE_CALL_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_SCHEDULE_CALL_MODAL,
        }
    }
}
export const setStoreCallScheduleSuccessfulModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_CALL_SCHEDULED_SUCCESSFUL_MODAL,
            payload: {
                ...token,
                show: "CALL_SCHEDULED_SUCCESSFUL_MODAL"
            }
        }
    }else{
        return{
            type:ActionTypes.SET_STORE_CALL_SCHEDULED_SUCCESSFUL_MODAL
        }
    }
}
export const setShareDocumentModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_SHARE_DOCUMENT_MODAL,
            payload: {
                ...token,
                show: "SHARE_DOCUMENT_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_SHARE_DOCUMENT_MODAL,
        }
    }
}
export const setStoreViewScheduledCallModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_VIEW_SCHEDULED_CALL_MODAL,
            payload: {
                ...token,
                show: "VIEW_SCHEDULED_CALL_MODAL",
        }
    }
}else{
    return{
        type: ActionTypes.SET_STORE_VIEW_SCHEDULED_CALL_MODAL,
    }
}
}
export const setViewDocumentModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_VIEW_DOCUMENT_MODAL,
            payload: {
                ...token,
                show: "VIEW_DOCUMENT_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_VIEW_DOCUMENT_MODAL,
        }
    }
}
export const setStoreRingCallModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_RING_CALL_MODAL,
            payload: {
                ...token,
                show: "RING_CALL_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_RING_CALL_MODAL,
        }
    }
}
export const setStoreAddRemarkModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_ADD_REMARK_MODAL,
            payload: {
                ...token,
                show: "ADD_REMARK_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_ADD_REMARK_MODAL,
        }
    }
}
export const setStoreViewRemarkModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_VIEW_REMARK_MODAL,
            payload: {
                ...token,
                show: "VIEW_REMARK_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_VIEW_REMARK_MODAL,
        }
    }
}
export const setStoreDiscardChangesModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_DISCARD_CHANGES_MODAL,
            payload: {
                ...token,
                show: "DISCARD_CHANGES_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_DISCARD_CHANGES_MODAL,
        }
    }
}
export const setStoreViewMailModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_VIEW_MAIL_MODAL,
            payload: {
                ...token,
                show: "VIEW_MAIL_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_VIEW_MAIL_MODAL,
        }
    }
}
export const setStoreComposeMailModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_COMPOSE_MAIL_MODAL,
            payload: {
                ...token,
                show: "COMPOSE_MAIL_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_COMPOSE_MAIL_MODAL,
        }
    }
}
export const setConfirmModalTwoPopup = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_MODAL_CONFIRM_TWO,
            payload: {
                ...token,
                show: "CONFIRM_MODAL_TWO"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_MODAL_CONFIRM_TWO,
        }
    }
}
export const setStoreInviteeModalTwoPopup = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_NEW_INVITEE_MODAL,
            payload: {
                ...token,
                show: "INVITEE_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_NEW_INVITEE_MODAL,
        }
    }
}
export const setStoreInviteeInfoModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_INVITEE_INFO_MODAL,
            payload: {
                ...token,
                show: "INVITEE_INFO_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_INVITEE_INFO_MODAL,
        }
    }
}
export const setStoreAppointmentViewModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_APPOINTMENT_VIEW_MODAL,
            payload: {
                ...token,
                show: "APPOINTMENT_VIEW_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_APPOINTMENT_VIEW_MODAL,
        }
    }
}
export const setShowModalPopup = (token) => {
    return {
       type: ActionTypes.SET_STORE_MODAL_POPUP,
       payload: token,
    }
};
export const setStoreSearchCompanyModal = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_SEARCH_COMPANY_MODAL,
            payload: {
                ...token,
                show: "SEARCH_COMPANY_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_SEARCH_COMPANY_MODAL,
        }
    }
}