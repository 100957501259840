// PatchRequestAPI

export const PatchRequestCallAPI = async (apiurl, data, access_token) => {
    const headers = {
        'Accept': "application/json",
        'Content-Type': "application/json",
    }
    if (access_token) {
        headers.Authorization = `Bearer ${access_token}`;
    }
    const header = {
        headers: headers
    }

    const patchResponse = window.axios.patch(`${process.env.REACT_APP_API_DOMAIN_URL}/${apiurl}`, data, header)
        .then(function (result) {
            return result;
        })
        .catch((e) => e.response);

    return patchResponse;
}
