import React from 'react'
import { Card, Table } from 'react-bootstrap'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'

const CommonTableComp = () => {
    return (
        <div className='custom-table-overview'>
            <Card>
                {/* <div className='header-1   d-flex justify-content-between align-items-center'>
                    <h6>Glass Cleaning</h6>
                    <h6 className='ms-5'>Man Hour Cost Calculation</h6>
                    <h6>Additional Cost Calculation</h6>
                    <div className='icons'>
                        <Icon className={"header-calendar-icon"} attrIcon={App_url.img.DeleteIcon} />
                        <Icon className={"header-calendar-icon"} attrIcon={App_url.img.EditIcon} />
                    </div>
                </div> */}
                <div className='row'>
                    <div className='col-4 pe-0'>
                        <div className='header-1'>
                            <h6>Glass Cleaning</h6>
                        </div>
                        <div className='header-2'>
                            <h6>Glass Outside with Frame</h6>
                        </div>
                        <div className='header-3'>
                            <div className='surface '>
                                <Icon className={"header-calendar-icon"} attrIcon={App_url.img.CalendarIcon} />
                                <h6>Surface Area : 1000</h6>
                            </div>
                            <div className='surface'>
                                <Icon className={"header-calendar-icon"} attrIcon={App_url.img.CalendarIcon} />
                                <h6>Frequency : Twice in a Month (24 T)</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-4 p-0'>
                        <Table bordered className='mb-0'>

                            <thead className=''>
                                <th colSpan={3} className='th-header '>Man Hour Cost Calculation</th>
                                <tr>
                                    <th>Particular</th>
                                    <th>Hours</th>
                                    <th >Cost</th>
                                </tr>
                            </thead>
                            <tbody className='table-with-radius'>

                                <tr >
                                    <td>Each</td>
                                    <td>2</td>
                                    <td>€ 100</td>
                                </tr>
                                <tr >
                                    <td>Yearly </td>
                                    <td>24</td>
                                    <td>€ 20</td>
                                </tr>
                                <tr >
                                    <td>Yearly </td>
                                    <td>24</td>
                                    <td>€ 20</td>
                                </tr>

                            </tbody>
                        </Table>

                    </div>
                    <div className='col-4 ps-0'>
                        <Table bordered>
                            <thead className=''>
                                <tr>
                                    <th colSpan={3} className='th-header'>Additional Cost Calculation</th>
                                    <th className='th-header'><Icon className={"header-calendar-icon th-header-icon"} attrIcon={App_url.img.DeleteIcon} /></th>
                                    <th className='th-header-dlt'><Icon className={"header-calendar-icon th-header-icon"} attrIcon={App_url.img.EditIcon} /></th>
                                </tr>
                                <tr>
                                    <th>Particular</th>
                                    <th>Frequency</th>
                                    <th >Cost</th>
                                </tr>
                            </thead>
                            <tbody className='table-with-radius'>
                                <tr >
                                    <td>Each</td>
                                    <td>2</td>
                                    <td>€ 100</td>

                                </tr>
                                <tr >
                                    <td>Yearly </td>
                                    <td>24</td>
                                    <td>€ 20</td>
                                </tr>

                            </tbody>
                        </Table>

                    </div>
                </div>


            </Card>
        </div>
    )
}

export default CommonTableComp