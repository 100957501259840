/* eslint-disable */
import { legacy_createStore as createStore, applyMiddleware, combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { modalReducers } from "./modules/modals/reducers"
import { userDataReducers } from "./modules/users_data/reducers";
import { customerReducers } from "./modules/customers/reducers";
import {thunk} from "redux-thunk";

const persistConfig = {
    key: "sale_app",
    storage
};

const reducers = combineReducers({
    modalReducers:modalReducers,
    userDataReducers:userDataReducers,
    customerReducers:customerReducers,
})

// export default reducers;
const middleware = [thunk];


const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, applyMiddleware(...middleware));

export const persistor = persistStore(store);

export default store;

