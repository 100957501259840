/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import { Breadcrumb, Card, Dropdown, Form } from 'react-bootstrap'
import InputGroup from '../../Common/InputGroup'
import AnchorLink from '../../Common/AnchorLink'
import Button from '../../Common/Button'
import App_url from '../../Common/Constants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Icon from '../../Common/Icon'
import Utils from '../../Common/Utils'
import { WebSocketContext } from '../../../WSContext'
import wsSendRequest from '../../../socketIO/wsSendRequest'
import { useSelector } from 'react-redux'

export default function ProspectsAddEdit(props) {
  const navigate = useNavigate();
  const ws = useContext(WebSocketContext)
  const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
  const { customerDetails } = useSelector(App_url.customerReducers);
  const { id: customerId } = useParams();

  const [formData, setFormData] = useState({
    company_name: "",
    company_mobile: "",
    customer_name: "",
    email: "",
    mobile: "",
    postal_code: "",
    city: "",
    street_no: "",
    customer_type: "",
    is_active: true,
    customer_position: "",
    industry_type: "",
    company_description: "",
    company_address: "",
    last_name: "",
    status: "",
  });
  const [error, setError] = useState({
    company_name: "",
    company_mobile: "",
    customer_name: "",
    email: "",
    mobile: "",
    postal_code: "",
    city: "",
    street_no: "",
    customer_type: "",
    customer_position: "",
    industry_type: "",
    company_description: "",
    company_address: "",
    last_name: "",
    status: "",
  })

  const [statusSaved, setStatusSaved] = useState(false)
  const [statusEdit, setStatusEdit] = useState(false);
  const onChange = (e) => {
    setFormData((data) => ({
      ...data,
      [e.target.name]: e.target.value
    }))
    setError((prevData) => ({
      ...prevData,
      [e.target.name]: "",
    }))
  }

  const customerTypeOptions = [
    { label: "Prospects", value: "prospects" },
    { label: "Old Customer", value: "oldCustomer" },
    { label: "Customers", value: "customers" },
  ]
  const statusOptionList = [
    {label: "Not Contacted", value: "Not Contacted"},
    { label: "Not Interested", value: "Not Interested" },
    { label: "Re-Schedule Call", value: "Re-Schedule Call" },
    { label: "Share Brochure", value: "Share Brochure" },
    { label: "Schedule Appointment", value: "Schedule Appointment" },
  ]
  const iconMap = {
    "Not Contacted": App_url.img.RedBulletIcon, 
    "Not Interested": App_url.img.GrayBulletIcon,
    "Re-Schedule Call": App_url.img.YellowBulletIcon,
    "Share Brochure": App_url.img.GreenBulletIcon,
    "Schedule Appointment": App_url.img.BlueBulletIcon,
  };
  const validation = () => {
    let val = true;

    const email = Utils.ValidateEmail(formData?.email);
    if (email) {
      error.email = email;
      val = false;
    }
    if (!formData.company_name) {
      error.company_name = "Please enter company name";
      val = false;
    }

    if (!formData.company_mobile) {
      error.company_mobile = "Please enter phone number";
      val = false;
    }

    if (!formData.customer_name) {
      error.customer_name = "Please enter first name";
      val = false;
    }
    if (!formData.last_name) {
      error.last_name = "Please enter last name";
      val = false;
    }

    if (!formData.mobile) {
      error.mobile = "Please enter mobile number";
      val = false;
    }
    // else if (!/^\d{10}$/.test(formData.mobile)) {
    //   error.mobile = "Please enter a valid 10-digit mobile number";
    //   val = false;
    // }

    if (!formData.postal_code) {
      error.postal_code = "Please enter postal code";
      val = false;
    }
    // else if (!/^\d{6}$/.test(formData.postal_code)) {
    //   error.postal_code = "Please enter a valid 6-digit postal code";
    //   val = false;
    // }

    if (!formData.city) {
      error.city = "Please enter city";
      val = false;
    }

    if (!formData.street_no) {
      error.street_no = "Please enter street number";
      val = false;
    }

    if (!formData.customer_position) {
      error.customer_position = "Please enter designation";
      val = false;
    }

    if (!formData.industry_type) {
      error.industry_type = "Please enter industry type";
      val = false;
    }

    if (!formData.company_description) {
      error.company_description = "Please enter company description";
      val = false;
    }

    if (!formData.company_address) {
      error.company_address = "Please enter address";
      val = false;
    }


    // Assuming `setError` is the function used to set error state
    setError((prevData) => ({
      ...prevData,
      ...error
    }));

    return val;
  };

  const confirmPayload = (formData) => {
    const payload = {
      company_name: "",
      company_mobile: "",
      customer_name: "",
      email: "",
      mobile: "",
      postal_code: "",
      city: "",
      street_no: "",
      customer_type: customerTypeOptions[0].value,
      is_active: true,
      customer_position: "",
      industry_type: "",
      company_description: "",
      company_address: "",
      last_name: ""
    };

    if (formData.company_name) {
      payload.company_name = formData.company_name;
    }
    if (formData.company_mobile) {
      payload.company_mobile = formData.company_mobile;
    }
    if (formData.customer_name) {
      payload.customer_name = formData.customer_name;
    }
    if (formData.last_name) {
      payload.last_name = formData.last_name;
    }
    if (formData.email) {
      payload.email = formData.email;
    }
    if (formData.mobile) {
      payload.mobile = formData.mobile;
    }
    if (formData.postal_code) {
      payload.postal_code = formData.postal_code;
    }
    if (formData.city) {
      payload.city = formData.city;
    }
    if (formData.street_no) {
      payload.street_no = formData.street_no;
    }
    if (formData.customer_type) {
      payload.customer_type = formData.customer_type;
    }
    if (formData.is_active !== undefined) {
      payload.is_active = formData.is_active;
    }
    if (formData.customer_position) {
      payload.customer_position = formData.customer_position;
    }
    if (formData.industry_type) {
      payload.industry_type = formData.industry_type;
    }
    if (formData.company_description) {
      payload.company_description = formData.company_description;
    }
    if (formData.company_address) {
      payload.company_address = formData.company_address;
    }

    return payload;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      callAddEditCustomerApi();
    } else {
      console.log("error in validation");
    }
  }

  const callStatusUpdateApi = (e) => {
    setFormData((data) => ({
      ...data,
      status: e
    }))
    const param = {
      transmit: "broadcast",
      url: "customer_status",
      request: {
        customer_id: customerId,
        status: e,
      },
      toast: true,
    };
    wsSendRequest(ws, param, device_id);
  }

  const callAddEditCustomerApi = () => {
    const payload = confirmPayload(formData);
    if (customerId) {
      payload.customer_id = customerId;
    }
    // console.log("payload", payload);
    const param = {
      transmit: "broadcast",
      url: customerId ? "customer_edit" : "customer_add",
      request: payload,
      toast: true,
      redirect: App_url.link.prospects,
    };
    wsSendRequest(ws, param, device_id)
  }


  useEffect(() => {
    if (customerId && customerDetails.length > 0) {
      setFormData((data) => ({
        ...data,
        ...customerDetails[0],
        // status:{label:customerDetails[0]?.status, value:customerDetails[0]?.status}
      }))
    }
  }, [customerId, customerDetails])

  const handleStatusSelect = (selectedValue) => {
    setFormData(selectedValue);
    setStatusEdit(true)
    setStatusSaved(false);
  }

  return (
    <ContentLayout title={props?.title}>
      <Breadcrumb className="breadcrumb-main">
        <Breadcrumb.Item>
          <AnchorLink to={App_url.link.prospects}>Prospects</AnchorLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{customerId ? "Edit" : "Add"}</Breadcrumb.Item>
      </Breadcrumb>
      <Card className='card customers-add-edit-card '>
        <Card.Body>
          <div className='card-body-header'>
            <div className='.item1  .flex-item'>
              <h6 className='card-body-header-title'>Spa Paragon</h6>
            </div>
            <div className='card-body-filters'>
              {customerId &&
                // <InputGroup
                //   placeholder={"Change Status"}
                //   name={"status"}
                //   value={formData?.status}
                //   error={error?.status}
                //   onChange={callStatusUpdateApi}
                //   formClassName="col-6 col-sm-6 col-md-6 col-lg-4"
                //   type="select"
                //   select
                //   option={statusOptionList}
                // />
                <Dropdown className='col-lg-3 status-dropdown ' onSelect={callStatusUpdateApi} >
                  <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle ' id="dropdown-basic">
                    <h6 className=''>{formData?.status}</h6>
                    <Icon image attrIcon={App_url.img.DropDownIcon} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='dropdown-menu'>
                    {statusOptionList && statusOptionList.map(option => {
                      const bulletIcon = iconMap[option?.value] || App_url.img.RedBulletIcon;

                      return (
                        <Dropdown.Item key={option?.value} eventKey={option?.value} style={{ paddingRight: '8px' }}>
                          <div className='check_menu'>
                            <span className='bulleticons'>
                              <Icon image attrIcon={bulletIcon} />{option?.value}
                            </span>
                            <span className='title'>
                              {formData?.status === option?.value
                                ? <Icon image attrIcon={App_url.img.Radiobutton2} className='' />
                                : <Icon image attrIcon={App_url.img.RadioCircleIcon} className='' />}
                            </span>
                          </div>
                        </Dropdown.Item>
                      );
                    })}

                  </Dropdown.Menu>
                </Dropdown>
              }
            </div>
              <Button onClick={onSubmit} className={""} variant={"primary"}>Save</Button>
          </div>
          <hr className='card-body-header-hr'></hr>
          <Form onSubmit={onSubmit} className='row'>
            <div className='col-lg-12 mb-2'>
              <h6 className='title-info'>Company Details</h6>
            </div>
            <div className='col-lg-8'>
              <div className='row'>
                <InputGroup
                  label={"Company Name"}
                  placeholder={"Enter company name"}
                  formClassName="col-lg-6 mb-3"
                  name="company_name"
                  value={formData?.company_name}
                  error={error?.company_name}
                  onChange={onChange}
                />
                <InputGroup
                  label={"Phone Number"}
                  placeholder={"Enter phone number"}
                  formClassName="col-lg-6 mb-3"
                  name="company_mobile"
                  value={formData?.company_mobile}
                  error={error?.company_mobile}
                  onChange={onChange}
                  rightLabel={<Icon image attrIcon={App_url.img.PhoneRingIcon} />}
                />
                <InputGroup
                  label={"Industry Type"}
                  placeholder={"Enter industry type"}
                  formClassName="col-lg-6 mb-3"
                  name="industry_type"
                  value={formData?.industry_type}
                  error={error?.industry_type}
                  onChange={onChange}
                // select
                // type={'select'}
                />
                <InputGroup
                  label={"Customer Type"}
                  placeholder={"Select prospect customer"}
                  formClassName="col-lg-6 mb-3"
                  name="customer_type"
                  option={customerTypeOptions}
                  value={formData?.customer_type ? formData?.customer_type : customerTypeOptions[0].value}
                  error={error?.customer_type}
                  onChange={onChange}
                  select
                  type={'select'}
                />
              </div>
            </div>

            <InputGroup
              label={"Company Description:"}
              placeholder={"Enter company description..."}
              formClassName="mb-3 col-12 col-lg-4"
              name="company_description"
              as={"textarea"}
              rows='5'
              value={formData?.company_description}
              error={error?.company_description}
              onChange={onChange}
            />
            <div className='col-lg-12 mb-2'>
              <h6 className='title-info'>Contact Person</h6>
            </div>
            <InputGroup
              label={"First Name"}
              placeholder={"Enter first name"}
              formClassName="col-lg-4 mb-3"
              name="customer_name"
              value={formData?.customer_name}
              error={error?.customer_name}
              onChange={onChange}
            />
            <InputGroup
              label={"Last Name"}
              placeholder={"Enter last name"}
              formClassName="col-lg-4 mb-3"
              name="last_name"
              value={formData?.last_name}
              error={error?.last_name}
              onChange={onChange}
            />
            <InputGroup
              label={"Mobile Number"}
              placeholder={"Enter mobile number"}
              formClassName="col-lg-4 mb-3"
              name="mobile"
              value={formData?.mobile}
              error={error?.mobile}
              onChange={onChange}
              rightLabel={<Icon image attrIcon={App_url.img.PhoneRingIcon} />}
            />
            <InputGroup
              label={"Email ID"}
              placeholder={"Enter email id"}
              formClassName="col-lg-4 mb-3"
              name="email"
              value={formData?.email}
              error={error?.email}
              onChange={onChange}
              rightLabel={<Icon image attrIcon={App_url.img.EmailIcon3} />}
            />
            <InputGroup
              label={"Designation"}
              placeholder={"Enter designation"}
              formClassName="col-lg-4 mb-3"
              name="customer_position"
              value={formData?.customer_position}
              error={error?.customer_position}
              onChange={onChange}
            />
            <div className='col-lg-4'></div>
            <div className='col-lg-12 mb-2'>
              <h6 className='title-info'>Address Details</h6>
            </div>

            <InputGroup
              label={"Street Number"}
              placeholder={"Enter street number"}
              formClassName="col-lg-4 mb-3"
              name="street_no"
              value={formData?.street_no}
              error={error?.street_no}
              onChange={onChange}
            />
            <InputGroup
              label={"City"}
              placeholder={"Enter city"}
              formClassName="col-lg-4 mb-3"
              name="city"
              value={formData?.city}
              error={error?.city}
              onChange={onChange}
            />
            <InputGroup
              label={"Post Code"}
              placeholder={"Enter post code"}
              formClassName="col-lg-4 mb-3"
              name="postal_code"
              value={formData?.postal_code}
              error={error?.postal_code}
              onChange={onChange}
            />
            <InputGroup
              label={"Address"}
              placeholder={"Enter address"}
              formClassName="col-lg-8 mb-3"
              name="company_address"
              value={formData?.company_address}
              error={error?.company_address}
              onChange={onChange}
              rightLabel={<Icon image attrIcon={App_url.img.LocationIcon2} />}

            />
          </Form>

        </Card.Body>
      </Card>
    </ContentLayout >
  )
}
