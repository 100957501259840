import wsSendRequest from "../../socketIO/wsSendRequest";

const callAppointmentList = (props) => {
    const param = {
      transmit: "broadcast",
      url: "appointment_list",
      request: {
        status: "",
        year_filter: "",
        limit: "500",
        page_no: "1",
        search: "",
        order_by: "-updated_at",
        customer_id: props?.customerId,
      },
    };
    wsSendRequest(props?.ws, param, props?.device_id)
  }

  const callAppointmentDetailsList = (props) =>{
    // console.log("pros", props);
    const param = {
      transmit: "broadcast",
      url: "appointment_detail",
      request: {
          appointment_id: props?.appointment_id,
      }
  };
  wsSendRequest(props?.ws, param, props?.device_id);
  }
  const callCustomerDetailsApi = (props) => {
    const param = {
      transmit: "broadcast",
      url: "customer_detail",
      request: { customer_id: props?.customer_id }
    };
    wsSendRequest(props?.ws, param, props?.device_id);
  }

  const UtilsGetList = {
    callAppointmentList:callAppointmentList,
    callAppointmentDetailsList:callAppointmentDetailsList,
    callCustomerDetailsApi:callCustomerDetailsApi,
  }

  export default UtilsGetList;