/* eslint-disable */
const App_url = {
    image: {
        preloadImage: [`/vercel.svg`],
        main_logo: '/main_logo.png',
        logo: '/assets/image/logo.svg',
        preloadImage: [`/vercel.svg`],
        main_logo: '/main_logo.png',
        ComplaintManagement: `${window.location.origin}/assets/image/ComplaintManagement.png`,
        ViewDoc: `${window.location.origin}/assets/image/ViewDoc.png`,
        CustomerManagement: `${window.location.origin}/assets/image/CustomerManagement.png`,
        Employers: `${window.location.origin}/assets/image/Employers.png`,
        Sales: `${window.location.origin}/assets/image/Sales.png`,
        Statistics: `${window.location.origin}/assets/image/Statistics.png`,
        Tax: `${window.location.origin}/assets/image/Tax.png`,
        RingCallBg: `${window.location.origin}/assets/image/RingCallBg.png`,
        RingCallUserImg: `${window.location.origin}/assets/image/RingCallUserImg.png`,
        GlassCleaningImg: `${window.location.origin}/assets/image/GlassCleaning.png`,
        FullCleaningImg: `${window.location.origin}/assets/image/FullCleaning.png`,
        IntervalCleaningImg: `${window.location.origin}/assets/image/IntervalCleaning.png`,
        MaintainenceCleaningImg: `${window.location.origin}/assets/image/MaintainenceCleaning.png`,
        BuildingDivisionImg: `${window.location.origin}/assets/image/BuildingDivision.png`,

    },

    img: {
        logo: `${window.location.origin}/assets/img/logo.svg`,
        ArrowDown: `${window.location.origin}/assets/img/ArrowDown.svg`,
        DashboardIcon: `${window.location.origin}/assets/img/DashboardIcon.svg`,
        SalesProgressIcon: `${window.location.origin}/assets/img/SalesProgressIcon.svg`,
        OffersIcon: `${window.location.origin}/assets/img/OffersIcon.svg`,
        CalculatorIcon: `${window.location.origin}/assets/img/CalculatorIcon.svg`,
        DocumentIcon: `${window.location.origin}/assets/img/DocumentIcon.svg`,
        ServicesIcon: `${window.location.origin}/assets/img/ServicesIcon.svg`,
        GearIcon: `${window.location.origin}/assets/img/GearIcon.svg`,
        CalendarIcon: `${window.location.origin}/assets/img/CalendarIcon.svg`,
        CalendarIcon3: `${window.location.origin}/assets/img/CalendarIcon3.svg`,
        LogoutIcon: `${window.location.origin}/assets/img/LogoutIcon.svg`,
        SearchIcon: `${window.location.origin}/assets/img/SearchIcon.svg`,
        CloseIcon: `${window.location.origin}/assets/img/CloseIcon.svg`,
        AngleRightIcon: `${window.location.origin}/assets/img/AngleRightIcon.svg`,
        MainLogo: `${window.location.origin}/assets/img/MainLogo.svg`,
        EyeIcon: `${window.location.origin}/assets/img/EyeIcon.svg`,
        CrossWhiteIcon: `${window.location.origin}/assets/img/CrossWhiteIcon.svg`,
        CirclePlusIcon: `${window.location.origin}/assets/img/CirclePlusIcon.svg`,
        BuildingIcon: `${window.location.origin}/assets/img/BuildingIcon.svg`,
        WorldLocationIcon: `${window.location.origin}/assets/img/WorldLocationIcon.svg`,
        UserInBoxIcon: `${window.location.origin}/assets/img/UserInBoxIcon.svg`,
        EmailIcon: `${window.location.origin}/assets/img/EmailIcon.svg`,
        CalendarTwoIcon: `${window.location.origin}/assets/img/CalendarTwoIcon.svg`,
        UserPlusIcon: `${window.location.origin}/assets/img/UserPlusIcon.svg`,
        RepeatCircleIcon: `${window.location.origin}/assets/img/RepeatCircleIcon.svg`,
        AlertIcon: `${window.location.origin}/assets/img/AlertIcon.svg`,
        UpDownIcon: `${window.location.origin}/assets/img/UpDownIcon.svg`,
        RightIcon: `${window.location.origin}/assets/img/RightIcon.svg`,
        LeftArrowIcon: `${window.location.origin}/assets/img/LeftArrowIcon.svg`,
        CircleCross: `${window.location.origin}/assets/img/CircleCross.svg`,
        CircleInfoIcon: `${window.location.origin}/assets/img/CircleInfoIcon.svg`,
        EditIcon: `${window.location.origin}/assets/img/EditIcon.svg`,
        CrossWhiteIcon: `${window.location.origin}/assets/img/CrossWhiteIcon.svg`,
        CirclePlusIcon: `${window.location.origin}/assets/img/CirclePlusIcon.svg`,
        WorldLocationIcon: `${window.location.origin}/assets/img/WorldLocationIcon.svg`,
        UserInBoxIcon: `${window.location.origin}/assets/img/UserInBoxIcon.svg`,
        EmailIcon: `${window.location.origin}/assets/img/EmailIcon.svg`,
        CalendarTwoIcon: `${window.location.origin}/assets/img/CalendarTwoIcon.svg`,
        UserPlusIcon: `${window.location.origin}/assets/img/UserPlusIcon.svg`,
        RepeatCircleIcon: `${window.location.origin}/assets/img/RepeatCircleIcon.svg`,
        AlertIcon: `${window.location.origin}/assets/img/AlertIcon.svg`,
        UpDownIcon: `${window.location.origin}/assets/img/UpDownIcon.svg`,
        RightIcon: `${window.location.origin}/assets/img/RightIcon.svg`,
        LeftArrowIcon: `${window.location.origin}/assets/img/LeftArrowIcon.svg`,
        CircleCross: `${window.location.origin}/assets/img/CircleCross.svg`,
        CircleInfoIcon: `${window.location.origin}/assets/img/CircleInfoIcon.svg`,
        DeleteIcon: `${window.location.origin}/assets/img/DeleteIcon.svg`,
        RedCrossIcon: `${window.location.origin}/assets/img/RedCrossIcon.svg`,
        AlertIcon2: `${window.location.origin}/assets/img/AlertIcon2.svg`,
        BuildingIcon2: `${window.location.origin}/assets/img/BuildingIcon2.svg`,
        CalendarIcon3: `${window.location.origin}/assets/img/CalendarIcon3.svg`,
        ClockIcon: `${window.location.origin}/assets/img/ClockIcon.svg`,
        DeleteIcon: `${window.location.origin}/assets/img/DeleteIcon.svg`,
        DirectionIcon: `${window.location.origin}/assets/img/DirectionIcon.svg`,
        DocIcon: `${window.location.origin}/assets/img/DocIcon.svg`,
        EditIcon: `${window.location.origin}/assets/img/EditIcon.svg`,
        EmailIcon2: `${window.location.origin}/assets/img/EmailIcon2.svg`,
        LocationIcon: `${window.location.origin}/assets/img/LocationIcon.svg`,
        PdfIcon2: `${window.location.origin}/assets/img/PdfIcon2.svg`,
        ExcelIcon: `${window.location.origin}/assets/img/ExcelIcon.svg`,
        WordIcon: `${window.location.origin}/assets/img/WordIcon.svg`,
        PPTIcon: `${window.location.origin}/assets/img/PPTIcon.svg`,
        CSVIcon: `${window.location.origin}/assets/img/CSVIcon.svg`,
        TEXTIcon: `${window.location.origin}/assets/img/TEXTIcon.svg`,
        PhoneIcon: `${window.location.origin}/assets/img/PhoneIcon.svg`,
        UserIcon: `${window.location.origin}/assets/img/UserIcon.svg`,
        AaIcon: `${window.location.origin}/assets/img/AaIcon.svg`,
        CirclesTwoIcon: `${window.location.origin}/assets/img/CirclesTwoIcon.svg`,
        RadioCircleIcon: `${window.location.origin}/assets/img/RadioCircleIcon.svg`,
        RadioIcon: `${window.location.origin}/assets/img/RadioIcon.svg`,
        NoteIcon: `${window.location.origin}/assets/img/NoteIcon.svg`,
        RepeatIcon2: `${window.location.origin}/assets/img/RepeatIcon2.svg`,
        CircleSuccess: `${window.location.origin}/assets/img/CircleSuccess.svg`,
        TelephoneIcon: `${window.location.origin}/assets/img/TelephoneIcon.svg`,
        CallIcon: `${window.location.origin}/assets/img/CallIcon.svg`,
        MobileIcon: `${window.location.origin}/assets/img/MobileIcon.svg`,
        DiscardIcon: `${window.location.origin}/assets/img/DiscardIcon.svg`,
        AttachmentIcon: `${window.location.origin}/assets/img/AttachmentIcon.svg`,
        StarNotSelected: `${window.location.origin}/assets/img/StarNotSelected.svg`,
        StarSelected: `${window.location.origin}/assets/img/StarSelected.svg`,
        LinkIcon: `${window.location.origin}/assets/img/LinkIcon.svg`,
        LinkIcon2: `${window.location.origin}/assets/img/LinkIcon2.svg`,
        BoldIcon: `${window.location.origin}/assets/img/BoldIcon.svg`,
        ItalicIcon: `${window.location.origin}/assets/img/ItalicIcon.svg`,
        UnderlineIcon: `${window.location.origin}/assets/img/UnderlineIcon.svg`,
        OrderedListIcon: `${window.location.origin}/assets/img/OrderedListIcon.svg`,
        UnorderedListIcon: `${window.location.origin}/assets/img/UnorderedListIcon.svg`,
        AttachmentIcon2: `${window.location.origin}/assets/img/AttachmentIcon2.svg`,
        ImageEditorIcon: `${window.location.origin}/assets/img/ImageEditorIcon.svg`,
        LoaderIcon: `${window.location.origin}/assets/img/LoaderIcon.svg`,
        PhoneRingIcon: `${window.location.origin}/assets/img/PhoneRingIcon.svg`,
        EmailIcon3: `${window.location.origin}/assets/img/EmailIcon3.svg`,
        LocationIcon2: `${window.location.origin}/assets/img/LocationIcon2.svg`,
        GalleryIcon: `${window.location.origin}/assets/img/GalleryIcon.svg`,
        GalleryIcon2: `${window.location.origin}/assets/img/GalleryIcon2.svg`,


        RedBulletIcon: `${window.location.origin}/assets/img/RedBulletIcon.svg`,
        GrayBulletIcon: `${window.location.origin}/assets/img/GrayBulletIcon.svg`,
        YellowBulletIcon: `${window.location.origin}/assets/img/YellowBulletIcon.svg`,
        GreenBulletIcon: `${window.location.origin}/assets/img/GreenBulletIcon.svg`,
        BlueBulletIcon: `${window.location.origin}/assets/img/BlueBulletIcon.svg`,
        Radiobutton2: `${window.location.origin}/assets/img/Radiobutton2.svg`,
        DropDownIcon: `${window.location.origin}/assets/img/DropDownIcon.svg`,
        PlusIcon: `${window.location.origin}/assets/img/PlusIcon.svg`,



    },

    link: {
        home: "/",
        login: "/login",
        signUp: "/signup",
        dashboard: "/dashboard",
        prospects: "/sales/prospects",
        prospectsDetails: "/sales/prospects",
        prospectsTodayDetails: "/sales/prospects/today",
        ProspectsAdd: "/sales/prospects/add",
        ProspectsEdit: "/sales/prospects/edit",
        ProspectsAddRemark: "/sales/prospects/remarks",
        customers: "/sales/customers",
        savedCalculation: "/calculation/saved",
        savedCalculationAdd: "/calculation/saved/add",
        savedCalculationCategory: "/calculation/saved/category",
        savedCalculationFullCleaning: "/calculation/saved/full-cleaning",
        savedCalculationCategoryOverview: "/calculation/saved/category/overview",
        savedCalculationIntervalCleaning: "/calculation/saved/interval-cleaning",
        newCalculation: "/calculation/new",
        offers: "/offers",
        documentationList: "/documentation/list",
        browseList: "/documentation/browse-list",
        services: "/services",

    },
    // reducers
    modalReducers: (state) => state.modalReducers,
    userDataReducers: (state) => state.userDataReducers,
    customerReducers: (state) => state.customerReducers,


    api: {
        USER_LOGIN: "user/login",
        COMPOSE_MAIL:"outlook/create_email",
        SYNC_INBOX_MAIL:"outlook/sync_inbox_email",
        SYNC_SENT_MAIL:"outlook/sync_sent_email",
        GET_MAIL_DETAILS:"outlook/get_email/",
        CREATE_APPOINTMENT:"appointment/create_appointment",
        RESCHEDULE_APPOINTMENT:"appointment/reschedule",
        CANCEL_APPOINTMENT:"appointment/cancel_appointment",
        COMPLETED_APPOINTMENT:"appointment/update_appointment_status",
        GET_BOOKED_SLOTS_TIME:"user/get_schedule",
        USER_DEL_LOGIN: "user/del_login",
        USER_LOG_OUT: "user/logout",
        FILE_UPLOAD: "file/upload",
        GET_MAGIC_CODE: "user/get_magic_code",
        USER_SET_LOGIN: "user/set_login",
        USER_DETAILS: "user/get",
        CREATE_APPOINTMENT: "appointment/create_appointment",
        DELETE_APPOINTMENT: "appointment/delete_appointment",
        CANCEL_APPOINTMENT: "appointment/cancel_appointment",
        RESCHEDULE_APPOINTMENT: "appointment/reschedule",
        UPDATE_APPOINTMENT: "appointment/update_appointment"
    }
}


export default App_url;