import React, { useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Breadcrumb, Card, Dropdown, Table } from 'react-bootstrap'
import Button from '../Common/Button'
import InputGroup from '../Common/InputGroup'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import AnchorLink from '../Common/AnchorLink'
import AdditionalCostModal from './AdditionalCostModal'
import { useNavigate } from 'react-router-dom'

const SelectCategory = () => {
    const navigate = useNavigate();
    const [selectedFreq, setSelectedFreq] = useState(null);
    const [selectedOption, setSelectedOption] = useState('performance');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption2, setSelectedOption2] = useState(null); 

    const statusOptionList = [
        { label: "Glass Inside", value: "Glass Inside" },
        { label: "Glass Outside", value: "Glass Outside" },
        { label: "Glass OutsideGlass Outside with Frame", value: "Glass OutsideGlass Outside with Frame" },
        { label: "Share Brochure", value: "Share Brochure" },
        { label: "Glass  Outside with Frame", value: "Glass  Outside with Frame" },
    ]
    const selectFrequencyList = [
        { label: "Once in a Year (1 T)", value: "Once in a Year (1 T)" },
        { label: "Twice in a Year (2 T)", value: "Twice in a Year (2 T)" },
        { label: "Six Times in a Year (6 T)", value: "Six Times in a Year (6 T)" },
        { label: "Once in a Month (12 T)", value: "Once in a Month (12 T)" },
        { label: "Twice in a Month (24 T)", value: "Twice in a Month (24 T)" },
        { label: "Once in a Week (52 T)", value: "Once in a Week (52 T)" },
        { label: "Twice in a Week (104 T)", value: "Twice in a Week (104 T)" },
    ]

    const handleSelect = (eventKey) => {
        const selected = statusOptionList.find(option => option.value === eventKey);
        setSelectedOption2(selected);
    };

    const handleSelectFreq = (eventKey) => {
        const selected = selectFrequencyList.find(option => option.value === eventKey);
        setSelectedFreq(selected);
    }


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const callBack = (e, state) => {
        navigate(App_url.link.savedCalculationCategoryOverview);
    }

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value); 
    };
    return (
        <ContentLayout title={'Add New Calculation'}>
            <div className='category mx-1'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.savedCalculation}>Saved Calculation </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >Select Category </Breadcrumb.Item>
                    <Breadcrumb.Item active> Glass Cleaning</Breadcrumb.Item>
                </Breadcrumb>
                <div className='row mt-2'>
                    <div className='col-8'>
                        <Card className='card '>
                            <Card.Body>
                                <div className='card-body-header'>
                                    <div className='.item1  .flex-item'>
                                        <h6 className='card-body-header-title'>Glass Cleaning</h6>
                                    </div>
                                </div>
                                {/* <hr className='card-body-header-hr'></hr> */}
                                <div className='heading'>
                                    <h6>Customer : Tata Motor</h6>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <label htmlFor="form_group form-label">Select Service type</label>
                                        <Dropdown className='status-dropdown ' onSelect={handleSelect}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {selectedOption2 ? selectedOption2.value : 'Type  Or Select Service'}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {statusOptionList && statusOptionList.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {selectedOption2?.value === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className='mt-3'>
                                            <label htmlFor="">Select cleaning days / year frequency</label>
                                            <Dropdown className='status-dropdown' onSelect={handleSelectFreq}>
                                                <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                    {selectedFreq ? selectedFreq.value : 'Select cleaning days or year frequency'}
                                                    <Icon image attrIcon={App_url.img.DropDownIcon} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='dropdown-menu'>
                                                    {selectFrequencyList && selectFrequencyList.map(option => (
                                                        <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                            <div className='check_menu'>
                                                                <span>
                                                                    {option.label}
                                                                </span>
                                                                {selectedFreq?.value === option.value && (
                                                                    <span className='title'>
                                                                        <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className='col '>
                                        <InputGroup
                                            label={"Surface area (in m2)"}
                                            placeholder={"Type Or Select Service"}
                                            name={"status"}
                                            // value={formData?.status}
                                            // error={error?.status}
                                            // onChange={callStatusUpdateApi}
                                            // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                            type="text"
                                            rightLabel={'m2'}
                                        // option={statusOptionList}
                                        />
                                    </div>
                                </div>
                                <div className='radios'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    value="performance"
                                                    checked={selectedOption === 'performance'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Performance
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault2"
                                                    value="standard"
                                                    checked={selectedOption === 'standard'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                    Standard
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-3'>
                                    <div className='col'>
                                        <InputGroup
                                            label={"Value in m2 /Hr"}
                                            placeholder={"Type Or Select Service"}
                                            name={"status"}
                                            type="text"
                                            rightLabel={'m2/Hr'}
                                            disabled={selectedOption !== 'performance'} 
                                            className={selectedOption === 'performance'} 
                                        />
                                        <InputGroup
                                            label={"Hourly Rate (in €)"}
                                            placeholder={"20"}
                                            name={"status"}
                                            formClassName="input-text mt-3"
                                            type="text"
                                            rightLabel={'€'}
                                            disabled={selectedOption !== 'performance'} 
                                            className={selectedOption === 'performance'} 
                                        />
                                    </div>
                                    <div className='col'>
                                        <InputGroup
                                            label={"Value in € / m2"}
                                            placeholder={"Type Or Select Service"}
                                            name={"status"}
                                            type="text"
                                            rightLabel={'€/m2'}
                                            disabled={selectedOption !== 'standard'}
                                            className={selectedOption === 'standard'}

                                        />
                                    </div>
                                </div>

                                <div onClick={openModal} className='preview mt-3'>
                                    <h6>+ Additional Cost</h6>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className=' col-4 ps-0'>
                        <Card className='card '>
                            <Card.Body>
                                <div className='preview'>
                                    <h6 className=''>Preview Summary</h6>
                                </div>
                                <div className='custom-table-preview'>
                                    <Card>
                                        <Table>
                                            <thead className=''>
                                                <tr>
                                                    <th>Particular</th>
                                                    <th>Hours</th>
                                                    <th >Cost</th>
                                                </tr>
                                            </thead>
                                            <tbody className='table-with-radius'>
                                                <tr >
                                                    <td>Each</td>
                                                    <td>2</td>
                                                    <td>€ 100</td>
                                                </tr>
                                                <tr >
                                                    <td>Yearly </td>
                                                    <td>24</td>
                                                    <td>€ 20</td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </Card>
                                </div>
                                <div className='preview'>
                                    <h6 className='addCost'>Additional Cost</h6>
                                </div>
                                <div className='custom-table-preview'>
                                    <Card>
                                        <Table>
                                            <thead className=''>
                                                <tr>
                                                    <th>Particular</th>
                                                    <th>Frequency</th>
                                                    <th >Cost</th>
                                                </tr>
                                            </thead>
                                            <tbody className='table-with-radius'>
                                                <tr >
                                                    <td>Scaffold</td>
                                                    <td>2</td>
                                                    <td>€ 100</td>
                                                </tr>
                                                <tr >
                                                    <td>Material</td>
                                                    <td>24</td>
                                                    <td>€ 20</td>
                                                </tr>

                                            </tbody>
                                        </Table>

                                    </Card>
                                </div>
                                <div className=''>
                                    <Button onClick={callBack} size={'md'} variant={"primary"} className={'w-100  mt-5'}>Submit this Service</Button>

                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <AdditionalCostModal isModalOpen={isModalOpen} handleClose={closeModal} />
                </div>
            </div>
        </ContentLayout >
    )
}

export default SelectCategory