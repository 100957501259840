import React, { useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import { Breadcrumb, Card, Dropdown, Table } from 'react-bootstrap'
import AnchorLink from '../../Common/AnchorLink'
import App_url from '../../Common/Constants'
import Button from '../../Common/Button'
import InputGroup from '../../Common/InputGroup'
import Icon from '../../Common/Icon'
import CustomTableTwo from '../../Common/CustomTableTwo'

const IntervalCleaning = () => {
    const [selectedRoomType, setSelectedRoomType] = useState(null);
    const [selectedCleaningType, setSelectedCleaningType] = useState(null);
    const [selectedWeekFreq, setSelectedWeekFreq] = useState(null);
    const [selectedSunFreq, setSelectedSunFreq] = useState(null);
    const selectRoomTypeList = [
        { label: "Office 1", value: "Office 1" },
        { label: "Office 2", value: "Office 2" },
        { label: "Office 3", value: "Office 3" },
        { label: "Toilet 1", value: "Toilet 1" },
        { label: "Toilet 2", value: "Toilet 2" },
        { label: "Staire Case", value: "Staire Case" },
        { label: "Passage", value: "Passage" },
        { label: "Reception", value: "Reception" },

    ]
    const selectCleanTypeList = [
        { label: "Furniture Cleaning", value: "Furniture Cleaning" },
        { label: "Empty Garbage", value: "Empty Garbage" },
        { label: "Clean", value: "Clean" },
    ]
    const handleSelectRoomType = (eventKey) => {
        const selected = selectRoomTypeList.find(option => option.value === eventKey);
        setSelectedRoomType(selected);
    }
    const handleSelectCleanType = (eventKey) => {
        const selected = selectCleanTypeList.find(option => option.value === eventKey);
        setSelectedCleaningType(selected);
    }
    const selectWeekDayList = [
        { label: "Once a week", value: "Once a week" },
        { label: "Twice a week", value: "Twice a week" },
        { label: "Three times a week", value: "Three times a week" },
        { label: "Four times a week", value: "Four times a week" },
        { label: "Five times a week	", value: "Five times a week" },
        { label: "Six times a week", value: "Six times a week" },
        { label: "Four times a month", value: "Four times a month" },
        { label: "Three times a month", value: "Three times a month" },
        { label: "Twice a month", value: "Twice a month" },
        { label: "One a month", value: "One a month" },
    ]

    const statusSundayList = [
        { label: "Once a week", value: "Once a week" },
        { label: "Four times a month", value: "Four times a month" },
        { label: "Three times a month", value: "Three times a month" },
        { label: "Twice a month", value: "Twice a month" },
        { label: "Once a month", value: "Once a month" },
    ]

    const handleSelectWeekDay = (eventKey) => {
        const selected = selectWeekDayList.find(option => option.value === eventKey);
        setSelectedWeekFreq(selected);
    }
    const handleSelect = (eventKey) => {
        const selected = statusSundayList.find(option => option.value === eventKey);
        setSelectedSunFreq(selected);
    };
    const CallHistory = [
        { particular: "Sunday", hours: '5', cost: "€ 0" },
        { particular: "Weekday", hours: '20', cost: "€ 0" },
        { particular: "Monthly", hours: '25', cost: "€ 0" },
        { particular: "Yearly", hours: '300', cost: "€ 0" },

    ]
    const RenderBody = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment className='dashTitle'>
                <td>{item?.particular}</td>
                <td     >{item?.hours}</td>
                <td>{item?.cost}</td>
            </React.Fragment>
        )
    }
    return (
        <ContentLayout title={'Interval Cleaning'}>
            <div className='interval'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.savedCalculation}>Saved Calculation </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >Select Category </Breadcrumb.Item>
                    <Breadcrumb.Item active>Interval Cleaning</Breadcrumb.Item>
                </Breadcrumb>
                <div className='first row'>
                    <div className='col-8   '>
                        <Card>
                            <Card.Body>
                                <div className='card-body-header'>
                                    <div className='.item1  .flex-item'>
                                        <h6 className='card-body-header-title'>Full Cleaning</h6>
                                    </div>
                                </div>
                                <hr className='card-body-header-hr'></hr>
                                <div className='heading'>
                                    <h6>Customer : Tata Motor</h6>
                                </div>
                                <div className='rate'>
                                    <div className='row'>
                                        <div className='preview'>
                                            <h6>Hourly Rate (in € )</h6>
                                        </div>
                                        <div className='col-5'>

                                            <label>Employer Per Hour Cost</label>
                                            <InputGroup
                                                placeholde={"13.50"}
                                                name={"status"}
                                                // value={formData?.status}
                                                // error={error?.status}
                                                // onChange={callStatusUpdateApi}
                                                // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                                type="text"
                                                rightLabel={' €'}
                                            // option={statusOptionList}
                                            />

                                        </div>
                                        <div className='col-5'>
                                            <label>Company surcharges in %</label>
                                            <InputGroup
                                                placeholder={"100"}
                                                name={"status"}
                                                // value={formData?.status}
                                                // error={error?.status}
                                                // onChange={callStatusUpdateApi}
                                                // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                                type="text"
                                                rightLabel={' %'}
                                            // option={statusOptionList}
                                            />
                                        </div>
                                        <div className='col-2 text-rate'>
                                            <h6> 27.00 € </h6>
                                        </div>
                                    </div>

                                </div>
                                <div className=' row mt-3'>
                                    <div className='col'>
                                        <label htmlFor=" ">Select Room type</label>
                                        <Dropdown className='status-dropdown ' onSelect={handleSelectRoomType}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {selectedRoomType ? selectedRoomType.value : 'Office 1'}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {selectRoomTypeList && selectRoomTypeList.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {selectedRoomType?.value === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor=" ">Type Of Cleaning</label>
                                        <Dropdown className='status-dropdown ' onSelect={handleSelectCleanType}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {selectedCleaningType ? selectedCleaningType.value : 'Furniture Cleaning'}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {selectCleanTypeList && selectCleanTypeList.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {selectedCleaningType?.value === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                </div>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <InputGroup
                                            label={"surface area (in m2)"}
                                            placeholder={"1000"}
                                            name={"status"}
                                            // value={formData?.status}
                                            // error={error?.status}
                                            // onChange={callStatusUpdateApi}
                                            // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                            type="text"
                                            rightLabel={' m2'}
                                        // option={statusOptionList}
                                        />
                                    </div>
                                    <div className='col'>
                                        <InputGroup
                                            label={"Performance Value"}
                                            placeholder={"200"}
                                            name={"status"}
                                            // value={formData?.status}
                                            // error={error?.status}
                                            // onChange={callStatusUpdateApi}
                                            // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                            type="text"
                                            rightLabel={' m2/Hr'}
                                        // option={statusOptionList}
                                        />
                                    </div>
                                </div>
                                <div className=' row mt-3'>

                                    <div className='col'>
                                        <label htmlFor=" ">Weekday Frequency</label>
                                        <Dropdown className='status-dropdown ' onSelect={handleSelectWeekDay}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {selectedWeekFreq ? selectedWeekFreq.value : '4Times in a Month (48 T)'}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {selectWeekDayList && selectWeekDayList.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {selectedWeekFreq?.value === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='col '>
                                        <label htmlFor="">Sunday Frequency</label>
                                        <Dropdown className='status-dropdown' onSelect={handleSelect}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {selectedSunFreq ? selectedSunFreq.value : 'Once in a Month (12 T)'}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {statusSundayList && statusSundayList.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {selectedSunFreq?.value === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>

                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col-4'>
                        <Card className='card '>
                            <Card.Body>
                                <div className='preview'>
                                    <h6 className=''>Preview Summary</h6>
                                </div>
                                <div className='custom-table-preview'>
                                    <CustomTableTwo
                                        title={{ text: "Surface Area : 1000 m", size: "fs-14", textTwo: "" }}
                                        columnNames={["Particular", "Hours", "Cost"]}
                                        recordData={CallHistory}
                                        renderBody={RenderBody}
                                    />
                                </div>


                                <div className=''>
                                    <Button size={'md'} variant={"primary"} className={'w-100  mt-5'}>Submit this Service</Button>

                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className='interval-prev '>
                    <Card className='card'>
                        <Card.Body>
                            <div className='preview'>
                                <h6>Preview Summary</h6>
                            </div>
                            <Table bordered>
                                <thead className=''>
                         
                                    <tr>
                                        <th className="top-left-radius">Action</th>
                                        <th>Room Type</th>
                                        <th >Type of Cleaning</th>
                                        <th>Area M2</th>
                                        <th>Weekday</th>
                                        <th>Sunday</th>
                                        <th>M2/Hour</th>
                                        <th>Monthly Cost</th>
                                        <th className="top-right-radius">Yearly Cost</th>
                                    </tr>
                                </thead>
                                <tbody className='table-with-radius'>
                                    <tr >
                                        <td className='text-center'>
                                            <Icon className={"header-calendar-icon th-header-icon me-2"} attrIcon={App_url.img.EditIcon} />
                                            <Icon className={"header-calendar-icon th-header-icon "} attrIcon={App_url.img.DeleteIcon} />
                                        </td>
                                        <td>Office 1</td>
                                        <td>Furniture Cleaning</td>
                                        <td>1000</td>
                                        <td>3</td>
                                        <td>1</td>
                                        <td>100</td>
                                        <td>€ 200,00</td>
                                        <td>€ 2.400,00</td>

                                    </tr>
                                    <tr >
                                        <td className='text-center'>
                                            <Icon className={"header-calendar-icon th-header-icon me-2"} attrIcon={App_url.img.EditIcon} />
                                            <Icon className={"header-calendar-icon th-header-icon  "} attrIcon={App_url.img.DeleteIcon} />
                                        </td>
                                        <td>Office 1</td>
                                        <td>Empty Garbage</td>
                                        <td>1000</td>
                                        <td>4</td>
                                        <td>1</td>
                                        <td>100</td>
                                        <td>€ 270</td>
                                        <td>€ 3.240,00</td>

                                    </tr>
                                    <tr >
                                        <td className='text-center'>
                                            <Icon className={"header-calendar-icon th-header-icon  me-2"} attrIcon={App_url.img.EditIcon} />
                                            <Icon className={"header-calendar-icon th-header-icon  "} attrIcon={App_url.img.DeleteIcon} />
                                        </td>
                                        <td>Toilet 1</td>
                                        <td>Clean</td>
                                        <td>50</td>
                                        <td>4</td>
                                        <td>1</td>
                                        <td>200</td>
                                        <td>€ 200,00</td>
                                        <td>€ 2.400,00</td>
                                    </tr>
                                    <tr >
                                        <td className='text-center'>
                                            <Icon className={"header-calendar-icon th-header-icon  me-2"} attrIcon={App_url.img.EditIcon} />
                                            <Icon className={"header-calendar-icon th-header-icon "} attrIcon={App_url.img.DeleteIcon} />
                                        </td>
                                        <td>Toilet 1</td>
                                        <td>Empty Garbage</td>
                                        <td>50</td>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>100</td>
                                        <td>€ 270</td>
                                        <td>€ 3.240,00</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </ContentLayout>
    )
}

export default IntervalCleaning