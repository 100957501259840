import React from 'react'
import ContentLayout from '../../layout/ContentLayout'

export default function CustomersPage() {
  return (
    <ContentLayout title={"Customers Page"}>
    <h6>Working on Customers page...</h6>
    </ContentLayout>    
  )
}
