import React, { useState } from 'react'
import CustomTableTwo from '../Common/CustomTableTwo'
import Icon from '../Common/Icon';
import App_url from '../Common/Constants';
import { Button } from 'react-bootstrap';


const AppointmentCalls = () => {
    const [activeState, setActiveState] = useState('Appointments');

    const maxLength = 32;
    const EllipsisText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const CallHistory = [
        { sales_person: "Nolan, Aspen, James", remark: 'Confirmed with the client that their application is being processed.', action: "View" },
        { sales_person: "Nolan, Aspen, James", remark: 'Confirmed with the client that their are', action: "View" },
        { sales_person: "Nolan, Aspen, James", remark: 'Confirmed with the client that their a..', action: "View" },
        { sales_person: "Nolan, Aspen, James", remark: 'Confirmed with the client that their a..', action: "View" },
        { sales_person: "Nolan, Aspen, James", remark: 'Confirmed with the client that their a..', action: "View" },
        { sales_person: "Nolan, Aspen, James", remark: 'Confirmed with the client that their a..', action: "View" },

    ]
    const RenderBody = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment className='dashTitle'>
                <td>{item?.sales_person}</td>
                <td className="ellipsis">
                    {EllipsisText(item.remark, maxLength)}
                </td>
                <td><span className='view-icon-box'><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>

            <div className='dashbtns'>
                {/* <div className="d_btns px-2 py-1 d-flex ">
                    <Button type="button" className='btn btn-light' variant='light' >Appointments</Button>
                    <h6 className='d-flex  align-items-center'>Calls</h6>
                    <Button  className='' variant='light' >Calls</Button>
                </div> */}
                <div className='d_btns '>
                    <Button onClick={() => setActiveState("Appointments")} variant={""} size={""}
                    className={`${activeState === "Appointments" ? "appointmentBtn " : "calls"}`} >Appointments</Button>
                    <Button onClick={() => setActiveState("Calls")} variant={""} size={""}
                    className={`${activeState === "Calls" ? "appointmentBtn" : "calls "}`} >Calls</Button>
                </div>
                <CustomTableTwo
                    title={{ text: "", size: "fs-20", textTwo: "" }}
                    columnNames={["Sales Person ", "Remark", "View"]}
                    recordData={CallHistory}
                    renderBody={RenderBody}
                />
            </div>
        </React.Fragment>
    )
}

export default AppointmentCalls