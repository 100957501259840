/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useState } from 'react'
import Calendar from 'react-calendar';

const TwoCalenderModal = () => {
    const [activeStartDate, setActiveStartDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    // // Update calendars based on new month or year
    // const updateCalendars = useCallback((newDate) => {
    //     setCurrentDate(newDate);
    // }, []);
   


    useEffect(() => {
        setCurrentMonth(activeStartDate.getMonth());
        setCurrentYear(activeStartDate.getFullYear());
    }, [activeStartDate]);

    const handleActiveStartDateChange = ({ activeStartDate }) => {
        setActiveStartDate(activeStartDate);
    };

    const handleNavigation = (date) => {
        const newMonth = date.getMonth();
        const newYear = date.getFullYear();

        if (newMonth !== currentMonth) {
            setActiveStartDate(new Date(newYear, newMonth, 1));
        } else {
            setActiveStartDate(new Date(newYear, newMonth, 1));
        }

        setCurrentMonth(newMonth);
        setCurrentYear(newYear);
    };

    const leftCalendarDate = new Date(currentYear, currentMonth, 1);
    const rightCalendarDate = new Date(currentYear, currentMonth + 1, 1);


    return (
            <div className='' style={{ display: 'flex', justifyContent: 'center', }}>
                <Calendar 
                    activeStartDate={leftCalendarDate}
                    value={leftCalendarDate}
                    onActiveStartDateChange={({ activeStartDate }) => handleNavigation(activeStartDate)}
                    prevLabel={'<'}
                    nextLabel={null}
                    prev2Label={'<<'}
                    next2Label={null}
                />
               <div className='custom-border-box'>
               <div className='custom-border'>
               </div>
               </div>
                <Calendar className=''
                    activeStartDate={rightCalendarDate}
                    value={rightCalendarDate}
                    onActiveStartDateChange={({ activeStartDate }) => handleNavigation(activeStartDate)}
                    prevLabel={null}
                    nextLabel={'>'}
                    prev2Label={null}
                    next2Label={'>>'}
                />
            </div>
    )
}

export default TwoCalenderModal