/* eslint-disable */
import React, { useState } from 'react';
import { ListGroupItem } from 'react-bootstrap';
import Icon from '../Common/Icon';
import { Link, NavLink } from 'react-router-dom';
import App_url from '../Common/Constants';

export default function TabListItem({ isActive, onMenuClick, activeSubMenu, onSubMenuClick, ...item }) {
  const [activeButton, setActiveButton] = useState(isActive);

  const handleDropdownClick = () => {
    setActiveButton(!activeButton);
    onMenuClick(); // Handle menu click in the parent component
  };

  const handleNavLinkClick = () => {
    setActiveButton(false);
    onMenuClick(); // Close the dropdown when the main NavLink is clicked
  };

  if (item?.to) {
    return (
      <NavLink to={item?.to} onClick={handleNavLinkClick}>
        <ListGroupItem className='d-align-center'>
          <Icon className={`md`} attrIcon={item?.Icon} />
          <span className='ellipse text-nowrap'>{item?.title}</span>
        </ListGroupItem>
      </NavLink>
    );
  }

  return (
    <Link className={`link-dropdown ${isActive ? "active" : ""}`}>
      <ListGroupItem className='d-align-center' onClick={handleDropdownClick}>
        <Icon className={`md`} attrIcon={item?.Icon} />
        <span className='ellipse'>{item?.title}</span>
        <Icon className={`md arrow-down-icon sm`} attrIcon={App_url.img.ArrowDown} />
      </ListGroupItem>
      {isActive && (
        <ul>
          {item?.data?.map((subItem, index) => (
            <li key={index}>
              <NavLink className={"text-nowrap"} to={subItem?.to} onClick={() => onSubMenuClick(index)}>
                {subItem?.title}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </Link>
  );
}
