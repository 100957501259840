/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import Button from '../../../Common/Button';
import App_url from '../../../Common/Constants';
import Icon from '../../../Common/Icon';
import Utils from '../../../Common/Utils';
import PrimeReactPage from '../../../Common/quill/PrimeReactPage'
import { setConfirmModalPopup } from '../../../../store/modules/modals/action';
import { PostRequestAPI } from '../../../../Api/api/PostRequest';


export default function ComposeEmail() {
    const dispatch = useDispatch();
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { userOutlookDetails, access_token } = useSelector(App_url.userDataReducers);
    const [cidImgArray, setCidImgArray] = useState([])
    // console.log("cidImgArray", cidImgArray);

    const { ModalPopup } = useSelector(App_url.modalReducers);
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [cc, setCC] = useState(false);
    const [bcc, setBCC] = useState(false);
    const [emailValue, setEmailValue] = useState([]);
    const [ccValue, setCCValue] = useState([]);
    const [bccValue, setBCCValue] = useState([]);
    const [UploadFile, setUploadFile] = useState([]);
    const [documentImageIds, setDocumentImageIds] = useState([]);


    const refs = useRef({
        refUpload: null,
        refBold: null,
        refItalic: null,
        refUnderline: null,
        refOrderedList: null,
        refUnOrderedList: null,
        refImage: null,
        refLink: null
    });

    const [formData, setFormData] = useState({
        subject: "",
        msg: "",
    })
    const [error, setError] = useState({
        subject: "",
        msg: "",
        email: "",
    })

    useEffect(() => {
        if (ModalPopup?.show === 'COMPOSE_MAIL_MODAL') {
            setEmailValue([{ label: customerDetails[0].email, value: customerDetails[0]?.email }]);
        }
        if(ModalPopup?.data?.documentImage && ModalPopup?.show === 'COMPOSE_MAIL_MODAL'){
             console.log("documentImage", ModalPopup?.data?.documentImage)   
             setUploadFile(ModalPopup?.data?.documentImage);

             const imageIds = ModalPopup?.data?.documentImage?.map(doc => doc?.document_image)
             setDocumentImageIds(imageIds)
        }
    }, [ModalPopup?.show === 'COMPOSE_MAIL_MODAL']);


    const validation = () => {
        let val = true;

        let multiEmails = [];
        if (emailValue) multiEmails = emailValue?.map(item => item.value);
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailValue?.length <= 0) {
            error.email = "Please provide email";
            val = false;
        } else {
            const invalidEmails = multiEmails.filter(email => !emailPattern.test(email));
            if (invalidEmails.length > 0) {
                error.email = "Please provide valid email addresses";
                val = false;
            }
        }
        if (formData?.subject.trim() === "") {
            error.subject = "Please provide subject";
            val = false;
            toast.error("Please provide the subject");
        }

        setError((data) => ({
            ...data,
            ...error,
        }))
        return val;
    }

    // Convert base64 string to a Blob
    function base64ToBlob(base64, mimeType) {
        // console.log("filerArray", base64);
        const byteCharacters = atob(base64.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    }


    // Convert base64 string to a File
    function base64ToFile(base64, filename) {
        const mimeType = base64.split(',')[0].match(/:(.*?);/)[1];
        const blob = base64ToBlob(base64, mimeType);
        return new File([blob], filename, { type: mimeType });
    }


    const replaceImageSrcs = (htmlString, newSrcs) => {
        let index = 0;

        // Regex to match base64 image data URLs
        return htmlString.replaceAll(/data:image\/[^;]+;base64,[^"]*/g, (match) => {
            const newSrc = newSrcs[index];
            index++;
            // return newSrc ? `cid:${newSrc}` : match;
            return `cid:${newSrc}`;

        });
    };

    const convertSrcQuotes = (htmlString) => {
        return htmlString.replaceAll(/src="([^"]*)"/g, (match, p1) => {
            return `src='${p1}' /`;
        });
    };

    const handleClose = async () => {
        if (validation()) {
            setLoader2(true);
            let InlineIds = ""

            // console.log("html===========", formData?.msg)

            const base64ImagesArray = [];
            console.log("base64ImagesArray", base64ImagesArray)
            const base64Regex = /<img\s+src="(data:image\/[^;]+;base64,[^"]+)"/g;
            let match;
            while ((match = base64Regex.exec(formData?.msg)) !== null) {
                base64ImagesArray.push(match[1]);
            }


            if (base64ImagesArray?.length > 0) {
                const filesArray = base64ImagesArray.map((item, index) => {
                    const base64 = item;
                    const extension = base64.split(';')[0].split('/')[1];
                    return base64ToFile(base64, `image${index + 1}.${extension}`);
                });

                console.log('filesArray' ,filesArray)


                try {
                    console.log("coming inside try...")
                    const uploadPromises = filesArray.map(async (file) => {
                        const formData = new FormData();
                        formData.append("file", file, file.name);
                        return PostRequestAPI(App_url.api.FILE_UPLOAD, formData, access_token, true);
                    });

                    const responses = await Promise.all(uploadPromises);
                    const successfulUploads = responses.filter(item => item?.status === 200);
                    InlineIds = successfulUploads.map(item => item.data.id);
                    // setInlineIds(inlineIds);

                } catch (error) {
                    console.error("Error during file upload:", error);
                }
            }

            if (ModalPopup?.callBackModal) {
                console.log("inside callback...");
                const emailValues = emailValue ? emailValue.map(item => item.value) : [];
                const ccValues = ccValue ? ccValue.map(item => item.value) : [];
                const bccValues = bccValue ? bccValue.map(item => item.value) : [];
                const uploadIds = UploadFile ? UploadFile.map(item => item.id) : [];
                const replacedMsg = replaceImageSrcs(formData?.msg, InlineIds);
                const finalMsg = convertSrcQuotes(replacedMsg);
                console.log("Replaced message:", replacedMsg);
                
                const formData1 = {
                    email: emailValues,
                    cc: ccValues,
                    bcc: bccValues,
                    subject: formData?.subject,
                    msg: finalMsg,
                    document: documentImageIds?.length > 0 ? documentImageIds : uploadIds,
                    inline_ids: InlineIds
                };


                await ModalPopup.callBackModal(formData1);
                CloseData();
            }

        } else {
            console.log("Validation error");
        }
    };


    const CloseData = async () => {
        if (ModalPopup?.callBackCancelModal) {
            await ModalPopup?.callBackCancelModal();
        }
        setCCValue([]);
        setBCCValue([]);
        setEmailValue([]);
        setCC(false);
        setBCC(false);
        setFormData({
            subject: "",
            msg: "",
        });
        setError({
            subject: "",
            msg: "",
            email: "",
        })
        setUploadFile([]);
        setCidImgArray([]);
        setLoader2(false);
        setDocumentImageIds([]);
        dispatch(setConfirmModalPopup())
    }

    const callRemoveCC = () => {
        setCC(false);
        setCCValue([]);
    }
    const callRemoveBCC = () => {
        setBCC(false)
        setBCCValue([]);
    }

    const onChange = (e) => {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }))
        setError((prevData) => ({
            ...prevData,
            subject: ""
        }))
    }
    const handleSetEmailValues = (selectedOptions) => {
        const emailsList = selectedOptions?.filter((item, index) => {
            const email = Utils.ValidateEmail(item?.value);
            if (email) {
                return false
            } else {
                return true
            }
        })
        // console.log("selectedOptions", selectedOptions  )
        setEmailValue(emailsList)
        setError((prevData) => ({
            ...prevData,
            email: "",
        }))
    }
    const handleCCEmailValues = (selectedOptions) => {
        const emailsList = selectedOptions?.filter((item, index) => {
            const email = Utils.ValidateEmail(item?.value);
            if (email) {
                return false
            } else {
                return true
            }
        })
        // console.log("selectedOptions", selectedOptions  )
        // setEmailValue(emailsList)
        setCCValue(emailsList)
    }
    const handleBCCEmailValues = (selectedOptions) => {
        const emailsList = selectedOptions?.filter((item, index) => {
            const email = Utils.ValidateEmail(item?.value);
            if (email) {
                return false
            } else {
                return true
            }
        })
        // console.log("selectedOptions", selectedOptions  )
        // setEmailValue(emailsList)
        setBCCValue(emailsList)
    }
    const handleQuillData = (messageData) => {
        setFormData((prevData) => ({
            ...prevData,
            msg: messageData
        }));
    };
    const handleFileSelect = (event) => {
        const files = event.target.files;
        handleUploadedFiles(files);

    };
    const handleUploadedFiles = async (files) => {
        const validFileTypes = ['csv', 'xls', 'xlsx', 'pdf', 'pptx', 'png', 'jpg', 'jpeg'];

        Array.from(files).forEach(async (file) => {
            if (file) {
                const fileName = file.name;
                const fileType = fileName.split('.').pop().toLowerCase();

                if (validFileTypes.includes(fileType)) {
                    // Create a new FormData object
                    const formData = new FormData();
                    formData.append('file', file); // Append the file to the form data
                    // console.log("formDatasssssss", [...formData]);

                    try {
                        // Send the form data using your API function
                        const response = await PostRequestAPI(App_url.api.FILE_UPLOAD, formData, access_token, true);
                        console.log("responseFileUpload", response);
                        if (response?.status === 200) {
                            setUploadFile(prevFiles => [...prevFiles, response?.data]);
                            // setUploadFileIds(preFileIds => [...preFileIds, response?.data?])
                        }
                    } catch (error) {
                        console.error("File upload failed", error);
                    }
                } else {
                    toast.error("Invalid file format");
                }
            }
        });
    };
    const handleDeleteAttachment = (file) => {
        const filterUploadFile = UploadFile.filter((item) => item?.id != file?.id);
        console.log("fileUPladed", filterUploadFile);
        setUploadFile(filterUploadFile)

        if (documentImageIds?.length > 0) {
            const updatedDocumentImageIds = documentImageIds.filter((id) => id !== file.document_image);
            setDocumentImageIds(updatedDocumentImageIds);
        }
    };
    const callIcons = (attachment) => {
        const extension = attachment?.split('.').pop();
        // console.log("attachment", extension);
        if (extension === "pdf") {
            return (
                <Icon attrIcon={App_url.img.PdfIcon2} image className='sm me-1 ms-1 d-flex-center' />
            )
        } else if (extension === "jpg" || extension === "jpeg" || extension === "png") {
            return (
                <Icon attrIcon={App_url.img.DocIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        } else if (extension === "xlsx" || extension === "csv") {
            return (
                <Icon attrIcon={App_url.img.ExcelIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        } else {
            return (
                <Icon attrIcon={App_url.img.TEXTIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        }
    }

    if (ModalPopup?.show !== "COMPOSE_MAIL_MODAL") {
        return null
    }

    return (
        <Modal show={true} centered size='lg' className='compose-mail-dialog'>
            <Modal.Header className='compose-mail-header'>
                <h6>Create mail</h6>
                <Icon image onClick={CloseData} attrIcon={App_url.img.RedCrossIcon} className="cursor-pointer" />
            </Modal.Header>
            <Modal.Body className='modal-body compose-mail-body'>
                <div className='from-name'>
                    <h6 className='title'>From <span className='user-name'>{userOutlookDetails?.account?.username}</span> (me)</h6>
                </div>
                <div className='to-name'>
                    <div className='title-search'>
                        <h6 className='title'>To</h6>
                        <CreatableSelect
                            isMulti
                            placeholder={"Enter email"}
                            // options={emailValue}
                            value={emailValue}
                            className='to-users'
                            onChange={(selectedOptions) => handleSetEmailValues(selectedOptions)}
                        />
                    </div>
                    <div className='email-cc-bcc-box'>
                        <Button onClick={() => setCC(true)} className={"ms-2 cc-btn"} size={"sm"} variant={"secondary"}>CC</Button>
                        <Button onClick={() => setBCC(true)} className={"ms-2 bcc-btn"} size={"sm"} variant={"secondary"}>BCC</Button>
                    </div>
                </div>
                {cc &&
                    <div className='to-name'>
                        <div className='title-search'>
                            <h6 className='title'>CC</h6>

                            <CreatableSelect
                                isMulti
                                placeholder={"Enter cc email"}
                                value={ccValue}
                                onChange={handleCCEmailValues}
                                className='to-users'
                            />

                        </div>
                        <div className=''>
                            <Button onClick={callRemoveCC} className={"ms-2"} size={"md"} variant={"secondary"}> <Icon image className='md mb-2 mail-delete-icon' attrIcon={App_url.img.DeleteIcon} /></Button>
                        </div>
                    </div>
                }
                {bcc &&
                    <div className='to-name'>
                        <div className='title-search'>
                            <h6 className='title'>BCC</h6>
                            {bcc &&
                                <CreatableSelect
                                    isMulti
                                    placeholder={"Enter bcc email"}
                                    value={bccValue}
                                    onChange={handleBCCEmailValues}
                                    className='to-users'
                                />
                            }
                        </div>
                        <div className=''>
                            <Button onClick={callRemoveBCC} className={"ms-2"} size={"md"} variant={"secondary"}> <Icon image className='mb-2 mail-delete-icon' attrIcon={App_url.img.DeleteIcon} /></Button>
                        </div>
                    </div>
                }
                <textarea type="text" name='subject' onChange={onChange} className='mail-input-box' rows={1} placeholder='Subject...' />
                {error?.subject && <h6 className='fs-14 compose-email-subject-error fw-4' style={{ color: "red" }}>{error?.subject}</h6>}

                <div className='prime-react-container'>
                    <PrimeReactPage handleQuillData={handleQuillData} refs={refs} />
                </div>
                {loader && <h6 className='ms-2'>Please wait while the file is uploading...</h6>}
                {UploadFile?.length > 0 && (
                    <React.Fragment>
                        <h6 className='attachments-text'>Attachments</h6>
                      <div className='email-file-preview-box'>
                      {UploadFile.map((file, index) => (
                            <div key={index} className='email-file-preview mb-2'>
                                <div className='email-file-name'>
                                    {callIcons(file?.name || file?.document_type)}
                                    <span className='email-attachment-name-ellipsis'>{file?.name || file?.document_name || ""}</span>
                                </div>
                                <div
                                    className='email-delete-icon'
                                    onClick={() => handleDeleteAttachment(file)}
                                >
                                    <Icon
                                        image
                                        attrIcon={App_url.img.DeleteIcon}
                                        className='sm ms-4 me-1 d-flex-center'
                                    />
                                </div>
                            </div>
                        ))}
                      </div>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer className='p-1 d-flex justify-content-between mt-2'>
                <div className='prime-header-icon-box'>
                    <input ref={el => refs.current.refUpload = el} type='file' onChange={handleFileSelect} id='input_file' hidden />
                    <Button onClick={() => refs?.current?.refUpload?.click()} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon className='' attrIcon={App_url.img.AttachmentIcon} /></Button>
                    <Button onClick={() => refs?.current?.refLink?.click()} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon className='' attrIcon={App_url.img.LinkIcon2} /></Button>
                    <Button onClick={() => refs?.current?.refImage?.click()} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon className='' attrIcon={App_url.img.GalleryIcon} /></Button>
                    <Button onClick={() => refs?.current?.refBold?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.BoldIcon} /></Button>
                    <Button onClick={() => refs?.current?.refItalic?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.ItalicIcon} /></Button>
                    <Button onClick={() => refs?.current?.refUnderline?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.UnderlineIcon} /></Button>
                    <Button onClick={() => refs?.current?.refOrderedList?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.OrderedListIcon} /></Button>
                    <Button onClick={() => refs?.current?.refUnOrderedList?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.UnorderedListIcon} /></Button>

                </div>
                <div className=''>
                    <Button variant={'secondary'} className={"me-2 add-document-btn"} size={"md"} onClick={() => refs?.current?.refUpload?.click()}>Add Document</Button>
                    <Button className={"me-3"} variant={"primary"} size={"md"} onClick={handleClose}>{loader2 ? "Please wait..." : "Send Email"}</Button>
                </div>
            </Modal.Footer>


        </Modal>
    )
}

