/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import Icon from '../Common/Icon';
import LazyImage from '../Common/LazyImage';
import AnchorLink from '../Common/AnchorLink';
import { Link, NavLink, } from 'react-router-dom';
import TabListItem from './TabListItem';
import { useDispatch, useSelector } from 'react-redux';
import { GetRequestCallAPI } from '../../Api/api/GetRequest';
import SidebarSite from './SidebarSite';
import App_url from '../Common/Constants';
import { setConfirmModalPopup } from '../../store/modules/modals/action';
import { setStoreCurrentUser } from '../../store/modules/users_data/action';

export default function SideBar(props) {
  const { access_token, site_code } = useSelector(App_url.userDataReducers);
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const handleMenuClick = (clickedItem) => {
    setActiveMenu(clickedItem === activeMenu ? null : clickedItem);
  };

  const handleSubMenuClick = (menuIndex, subMenuIndex) => {
    setActiveSubMenu(subMenuIndex);
  };

  const SideList = [
    {
      title: "Dashboard",
      Icon: App_url.img.DashboardIcon,
      to: App_url.link.dashboard,
    },
    ,
    {
      title: "Sales",
      Icon: App_url.img.SalesProgressIcon,
      data: [
        {
          title: "Prospects",
          Icon: App_url.img.StaffIcon,
          to: App_url.link.prospects,
        },
        {
          title: "Customers",
          Icon: App_url.img.StaffIcon,
          to: App_url.link.customers,
        },
      ]
    },
    {
      title: "Offers",
      Icon: App_url.img.OffersIcon,
      to: App_url.link.offers,
    },
    {
      title: "Calculation",
      Icon: App_url.img.CalculatorIcon,
      // to: App_url.link.calculation,
      data: [
        {
          title: "Saved Calculation",
          Icon: App_url.img.StaffIcon,
          to: App_url.link.savedCalculation,
        },
        {
          title: "New Calculation",
          Icon: App_url.img.StaffIcon,
          to: App_url.link.newCalculation,
        },
      ]
    },
    {
      title: "Documentation",
      Icon: App_url.img.DocumentIcon,
      // to: App_url.link.documentation,
      data: [
        {
          title: "Documents List",
          Icon: App_url.img.StaffIcon,
          to: App_url.link.documentationList,
        },
        {
          title: "Browse",
          Icon: App_url.img.StaffIcon,
          to: App_url.link.browseList,
        },
      ]
    },
    {
      title: "List of Services",
      Icon: App_url.img.ServicesIcon,
      to: App_url.link.services,
    },
    // {
    //   title:"FAQ’S",
    //   Icon:App_url.img.FAQ,
    //   to: App_url.link.faqs,
    // },
    // {
    //   title:"Privacy Policy",
    //   Icon:App_url.img.PrivacyPolicy,
    //   to: App_url.link.privacy_policy,
    // },
    // {
    //   title:"Terms & Conditions",
    //   Icon:App_url.img.TermsConditions,
    //   to: App_url.link.terms_conditions,
    // },
  ];

  const callLoginOutConfirm = (item) => {
    dispatch(setConfirmModalPopup({
      title: "Log Out",
      description: `Are you sure want to log out?`,
      data: {},
      show: "CONFIRM_MODAL",
      callBackModal: () => LogoutUser(),
      buttonSuccess: "Yes",
      buttonCancel: "Cancel",
    }));
  }
  const LogoutUser = async () => {
    await GetRequestCallAPI(App_url.api.USER_LOG_OUT, access_token);
    dispatch(setStoreCurrentUser())
    window.location.replace(`${process.env.REACT_APP_API_DOMAIN_URL}/${App_url.api.USER_DEL_LOGIN}/${window.location.hostname}`);
  }
  // const siteCode = useMemo(()=>{
  //   return OrgList?.find((item, index)=>item.site_code === site_code);
  // },[site_code]);


  return (
    <div className='sidebar-panel'>
      <SidebarSite />
      <aside className='main-sidebar '>
        <div className='logo d-flex justify-content-between'>
          <AnchorLink to={App_url?.link?.home}>
            {/* <LazyImage src={App_url.image.default_logo} /> */}
            <div className='logo_title'>
              <h6 className='side-bar-title'>SALES DETAILS</h6>
            </div>
          </AnchorLink>
        </div>
        <div id='sidebar_menu' className='metis_menu'>
          <div className='side-scrolled'>
            <ListGroup>
              {SideList?.map((item, index) => (
                <React.Fragment key={index}>
               <TabListItem
                {...item}
                isActive={activeMenu === index}
                onMenuClick={() => handleMenuClick(index)}
                activeSubMenu={activeSubMenu}
                onSubMenuClick={(subMenuIndex) => handleSubMenuClick(index, subMenuIndex)}
              />
                </React.Fragment>
              ))}
              {/* {SideList.map((item, index) => (
                <TabListItem
                  key={index}
                  {...item}
                  isSubMenuActive={activeSubMenu !== null && activeMenu === item}
                  onMenuClick={() => handleMenuClick(index)}
                  activeSubMenu={activeSubMenu}
                  onSubMenuClick={() => handleSubMenuClick(index)}
                />
              ))} */}

            </ListGroup>

          </div>
        </div>

      </aside>
    </div>
  )
}
