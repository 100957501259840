/* eslint-disable */
import React, { useEffect, useState } from 'react'
import App_url from '../../../Common/Constants'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Dropdown } from 'react-bootstrap';
import Icon from '../../../Common/Icon';
import InputGroup from '../../../Common/InputGroup';
import Utils from '../../../Common/Utils';

export default function ViewAppointmentStatus(props) {
    const [startDate, setStartDate] = useState(()=>{
        const utcDate = new Date(props?.start_time);
        return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
    });
    const [endDate, setEndDate] = useState(()=>{
        const utcDate = new Date(props?.end_time);
        return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
    });
    const [startTime, setStartTime] = useState(()=>{
        const utcDate = new Date(props?.start_time);
        const hours = utcDate.getUTCHours();
        const minutes = utcDate.getUTCMinutes();
        const seconds = utcDate.getUTCSeconds();
    
        // Create a Date object representing the time (hours, minutes, seconds) on a neutral date
        const timeOnly = new Date(1970, 0, 1, hours, minutes, seconds);
    
        return timeOnly;
    });
    const [endTime, setEndTime] = useState(()=>{
        const utcDate = new Date(props?.end_time);
        const hours = utcDate.getUTCHours();
        const minutes = utcDate.getUTCMinutes();
        const seconds = utcDate.getUTCSeconds();
    
        // Create a Date object representing the time (hours, minutes, seconds) on a neutral date
        const timeOnly = new Date(1970, 0, 1, hours, minutes, seconds);
    
        return timeOnly;
    });
    const [isAllDay, setIsAllDay] = useState(false);
    const [travelTime, setTravelTime] = useState('None');

    const TravelTimeOptions = [
        { label: "None", value: "None" },
        { label: "5 minutes", value: "5 minutes" },
        { label: "15 minutes ", value: "15 minutes " },
        { label: "30 minutes", value: "30 minutes" },
        { label: "1 hour", value: "1 hour" },
        { label: "1 hour , 30 minutes", value: "1 hour , 30 minutes" },
        { label: "2 hours", value: "2 hours" },
    ]

    const handleTravelSelect = (eventKey) => {
        setTravelTime(eventKey);
    };

    const [formData, setFormData] = useState({
        start_time:startDate,
        end_time:endDate,
        is_all_day:false,
    })

    useEffect(()=>{
        if(props?.handleReScheduleData){
            props?.handleReScheduleData(formData);
        }
    },[formData])


    const handleSelectedDateTime = (dateTime, type) =>{
        if(type === "startDate"){
          if(formData?.is_all_day){
            setStartDate(dateTime);
            setEndDate(dateTime);
            setFormData((data)=>({
                ...data,
                start_time: Utils.formatToISOLocal(dateTime),
                end_time: Utils.formatToISOLocal(dateTime),
            }))
          }else{
            setStartDate(dateTime);
            setEndDate(dateTime);
            const endTime = new Date(dateTime);
            endTime.setTime(endTime.getTime() + 30 * 60000); 
            setEndTime(endTime);
            setFormData((data)=>({
                ...data,
                start_time: Utils.formatToISOLocal(dateTime),
                end_time: Utils.formatToISOLocal(endTime),
            }))
          }
        }
        if (type === "startTime") {
            setStartTime(dateTime);
            const endTime = new Date(dateTime);
            endTime.setTime(endTime.getTime() + 30 * 60000); 
            setEndTime(endTime);
            const start_date = Utils.formatOnlyDate(startDate);
            const start_time = Utils.formatOnlyTime(dateTime);
            const end_date = Utils.formatOnlyDate(endDate);
            const end_time = Utils?.formatOnlyTime(endTime);
            setFormData((data) => ({
                ...data,
                start_time: Utils.concatDateTime(start_date, start_time),  
                end_time: Utils.concatDateTime(end_date, end_time),
            }));
        }
        if(type === "endDate"){
            const startTime = formData?.start_time;
            const dt = Utils.formatToISOLocal(dateTime);
           if(dt < startTime){
                toast.error("End date can't be less than Start date");
           }else{
              if(formData?.is_all_day){
                setEndDate(dateTime);
                setFormData((data)=>({
                    ...data,
                    end_time: Utils.formatToISOLocal(dateTime),
                   
                }))

              }else{
                setEndDate(dateTime);
                const end_date = Utils.formatOnlyDate(dateTime);
                const end_time = Utils.extractTime(formData?.end_time);
                setFormData((data)=>({
                    ...data,
                    end_time: Utils.concatDateTime(end_date, end_time),

                }))
              }
           }
        }
        if(type === "endTime"){
            setEndTime(dateTime);
            const end_date = Utils.formatOnlyDate(endDate);
            const end_time = Utils.formatOnlyTime(dateTime);
            setFormData((data)=>({
                ...data,
                end_time: Utils.concatDateTime(end_date, end_time),
            }))
        }
        if(type === "repeatEndDate"){
            setRangeEndDate(dateTime);
            const startTime = formData?.start_time;
            const dt = Utils.formatToISOLocal(dateTime);
           if(dt < startTime){
                toast.error("End date can't be less than Start date");
           }else{
               setFormData((data)=>({
                   ...data,
                   recurrence:{
                       ...data?.recurrence,
                       range:{
                           ...data?.recurrence?.range,
                           startDate: Utils.formatOnlyDate(startDate),
                           endDate: Utils.formatOnlyDate(dateTime),
                        }
                   }
               }))
           }
        }
        if(type === "repeatEndTime"){
            setRangeEndDateTime(dateTime);
            const end_time = Utils?.formatOnlyTime(dateTime);
            const end_date = Utils?.formatOnlyDate(rangeEndDate)
            setFormData((data)=>({
                ...data,
                recurrence:{
                    ...data?.recurrence,
                    range:{
                        ...data?.recurrence?.range,
                        endDate:Utils.concatDateTime(end_date, end_time),
                     }
                }
            }))
        }
    }

    const handleIsAllDay = (e) => {
        if (e.target.checked) {
            setFormData((data) => ({
                ...data,
                is_all_day:true,
                start_time: Utils.formatOnlyDate(startDate) + "T00:00:00Z",
                end_time: Utils.formatOnlyDate(endDate) + "T00:00:00",
            }))
        } else {
            setStartTime(new Date());
            setEndTime(new Date());
            setFormData((data) => ({
                ...data,
                is_all_day:false,
                start_time: Utils.formatToISOLocal(new Date()),
                end_time: Utils.formatToISOLocal(new Date()),
            }))
        }

    } 

    const StatusContent = () =>{
        if(props?.status === "Rescheduled"){
            return(
                <div className="new-appointment-modal-dialog">
            <div className="new-appointment-modal">
                <div className='date-time-info-box'>
                    <div className='date-time-first'>
                        <div className='date-time-icon'><Icon image attrIcon={App_url.img.CalendarTwoIcon} /></div>
                        <div className='date-time-start-box'>
                            <h6 className='title'>Starts</h6>
                            <div className='custom-date-box'>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => handleSelectedDateTime(date, "startDate")}
                                    dateFormat="dd MMM yyyy"
                                    placeholderText={"Start Date"}
                                    name={"start-date"}
                                    value={new Date()}
                                    className={"start-date "}
                                    wrapperClassName='custom-date-time-wrapper'
                                    minDate={new Date()}
                                />
                            </div>
                            {!formData?.is_all_day && <div className='custom-time-box'>
                                <DatePicker
                                    selected={startTime}
                                    onChange={(time) => handleSelectedDateTime(time, "startTime")}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="Select Time"
                                    className="custom-time-picker"
                                    wrapperClassName="custom-time-picker-wrapper"
                                />
                            </div>}
                            <h6 className='ms-2'>All-day</h6>
                            <InputGroup type="switch" name={"status"} checked={formData?.is_all_day} onCheck={(e) => handleIsAllDay(e)} />

                        </div>
                    </div>
                    <div className='date-time-second'>
                        <div className='date-time-icon'><Icon image attrIcon={App_url.img.CalendarTwoIcon} /></div>
                        <div className='date-time-end-box'>
                            <h6 className='title'>Ends</h6>
                            <div className='custom-date-box'>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => handleSelectedDateTime(date, "endDate")}
                                    dateFormat="dd MMM yyyy"
                                    placeholderText={"Start Date"}
                                    name={"start-date"}
                                    value={new Date()}
                                    className={"start-date "}
                                    wrapperClassName='custom-date-time-wrapper'
                                    minDate={new Date()}
                                />
                            </div>
                            {!formData?.is_all_day && <div className='custom-time-box'>
                                <DatePicker
                                    selected={endTime}
                                    onChange={(time) => handleSelectedDateTime(time, "endTime")}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="Select Time"
                                    className="custom-time-picker"
                                    wrapperClassName="custom-time-picker-wrapper"
                                />
                            </div>}

                            <h6 className='ms-2'>Travel Time</h6>
                            <div className='date-time-custom-dropdown'>
                                <Dropdown onSelect={handleTravelSelect}>
                                    <Dropdown.Toggle variant="light" className='custom-travel-time-dropdown-toggle'>
                                        <span>{travelTime}</span>
                                        <Icon className={"travel-time-arrow-icon"} image attrIcon={App_url.img.UpDownIcon} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {TravelTimeOptions && TravelTimeOptions.map(option => (
                                            <Dropdown.Item key={option?.value} eventKey={option?.value} style={{ paddingRight: '8px' }}>
                                                <div className='check_menu' style={{ fontSize: '13px' }}>
                                                    {travelTime === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                    <span style={{ marginLeft: travelTime === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            )
        }
        if(props?.status === "Cancelled"){
            return(
                <InputGroup
                    placeholder={"Enter remark note"}
                    formClassName={"status-textarea"}
                    name={"cancel_comment"}
                    as={"textarea"}
                    rows={4}
                    value={props?.formData?.cancel_comment}
                    onChange={props?.onChange}
                />   
            )
        }
        if(props?.status === "Completed"){
            return(
                <InputGroup
                    placeholder={"Enter remark note"}
                    formClassName={"status-textarea"}
                    name={"completed_comment"}
                    as={"textarea"}
                    rows={4}
                    value={props?.formData?.completed_comment}
                    onChange={props?.onChange}
                />   
            )
        }
    }


    return (
        StatusContent()
    )
}
