/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import CustomTable from '../../Common/CustomTable'
import { useNavigate } from 'react-router-dom'
import App_url from '../../Common/Constants';
import { WebSocketContext } from '../../../WSContext';
import { useSelector } from 'react-redux';
import wsSendRequest from '../../../socketIO/wsSendRequest';
import { toast } from 'react-toastify';


export default function ProspectsPage(props) {
  const navigate = useNavigate();
  const ws = useContext(WebSocketContext);
  const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
  const {customersList} = useSelector(App_url.customerReducers);
  // console.log("customersList", customersList)

  const [filter, setFilter] = useState({
    status : true,
    search : "",
    user_status:""
})


const callCustomersListApi = (page) =>{
  const param = {
    transmit: "broadcast",
    url: "customer_list",
    request: {
      status: true,
      limit: "10",
      page_no: page,
      pincode_search: "",
      city_search: "",
      customer_type: [""],
      order_by: "-updated_at",
      industry_search: ""
    }
  };
  wsSendRequest(ws, param, device_id);
};

useEffect(()=>{
  if(ws){
    callCustomersListApi(1);
  }
},[ws, filter]);

const statusOptionList = [
  {label: "Not Contacted", value: "Not Contacted"},
  { label: "Not Interested", value: "Not Interested" },
  { label: "Re-Schedule Call", value: "Re-Schedule Call" },
  { label: "Share Brochure", value: "Share Brochure" },
  { label: "Schedule Appointment", value: "Schedule Appointment" },
]




  const callBack = (e, state) =>{
    if(state === "AddButton"){
      navigate(App_url.link.ProspectsAdd);
    }
  }

  const handleDetails = (customerId) =>{
    navigate(`${App_url.link.prospectsDetails}/${customerId}`)
  } 

  const RenderBody = (item) => {
    return (
            <React.Fragment>
               <td onClick={()=>handleDetails(item?.id)}>{item?.id}</td>
               <td onClick={()=>handleDetails(item?.id)}>{item?.company_name}</td>
               <td onClick={()=>handleDetails(item?.id)}>{item?.customer_name}</td>
               <td onClick={()=>handleDetails(item?.id)}>{item?.last_name}</td>
               <td onClick={()=>handleDetails(item?.id)}>{item?.email}</td>
               <td onClick={()=>handleDetails(item?.id)}>{item?.company_mobile}</td>
               <td onClick={()=>handleDetails(item?.id)}>{item?.company_address}</td>
               <td onClick={()=>handleDetails(item?.id)}>{item?.status}</td>
            </React.Fragment>
    )
}
  return (
    <ContentLayout title={"Prospects Page"}>
      <div className="row m-0">
        <div className="col-lg-12 mt-2">
        <CustomTable
        title={"Prospect List"}
        AddButton={{ title:"+ Add", variant:"outline-primary", size:"md"}}
        AddButton2={{ title:"Import", variant:"secondary", size:"md"}}
        columnNames={["Customer ID", "Company Name", "First Name", "Last Name", "Email", "Mobile No.", "Address", "Status"]}
        recordData={customersList?.data}
        renderBody={RenderBody}
        callBack={callBack}
        setFilter={setFilter}
        filter={filter}
        search_placeholder={"Search By Name,City,Industry & Postcode"}
        typePlaceHolder={"Select Status"}
        pagination={customersList?.pagination}
        onPagination={(page) => callCustomersListApi(page)}
        typeOption={statusOptionList}
        
        // scrollbar
        // style={{height: "calc(100vh - 217px)"}}
       
      />
        </div>
      </div>
    
    </ContentLayout>   
  )
}
