/* eslint-disable */
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import DashboardLayout from '../components/layout/DashboardLayout'
import DashboardPage from '../components/Dashboard/DashboardPage'
import OffersPage from '../components/Offers/OffersPage'
import DocumentationPage from '../components/Documentation/DocumentationPage'
import ServicesPage from '../components/Services/ServicesPage'
import ProspectsPage from '../components/Sales/Prospects/ProspectsPage'
import ProspectsDetails from '../components/Sales/Prospects/ProspectsDetails'
import CustomersPage from '../components/Sales/Customers/CustomersPage'
import ProspectsAddEdit from '../components/Sales/Prospects/ProspectsAddEdit'
import Login from '../components/Login'
import { useSelector } from 'react-redux'
import App_url from '../components/Common/Constants'
import TodayPage from '../components/Sales/Prospects/TodayPage'
import OpenCalculation from '../components/Calculation/OpenCalculation'
import NewCalculation from '../components/Calculation/NewCalculation'
import AddNewCalculation from '../components/Calculation/AddNewCalculation'
import SelectCategory from '../components/Calculation/SelectCategory'
import CalculationOverview from '../components/Calculation/CalculationOverview'
import FullCleaningCal from '../components/Calculation/FullCleaning/FullCleaningCal'
import IntervalCleaning from '../components/Calculation/IntervalCleaning/IntervalCleaning'
import BrowseListPage from '../components/Documentation/BrowseListPage'

export default function MainRouter() {

  const { access_token } = useSelector(App_url.userDataReducers);

  return (
    <React.Fragment>
      <Routes>
        {access_token ? (
          //  <Route path={App_url.link.home}  element={access_token === "" ? <Login/> :<DashboardLayout/>} >
          <React.Fragment>
            <Route path='*' element={<Navigate to={App_url.link.dashboard} />} />
            <Route path={`${App_url.link.home}`} element={<DashboardLayout />}>
              <Route index element={<Navigate to={App_url.link.dashboard} />} />
              <Route path={`${App_url.link.dashboard}`} element={<DashboardPage />} />
              <Route path={`${App_url.link.prospects}`} element={<ProspectsPage />}></Route>
              <Route path={`${App_url.link.customers}`} element={<CustomersPage />}></Route>
              <Route path={`${App_url.link.offers}`} element={<OffersPage />}></Route>
              <Route path={`${App_url.link.savedCalculation}`} element={<OpenCalculation />}></Route>
              <Route path={`${App_url.link.savedCalculationAdd}`} element={<AddNewCalculation />}></Route>
              <Route path={`${App_url.link.savedCalculationCategory}`} element={<SelectCategory />}></Route>
              <Route path={`${App_url.link.savedCalculationFullCleaning}`} element={<FullCleaningCal/>}></Route>
              <Route path={`${App_url.link.savedCalculationIntervalCleaning}`} element={<IntervalCleaning/>}></Route>
              <Route path={`${App_url.link.savedCalculationCategoryOverview}`} element={<CalculationOverview/>}></Route>
              <Route path={`${App_url.link.newCalculation}`} element={<NewCalculation />}></Route>
              <Route path={`${App_url.link.documentationList}`} element={<DocumentationPage />}></Route>
              <Route path={`${App_url.link.browseList}`} element={<BrowseListPage />}></Route>
              <Route path={`${App_url.link.services}`} element={<ServicesPage />}></Route>
              <Route path={`${App_url.link.prospectsDetails}/:id`} element={<ProspectsDetails />}></Route>
              <Route path={`${App_url.link.ProspectsAdd}`} element={<ProspectsAddEdit />}></Route>
              <Route path={`${App_url.link.ProspectsEdit}/:id`} element={<ProspectsAddEdit />}></Route>
              <Route path={`${App_url.link.prospectsTodayDetails}`} element={<TodayPage />}></Route>
            </Route>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Route path={"*"} element={<Navigate to={App_url.link.login}/>}></Route> */}
            <Route path={`${App_url.link.login}`} element={<Login />}></Route>
          </React.Fragment>
        )}
      </Routes>
    </React.Fragment>
  )
}
