/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Form } from 'react-bootstrap';
import AnchorLink from '../../Common/AnchorLink';
import App_url from '../../Common/Constants';
import Icon from '../../Common/Icon';
import CustomTableTwo from '../../Common/CustomTableTwo';
import { useDispatch, useSelector } from 'react-redux';
import TwoCalenderModal from './Common/TwoCalenderModal';
import { setNewAppointmentModal, setScheduleCallModal, setShowModalPopup, setStoreAppointmentViewModal, setStoreCallScheduleSuccessfulModal, setStoreDiscardChangesModal, setStoreSearchCompanyModal, setStoreViewRemarkModal, setStoreViewScheduledCallModal } from '../../../store/modules/modals/action';
import Button from '../../Common/Button';
import wsSendRequest from '../../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../../WSContext';
import Utils from '../../Common/Utils';

export default function TodayPage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const ws = useContext(WebSocketContext);
  const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers)
  const { appointmentList, scheduleList } = useSelector(App_url.customerReducers);
  const { ModalPopup, ModalPopupTwo, ModalPopupThree } = useSelector(App_url.modalReducers);

  const [activeState, setActiveState] = useState('TODO');
  // const { id } = useParams();
  const callAppointmentList = () => {
    const param = {
      transmit: "broadcast",
      url: "appointment_list",
      request: {
        status: "",
        year_filter: "",
        limit: "500",
        page_no: "1",
        search: "",
        order_by: "-updated_at",
        // customer_id: "",
      },
    };
    wsSendRequest(ws, param, device_id)
  }
  const callScheduleList = () => {
    const param = {
      transmit: "broadcast",
      url: "schedule_list",
      request: {
        limit: "500",
        page_no: "1",
        search: "",
        order_by: "-updated_at",
        // customer_id: "",
        form_date: "",
        form_date: "",
        type: "",
      },
    };
    wsSendRequest(ws, param, device_id)
  }
  useEffect(() => {
    callAppointmentList();
    callScheduleList();

  }, [ws])
  const AppointmentList = [
    { dateTime: "28 Feb 2024, 10:10 AM", customerId: "Nolan, Aspen", company: "Confirmed with the client" },
    { dateTime: "28 Feb 2024, 10:10 AM", customerId: "Nolan, Aspen", company: "Confirmed with the client" },
    { dateTime: "28 Feb 2024, 10:10 AM", customerId: "Nolan, Aspen", company: "Confirmed with the client" },
  ];
  const CallList = [
    { dateTime: "28 Feb 2024, 10:10 AM", Remark: "Client requested to reschedule the appointm.." },
    { dateTime: "28 Feb 2024, 10:10 AM", Remark: "Client requested to reschedule the appointm.." },
    { dateTime: "28 Feb 2024, 10:10 AM", Remark: "Client requested to reschedule the appointm.." },
  ];


  const callBack = (e, state, buttonName) => {
    if (state === "AddButton" && buttonName === "Personal&MeetingEvent") {
      // console.log("add...", buttonName)
      dispatch(setNewAppointmentModal({
        show: "NEW_APPOINTMENT_MODAL",
        data: { event: "personal_event", AptTitle: "Personal Event" }
      }));
    }
    if (state === "AddButton2" && buttonName === "Personal&MeetingEvent") {
      dispatch(setNewAppointmentModal({
        show: "NEW_APPOINTMENT_MODAL",
        data: { event: "meeting_event", AptTitle: "Meeting Event" }
      }));
    }
    if (state === "AddButton" && buttonName === "scheduleCall") {
      // console.log("add...", buttonName)
      dispatch(setStoreSearchCompanyModal({
        show: "SEARCH_COMPANY_MODAL",
        callBackModal: (item) => handleSearchCompanyCallBack(item)
      }));
    }
  }
  const handleView = (item, state) => {
    if (state === "appointmentView") {
      dispatch(setStoreAppointmentViewModal({
        show: "APPOINTMENT_VIEW_MODAL",
        data: { item: item },
      }))
    }
    if (state === "callView") {
      dispatch(setStoreViewScheduledCallModal({
        show: "VIEW_SCHEDULED_CALL_MODAL",
        data: { item: item },
        callBackModal: (scheduleCallItem, state) => handleScheduledCallCallBack(scheduleCallItem, state),
      }))
    }

  }

  const getPersonalEventList = () =>{
    const today = Utils.formatToISOLocal(new Date()).split('T')[0];
    // return appointmentList?.data?.filter(record => Utils.extractDate(record.start_time) === today && record.customer_id === null);
    return appointmentList?.data?.filter(record => record.customer_id === null);
  }
  
  const getScheduleCallList = () =>{
    const today = Utils.formatToISOLocal(new Date()).split('T')[0];
    // return scheduleList?.data?.filter(record => Utils.extractDate(record.schedule_date) === today && record.customer_id === null);
    return scheduleList?.data?.filter(record => record.customer_id);

  }


  const handleSearchCompanyCallBack = (item) => {
    const customerId = item?.id
    dispatch(setScheduleCallModal({
      show: "SCHEDULE_CALL_MODAL",
      data: { customerId: customerId },
      callBackModal: (scheduleData) => callScheduleCall(scheduleData, false, false, customerId),
    }));
  }

  const handleScheduledCallCallBack = (state, scheduleCallItem) => {
    console.log("scheduleCallItem",scheduleCallItem)
    if (state === "deleteScheduledCall") {
      dispatch(setStoreViewRemarkModal())
      dispatch(setStoreDiscardChangesModal({
        show: "DISCARD_CHANGES_MODAL",
        data: { item: scheduleCallItem, description: "Are you sure you want to delete this scheduled call ?" },
        callBackModal: (item) => callScheduledCallDelete(item),
      }))
    }
    if (state === "editScheduledCall") {
      dispatch(setScheduleCallModal({
        show: "SCHEDULE_CALL_MODAL",
        data: { item: scheduleCallItem },
        callBackModal: (scheduleData, item) => callScheduleCall(scheduleData, item, "schedule_edit", scheduleCallItem?.customer_id),
      }))
    }
  }

  const callScheduleCall = (scheduleData, item, state, customerId) => {
    console.log("scheduleData", scheduleData)
    const payload = {
      ...scheduleData,
      customer_id: customerId
    };
    if (state === 'schedule_edit') {
      payload.schedule_id = item?.id;
    }
    console.log("payload", payload);

    const param = {
      transmit: "broadcast",
      url: state === "schedule_edit" ? "schedule_edit" : "schedule_add",
      request: payload,
    };
    wsSendRequest(ws, param, device_id);
  }

  const callScheduledCallDelete = (item) => {
    const param = {
      transmit: "broadcast",
      url: "schedule_delete",
      request: { schedule_id: item?.id },
      toast: true,
    };
    wsSendRequest(ws, param, device_id);
  }

  useEffect(() => {
    if (ws) {
      if (websocketReqRes?.url === "schedule_add" ||
        websocketReqRes?.url === "schedule_edit" && websocketReqRes?.status === 200) {
        callScheduleList();
        dispatch(setStoreCallScheduleSuccessfulModal({
          show: "CALL_SCHEDULED_SUCCESSFUL_MODAL",
          data: { item: websocketReqRes?.response, message:`${websocketReqRes?.url === "schedule_add" ?"Call Scheduled Successfully":"Call Rescheduled Successfully"}`}
        }))
      }
      if (websocketReqRes?.url === "schedule_delete" && websocketReqRes?.status === 200) {
        callScheduleList();
      }
    }

  }, [ws, websocketReqRes?.url]);

  
  const RenderBody = (item) => {
    return (
      <React.Fragment>
            <>
              {/* <td>{Utils.formatDateTime2(item?.start_time)}</td> */}
              <td>{Utils.formatDateTime2(item?.start_time)}</td>
              {/* <td>{item?.customer_name}</td> */}
              <td>{item?.subject}</td>
              <td><span onClick={() => handleView(item, "appointmentView")} className='view-icon-box'><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
            </>

      </React.Fragment>
    )
  }
  const RenderBody2 = (item) => {
    return (
      <React.Fragment>
            <>
              <td>{Utils.formatDateTime(item?.schedule_date, item?.schedule_time)}</td>
              <td>{item?.remark}</td>

        <td><span onClick={() => handleView(item, "callView")} className='view-icon-box'><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
             </>

      </React.Fragment>
    )
  }

  return (
    <ContentLayout title={"Prospects Details Page"}>
      <div className=' '>
        <div className="row m-0 mb-4">

          <div>
            <Breadcrumb className="breadcrumb-main">
              <Breadcrumb.Item>
                <AnchorLink onClick={() => navigate(-1)} className={" d-flex-center"}>
                  <Icon attrIcon={App_url.img.LeftArrowIcon} className={'sm me-1'} />
                  <div>Back</div>
                  <div className='showdate'>
                    <h6 className=''>28 March 2024</h6>
                    <Button variant={"secondary "} size={'sm'} className='date-btn'>12 Appointments  |  5 Call</Button>
                  </div>
                </AnchorLink>
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className='today-page-container'>
              {/* <Calendar value={date} />
              <Calendar
                value={new Date(date.getFullYear(), date.getMonth() + 1)}
              /> */}
              <TwoCalenderModal />
            </div>
          </div>

          <div className="col-lg-6">
            <CustomTableTwo
              title={{ text: "Scheduled Appointment" }}
              AddButton={{ title: "+ Personal Event", variant: "outline-primary", size: "sm" }}
              AddButton2={{ title: "+ Meeting Event", variant: "outline-primary", size: "sm" }}
              columnNames={['Date & Time',  "Remark", "View"]}
              // recordData={appointmentList?.data}
              recordData={getPersonalEventList()}
              renderBody={RenderBody}
              callBack={(e, state) => callBack(e, state, "Personal&MeetingEvent")}
              search_placeholder={"Search By Name,City,Industry & Postcode"}
              typePlaceHolder={"Select Status"}
              scrollbar={appointmentList?.data?.length > 3 && true}
              style={{ height: "182px" }}
            />
          </div>
          <div className="col-lg-6">
            <CustomTableTwo
              title={{ text: "Scheduled Call" }}
              AddButton={{ title: "Schedule Call", variant: "outline-primary", size: "sm" }}
              columnNames={["Date & Time", "Remark", "View"]}
              recordData={getScheduleCallList()}
              renderBody={RenderBody2}
              callBack={(e, state) => callBack(e, state, "scheduleCall")}
              search_placeholder={"Search By Name,City,Industry & Postcode"}
              typePlaceHolder={"Select Status"}
              scrollbar={scheduleList?.data?.length > 3 && true}
              style={{ height: "182px" }}
            />
          </div>

        </div>
      </div>
    </ContentLayout>
  )
}
