import React from 'react'
import Button from '../Common/Button'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import { Modal } from 'react-bootstrap'
import InputGroup from '../Common/InputGroup'

const AdditionalCostModal = ({ isModalOpen, handleClose }) => {

    return (
        <div>
            <Modal show={isModalOpen} onHide={handleClose} backdrop="static" size='' className='addcost' centered>
                <Modal.Body className=''>
                    <div className='items'>
                        <h6 className=''>Additional Cost</h6>
                        <div>
                            <Button variant="primary" className='' size={'sm'}> Save</Button>
                            <Icon onClick={handleClose} className={"ms-1 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                    <div className='costs row'>
                        <h6>Scaffold Cost</h6>
                        <div className='col'>
                            <InputGroup
                                label={"Select Service type"}
                                placeholder={"Enter"}
                                name={"status"}
                                // value={formData?.status}
                                // error={error?.status}
                                // onChange={callStatusUpdateApi}
                                // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                type="text"
                                rightLabel={'€'}
                            // option={statusOptionList}
                            />
                        </div>
                        <div className='col'>
                            <InputGroup
                                label={"Select Service type"}
                                placeholder={"Enter"}
                                name={"status"}
                                // value={formData?.status}
                                // error={error?.status}
                                // onChange={callStatusUpdateApi}
                                // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                type="text"
                            // option={statusOptionList}
                            />
                        </div>
                    </div>
                    <div className='costs row mt-3'>
                        <h6>Material Cost</h6>
                        <div className='col'>
                            <InputGroup
                                label={"Select Service type"}
                                placeholder={"Enter"}
                                name={"status"}
                                // value={formData?.status}
                                // error={error?.status}
                                // onChange={callStatusUpdateApi}
                                // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                type="text"
                                rightLabel={'€'}
                            // option={statusOptionList}
                            />
                        </div>
                        <div className='col'>
                            <InputGroup
                                label={"Select Service type"}
                                placeholder={"Enter"}
                                name={"status"}
                                // value={formData?.status}
                                // error={error?.status}
                                // onChange={callStatusUpdateApi}
                                // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                type="text"
                            // option={statusOptionList}
                            />
                        </div>
                    </div>
                    <div className='costs row mt-3'>
                        <h6>Miscellaneous Cost</h6>
                        <div className='col'>
                            <InputGroup
                                label={"Select Service type"}
                                placeholder={"Enter"}
                                name={"status"}
                                // value={formData?.status}
                                // error={error?.status}
                                // onChange={callStatusUpdateApi}
                                // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                type="text"
                                rightLabel={'€'}
                            // option={statusOptionList}
                            />
                        </div>
                        <div className='col'>
                            <InputGroup
                                label={"Select Service type"}
                                placeholder={"Enter"}
                                name={"status"}
                                // value={formData?.status}
                                // error={error?.status}
                                // onChange={callStatusUpdateApi}
                                // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                type="text"
                            // option={statusOptionList}
                            />
                        </div>
                    </div>


                </Modal.Body>

            </Modal>
        </div>
    )
}

export default AdditionalCostModal