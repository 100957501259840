/* eslint-disable */
import React, { useState } from "react";
import { Editor } from "primereact/editor";

export default function PrimeReactPage({ handleQuillData, refs}) {
    const [text, setText] = useState('');
    

    const renderHeader = () => {
        return (
            <span className="ql-formats">
            <button ref={el => refs.current.refBold = el} className="ql-bold" aria-label="Bold"></button>
            <button ref={el => refs.current.refItalic = el} className="ql-italic" aria-label="Italic"></button>
            <button ref={el => refs.current.refUnderline = el} className="ql-underline" aria-label="Underline"></button>
            <button ref={el => refs.current.refOrderedList = el} className="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button ref={el => refs.current.refUnOrderedList = el} className="ql-list" value="bullet" aria-label="Unordered List"></button>
            <button ref={el => refs.current.refImage = el} className="ql-image" aria-label="Image"></button>
            <button ref={el => refs.current.refLink = el} className="ql-link" aria-label="Link"></button>
        </span>
        );
    };

    const header = renderHeader();
 
    let retainId = "0"
    const handleTextChange = (e) => {
        // console.log("e", e)
        setText(e.htmlValue);
        handleQuillData(e.htmlValue);
    };
    return (
        <div className="card mb-3">
            <Editor placeholder="Type here..."   value={text} onTextChange={handleTextChange}  headerTemplate={header} style={{ height: '120px' }} />
        </div>
    )
}