/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import CustomTableTwo from '../../Common/CustomTableTwo'
import Button from '../../Common/Button';
import Icon from '../../Common/Icon';
import App_url from '../../Common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setShareDocumentModal, setViewDocumentModal } from '../../../store/modules/modals/action';
import wsSendRequest from '../../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../../WSContext';
import Utils from '../../Common/Utils';

const DocumentList = () => {
    const dispatch = useDispatch();
    const ws = useContext(WebSocketContext);

    const { device_id } = useSelector(App_url.userDataReducers);
    const {ModalPopupTwo} = useSelector(App_url.modalReducers);
    const { documentList, customerDetails } = useSelector(App_url.customerReducers);
    // console.log("documentList", documentList);



    const callDocumentsListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "document_list",
            request: {
                document_type: "",
                file_type: "",
                page_no: page,
                limit: "20",
                customer_id: ModalPopupTwo?.show === "SHARE_DOCUMENT_MODAL" ? "":customerDetails[0]?.id,
                // customer_id: "",

            }
        };
        wsSendRequest(ws, param, device_id);
    };

    useEffect(() => {
        if (ws) {
            callDocumentsListApi(1);
        }
    }, [ws, ModalPopupTwo?.show === "SHARE_DOCUMENT_MODAL"]);
   
    const handleView = async (item) => {
        dispatch(setViewDocumentModal({
            show: "VIEW_DOCUMENT_MODAL",
            data:{item:item},
        }))
    }



    const iconMapping = {
        pdf: App_url.img.PdfIcon2,
        excel: App_url.img.ExcelIcon,
        word: App_url.img.WordIcon,
        png	: App_url.img.GalleryIcon2,
        jpg	: App_url.img.GalleryIcon2,
        jpeg: App_url.img.GalleryIcon2,
        svg	: App_url.img.GalleryIcon2,
        MOV: App_url.img.TEXTIcon,
        rtf: App_url.img.TEXTIcon,
        pptx: App_url.img.PPTIcon,
        pages: App_url.img.TEXTIcon,
        numbers: App_url.img.TEXTIcon,

    };
    const RenderBody = (item) => {
        const getIcon = (document_type) => {
            return iconMapping[document_type] || App_url.img.TEXTIcon;
        };
        //console.log("item", item);
        return (
            <>
                < React.Fragment >
                    <td>{item?.document_name}</td>
                    <td className='document-type'><Icon className={"me-2 document-type"} attrIcon={getIcon(item?.document_type)} image />{item?.document_type}</td>
                    <td>{Utils.formatDateTimeCreatedAt(item?.created_at)}</td>
                    <td><span className='view-icon-box' onClick={() => handleView(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
                </React.Fragment >
            </>
        )
    }

    const callBack = (e, state, buttonName) => {
        if (state === "AddButton" && buttonName === "shareDocument") {
            dispatch(setShareDocumentModal({
                show: "SHARE_DOCUMENT_MODAL"
            }));
        }
    }

    return (
        <React.Fragment>
            <div className="col-lg-12">
                <CustomTableTwo
                    title={{ text: "Documents", size: "fs-20", textTwo: `Total Documents ${documentList?.data ? documentList?.data?.length:"0" }` }}
                    columnNames={["Document Name", "Document Type", "Date & Time", "View"]}
                    AddButton={{ title: "Share Document", variant: "outline-primary", size: "sm" }}
                    recordData={documentList?.data}
                    // recordData={documentList}
                    renderBody={RenderBody}
                    callBack={(e, state) => callBack(e, state, "shareDocument")}
                    search_placeholder={"Search By Name,City,Industry & Postcode"}
                    typePlaceHolder={"Select Status"}
                    className={"document-table"}
                    pagination={documentList?.pagination}
                    onPagination={(page) => callDocumentsListApi(page)}
                    scrollbar={documentList?.data?.length > 10 && true}
                    style={{ height: "350px" }}

                />
            </div>
        </React.Fragment>
    )
}

export default DocumentList