import React, { useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Breadcrumb, Card } from 'react-bootstrap'
import AnchorLink from '../Common/AnchorLink'
import App_url from '../Common/Constants'
import Button from '../Common/Button'
import CommonTableComp from './CommonTableComp'
import CommonTbl2 from './CommonTbl2'
import OfferModal from './OfferModal'

const CalculationOverview = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <ContentLayout title={'Calculation Overview'}>
            <div className='overview'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.savedCalculation}>Saved Calculation </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >Select Category </Breadcrumb.Item>
                    <Breadcrumb.Item > Glass Cleaning</Breadcrumb.Item>
                    <Breadcrumb.Item active>Calculation Overview</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='card'>
                    <Card.Body>
                        <div className='card-body-header'>
                            <div className='.item1  .flex-item'>
                                <h6 className='card-body-header-title'>Calculation Overview</h6>
                            </div>
                            <div className='btns'>
                                <Button size={'md'} className={'save-btn'}>Save Calculation</Button>
                                <Button size={'md'} variant={"primary"} onClick={openModal}>Add To Offer</Button>
                            </div>
                        </div>
                        <div className='heading'>
                            <h6>Customer : Tata Motor </h6>
                        </div>

                        <div className='row mt-3'>
                            <div className=''>
                            <CommonTableComp />
                            </div>
                            <div className='mt-3'>
                            <CommonTableComp />

                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='mt-2'>
                                <CommonTbl2 />
                            </div>
                            <div className='mt-2'>
                                <CommonTbl2 />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <OfferModal isModalOpen={isModalOpen} handleClose={closeModal} />
            </div>
        </ContentLayout>
    )
}

export default CalculationOverview