/* eslint-disable react-hooks/rules-of-hooks */

const wsSendRequest = (websocket, params, device_id)=>{
    // const site_code = localStorage.getItem("site_code");
    if(device_id){
        params.device_id = device_id
    }

    if(!params.request){
        params.request = {}
    }
    if(params?.transmit){
        params.transmit = 'single'
    }
    // if(params.request){
    //     params.request.site_code = site_code
    // }
    if(params){
        JSON.stringify(params);
    }
    // console.log("params", params, websocket)

    if(websocket?.readyState === websocket?.OPEN ){

        if(params?.request !== undefined){
            setTimeout(() => {
                 websocket && websocket?.send(JSON.stringify(params))
            }, [700]);
        } else {
            websocket && websocket?.send(JSON.stringify(params))
        };
    }else if(websocket?.readyState !== websocket?.CLOSED){
        setTimeout(()=>{
             wsSendRequest(websocket, params, device_id)
        },1500)
    };
}
export default wsSendRequest;
