import React from 'react'
import ContentLayout from '../layout/ContentLayout'

export default function ServicesPage() {
  return (
    <ContentLayout title={"Services Page"}>
    <h6>Working on Services Page...</h6>
    </ContentLayout>
  )
}
