/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */


import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import Button from './Button'
import Scrollbar from './Scrollbar'
import InputGroup from './InputGroup'
import App_url from './Constants'
import AnchorLink from './AnchorLink'
import Images from './Image'
import PaginationPage from './PaginationPage'

const CustomTableTwo = (props) => {
    const [disable, setDisable] = useState(false)
    const [search, setSearch] = useState(props?.filter?.search);
    const { recordData, current_page, columnNames, dataItems } = props;
    const [stateClick, setStateClick] = useState(false)




    const onChangeFilter = (e) => {
        if (e.target.name === "search") {
            setSearch(e.target.value)
        } else {
            props?.setFilter((data) => ({
                ...data,
                [e.target.name]: e.target.value
            }));
        }
    }
    const callSearch = (data) => {
        props?.setFilter((data1) => ({
            ...data1,
            search: data
        }));
        setSearch(data);
    }

    const callBack = async (e, state) => {
        e.preventDefault();
        if (disable === false) {
            setDisable(true)
            await props?.callBack(e, state);
            setDisable(false)
        }
    }
    const onClickIcon = (e, state, item) => {
        e.preventDefault();
        e.stopPropagation();
        if (!stateClick) {
            props?.onClickIcon(state, item);
        }
    }

    const IconView = (item_props) => {
        const { item, index } = item_props;
        if (!props?.view) {
            return <React.Fragment />
        }
        return (
            <Icon disable={stateClick} image attrIcon={App_url.image.EyeIcon} onClick={(e) => onClickIcon(e, "view", { ...item, index: index })} button hover className={"md "} buttonClassName={"btn-sm rounded"} />
        )
    }
    const IconEdit = (item_props) => {
        const { item, index } = item_props
        if (!props?.edit) {
            return <React.Fragment />
        }
        return (
            <Icon disable={stateClick} image attrIcon={App_url.image.editIcon} onClick={(e) => onClickIcon(e, "edit", { ...item, index: index })} button hover className={"md "} buttonClassName={"btn-sm rounded"} />
        )
    }
    const IconDelete = (item_props) => {
        const { item, index } = item_props
        if (!props?.delete) {
            return <React.Fragment />
        }
        return (
            <Icon disable={stateClick} image attrIcon={App_url.image.Delete} onClick={(e) => onClickIcon(e, "delete", { ...item, index: index })} button hover className={"md "} buttonClassName={"btn-sm rounded"} />
        )
    }

    const TableActionIcon = (item_props) => {
        const { item, index } = item_props;
        if (!props?.view && !props?.edit && !props?.delete) {
            return <React.Fragment />
        }
        return (
            <td className='text-nowrap'>
                {IconView({ item: item, index: index })}
                {IconEdit({ item: item, index: index })}
                {IconDelete({ item: item, index: index })}
            </td>
        )
    }

    const ActionComponent = () => {
        return (
            <React.Fragment>
                {props?.filter?.search !== undefined && (
                    <div className={"button-filter search"}>
                        <InputGroup
                            size={"md"}
                            onChange={onChangeFilter}
                            name={"search"}
                            leftLabel={search === "" ? <Icon className={props?.size} attrIcon={App_url.img.SearchIcon} /> : null}
                            rightLabel1={search !== "" ? <Icon className={props?.size} attrIcon={App_url.img.SearchIcon} /> : null}
                            rightLabel={search !== "" ? <Icon className={props?.size} attrIcon={App_url.img.CloseIcon} /> : null}
                            onSubmit={(data) => callSearch(data)}
                            rightIconClick1={() => callSearch(search)}
                            rightIconClick={() => callSearch("")}
                            placeholder={props?.search_placeholder}
                            value={search}
                            formClassName={""}
                        />
                    </div>
                )}
                {props?.filter?.status !== undefined && (
                    <div className={"button-filter search user-type"}>
                        <InputGroup
                            size={"md"}
                            onChange={onChangeFilter}
                            placeholder={props?.typePlaceHolder}
                            name={"user_type"}
                            formClassName={""}
                            select
                            value={props?.filter?.user_type}
                            type="select"
                            option={props?.typeOption}
                            isClearable={props?.isClearable}
                        />
                    </div>
                )}
                {props?.filter?.city !== undefined && (
                    <div className={"button-filter search user-type"}>
                        <InputGroup
                            size={"md"}
                            onChange={onChangeFilter}
                            placeholder={props?.cityPlaceHolder}
                            name={"user_type "}
                            formClassName={""}
                            select
                            value={props?.filter?.city}
                            type="select"
                            option={props?.typeOption}
                            isClearable={props?.isClearable}
                        />
                    </div>
                )}
                {props?.filter?.postcode !== undefined && (
                    <div className={"button-filter search user-type"}>
                        <InputGroup
                            size={"md"}
                            onChange={onChangeFilter}
                            placeholder={props?.postcodePlaceHolder}
                            name={"user_type"}
                            formClassName={""}
                            select
                            value={props?.filter?.postcode}
                            type="select"
                            option={props?.typeOption}
                            isClearable={props?.isClearable}
                        />
                    </div>
                )}
                {props?.AddButton?.title && (
                    <div>
                        <Button
                            className="d-flex-center"
                            variant={props?.AddButton?.variant || 'primary'}
                            onClick={(e) => callBack(e, "AddButton")}
                            size={props?.AddButton?.size || "sm"}
                            disable={disable}
                        >
                            {props?.AddButton?.Icon && <Icon className={'sm'} attrIcon={props?.AddButton?.Icon} />}
                            {props?.AddButton?.title}
                        </Button>
                    </div>
                )}
                {props?.AddButton2?.title && (
                    <div>
                        <Button
                            className="d-flex-center"
                            variant={props?.AddButton2?.variant || 'primary'}
                            onClick={(e) => callBack(e, "AddButton2")}
                            size={props?.AddButton2?.size || "sm"}
                            disable={disable}
                        >
                            {props?.AddButton2?.Icon && <Icon className={'sm'} attrIcon={props?.AddButton2?.Icon} />}
                            {props?.AddButton2?.title}
                        </Button>
                    </div>
                )}
            </React.Fragment>
        )
    }
    const ActionComponent2 = () => {
        return (
            <React.Fragment>
               {props?.AddButton3?.title && (
                    <div>
                        <Button
                            className="d-flex-center"
                            variant={props?.AddButton3?.variant || 'primary'}
                            onClick={(e) => callBack(e, "AddButton3")}
                            size={props?.AddButton3?.size || "sm"}
                            disable={disable}
                        >
                            {props?.AddButton3?.Icon && <Icon className={'sm'} attrIcon={props?.AddButton3?.Icon} />}
                            {props?.AddButton3?.title}
                        </Button>
                    </div>
                )}
            </React.Fragment>
        )
    }

    const TableWithScroll = () => {
        if (props?.scrollbar) {
            return (
                <Scrollbar style={{ ...props?.style }}>
                    <table className="table table-lg mb-0 table-with-radius">
                        <thead className='tbl-thead table-th-bg'>
                            {TableHeader()}
                        </thead>
                        <tbody className='tbl-body table-td-bg'>
                            {TableBody()}
                        </tbody>
                    </table>
                </Scrollbar>
            )
        }
        return (
            <table className="table table-lg mb-0 table-with-radius">
                <thead className='tbl-thead table-th-bg'>
                    {TableHeader()}
                </thead>
                <tbody className='tbl-body table-td-bg'>
                    {TableBody()}
                </tbody>
            </table>
        )
    }

    const callCheckAll = (e) => {
        if (recordData?.length > 0) {
            if (e.target.checked === true) {
                const isSelected = recordData?.filter((item) => {
                    const setCheck = props?.selectList.find(selectedItem => selectedItem[props?.indexName] === item[props?.indexName]);
                    if (setCheck) {
                        return false
                    } else {
                        return true
                    }
                });

                props?.callBackSelectAll([
                    ...props?.selectList,
                    ...isSelected
                ])
            } else {
                const isSelected = props?.selectList?.filter((item) => {
                    const setCheck = recordData.find(selectedItem => selectedItem[props?.indexName] === item[props?.indexName]);
                    if (setCheck) {
                        return false
                    } else {
                        return true
                    }
                });
                props?.callBackSelectAll(isSelected)
            }
        }
    }
    const callCheckBox = (e, item) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            props?.callBackSelectAll([...props?.selectList, { ...item }]);

        } else {
            const updatedSelectList = props?.selectList.filter(selectedItem => selectedItem[props?.indexName] !== item[props?.indexName]);
            props?.callBackSelectAll(updatedSelectList);
        }

    };
    // const callBackSelectAll = (selectList) => {
    //     props?.callBackSelectAll(selectList);
    //     console.log("selectedList", selectList);
    // }
    const TableRowContent = (props1) => {
        const { item, index } = props1;
        const isSelected = props?.selectList.find(selectedItem => selectedItem[props?.indexName] === item[props?.indexName]);
        if (props?.renderBody) {
            return (
                <React.Fragment>
                    <tr>
                        {props?.renderBody(item)}
                        {TableActionIcon({ item: item, index: index })}
                    </tr>
                </React.Fragment>
            )
        }
        return (
            <tr key={index} className={isSelected ? 'selected' : ''} >
                {props?.selected && (
                    <td className='table-select'>
                        <InputGroup type="checkbox" checked={isSelected ? true : false} onCheck={(e) => callCheckBox(e, item, index)} className='checkbox form-check-input' />
                    </td>
                )}
                {recordData.map((recordKey, idx) => (
                    <td key={idx}>
                        <TableItem recordKey={recordKey} index={index} item={item} />
                    </td>
                ))}
                {TableActionIcon({ item: item, index: index })}
            </tr>
        )
    }
    const TableItem = ({ recordKey, item, index }) => {
        const startIdx = parseFloat(current_page - 1) * parseFloat(props?.pagination?.record_limit);
        const listNumber = startIdx + index + 1
        if (recordKey === 'ID') {
            return (
                <AnchorLink href="#">{listNumber}</AnchorLink>
            )
        }
        if (recordKey === "status") {
            return (
                <span className={`badge ${item[recordKey.toLowerCase()] === "Active" ? 'bg-primary' : 'bg-secondary-bright text-secondary'} `}>{item[recordKey.toLowerCase()]}</span>
            )
        } if (recordKey === "image") {
            return (
                <Images className={'custom-tbl-img'} src={item[recordKey?.toLowerCase()]} />
            )
        }
        return (
            <React.Fragment>
                {recordKey === 'Name' && (
                    <AnchorLink href="product-detail.html" className="d-flex-center">
                        <span>{item[recordKey?.toLowerCase()]}</span>
                    </AnchorLink>
                )}
                {recordKey !== 'Name' && <span>{item[recordKey.toLowerCase()]}</span>}
            </React.Fragment>
        )
    }

    const TableHeader = () => {
        const isSelected = recordData?.filter((item) => {
            return props?.selectList.find(selectedItem => selectedItem[props?.indexName] === item[props?.indexName]);
        });
        if (props?.renderHeader) {
            return (
                <React.Fragment>
                    <tr>
                        {props?.renderHeader()}
                    </tr>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <tr>
                    {props?.selected && (
                        <React.Fragment>
                            <th className='table-select'><InputGroup type="checkbox" onCheck={callCheckAll} checked={isSelected?.length > 0 && isSelected?.length === recordData?.length} /></th>
                        </React.Fragment>
                    )}
                    {props?.sr_no && (
                        <React.Fragment>
                            <th className='table-select'>Sr.No.</th>
                        </React.Fragment>
                    )}
                    {columnNames.map((columnName, index) => (
                        <th key={index}>{columnName}</th>
                    ))}
                    {(props?.view || props?.edit || props?.delete) && <th>{props?.actionLabel}</th>}
                </tr>
            </React.Fragment>
        )
    }
    const getSerialNumber = (index) => {
        // Convert current_page to integer and subtract 1 for zero-based index
        const currentPage = parseInt(props?.pagination?.current_page, 10) - 1;
        const recordLimit = parseInt(props?.pagination?.record_limit, 10);
        if (props?.pagination?.current_page === 1) {
            return index + 1;
        } else {

            return currentPage * recordLimit + (index + 1);
        }
    };

    const TableBody = () => {
        if (recordData?.length > 0) {
            return (
                <React.Fragment>
                    {recordData?.map((item, index) => {
                        const isSelected = props?.selectList.find(selectedItem => selectedItem[props?.indexName] === item[props?.indexName]);
                        return (
                            <tr key={index} className={`${isSelected ? 'selected' : ''} table-tr-bg`} >
                                {props?.selected && (
                                    <td className='table-select'>
                                        <InputGroup type="checkbox" checked={isSelected ? true : false} onCheck={(e) => callCheckBox(e, item, index)} />
                                    </td>
                                )}
                                {props?.sr_no && (
                                    <td className='table-select'>
                                        {getSerialNumber(index)}
                                    </td>
                                )}
                                {props?.renderBody(item)}
                                {TableActionIcon({ item: item, index: index })}
                            </tr>
                        )
                    })}
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                {dataItems?.length > 0 ? (
                    <React.Fragment>
                        {dataItems?.map((item, index) => (
                            <React.Fragment key={index}>
                                {TableRowContent({ item: item, index: index })}
                                {/* <TableRowContent item={item} index={index} /> */}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ) : (
                    <tr >
                        <td colSpan={recordData?.length + 10} align='center'>No Data Found</td>
                    </tr>
                )}
            </React.Fragment>
        )
    }

    const TableBodyContent = () => {
        return (
            <div className={`${props?.bodyClassName}`}>
                <div className={"card-header"}>
                    <div className={'card-header-first-div'}>
                        {props?.customTabs ? (
                            <div className='card-header-custom-tabs'>
                                {props?.customTabs?.map((item, index) => (
                                    <h6 className={`tabs-text ${props?.activeTab === item?.value ? "active-tab" : ""}`} onClick={(e) => props?.onCustomTabSelection(e, item)} key={index} size={"sm"}>{item?.label}</h6>
                                ))}
                            </div>
                        ) : (
                            <div>
                                <h6 className={`heading-title ${props?.title?.size}`}>{props?.title?.text}</h6>
                                {props?.subtitle?.text && <h6 className={`${props?.subtitle?.size}`}>{props?.subtitle?.text}</h6>}
                            </div>
                        )}
                        <div className='d-flex-center gap-2'>
                            {props?.title?.textTwo &&
                                <h6 className={`heading-title-two ${props?.title?.sizeTwo}`}>{props?.title?.textTwo}</h6>
                            }
                            {ActionComponent()}
                        </div>
                    </div>

                    <div className='d-flex justify-content-end mt-1'>
                        {ActionComponent2()}
                    </div>
                </div>
                <div className={`card-body p-0  ${props?.wrapperClassName}`}>
                    <div className='table-responsive'>
                        {TableWithScroll()}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Suspense fallback={<React.Fragment />}>
            <div className={`custom-table-two card rounded-3 ${props?.className}`}>
                {(props?.title || props?.AddButton) && (
                    TableBodyContent()
                )
                }
                {props?.pagination &&
                    <PaginationPage pagination={props?.pagination} onChange={props?.onPagination} />
                }
            </div>
        </React.Suspense>
    )
}

CustomTableTwo.propTypes = {
    recordData: PropTypes.array.isRequired,
    current_page: PropTypes.number.isRequired,
    columnNames: PropTypes.array.isRequired,
    dataItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func,
    renderHeader: PropTypes.func,
    setFilter: PropTypes.func,
    onClickEdit: PropTypes.func,
    edit: PropTypes.bool,
    onClickDelete: PropTypes.func,
    stateFilter: PropTypes.bool,
    addTitle: PropTypes.any,
    addLink: PropTypes.any,
    stateTitle: PropTypes.bool,
    tbl1_heading: PropTypes.bool,
    tbl2_heading: PropTypes.bool,
    scrollbar: PropTypes.bool,
    style: PropTypes.any,
    Filter: PropTypes.bool,
    delete: PropTypes.bool,
    search: PropTypes?.any,
    search_placeholder: PropTypes?.any,
    className: PropTypes?.any,
    bodyClassName: PropTypes?.any,
    wrapperClassName: PropTypes?.any,
    onPagination: PropTypes?.func,
    view: PropTypes?.bool,
    onView: PropTypes?.func,
    showEnroll: PropTypes?.any,
    selectList: PropTypes?.array,
    ActionValue: PropTypes?.any,
    title: PropTypes?.any,
    AddEntry: PropTypes?.any,
    ArchiveTitle: PropTypes?.any,
    DeleteTitle: PropTypes?.any,
    CancelTitle: PropTypes?.any,
    AddButton: PropTypes?.any,
    ArchiveButton: PropTypes?.any,
    staffDropdown: PropTypes?.any,
    onSelectAction: PropTypes?.func,
    onSelect: PropTypes?.func,
    onClickButton: PropTypes?.func,
    callBackSelectAll: PropTypes?.func,
    onClickIcon: PropTypes?.func,
    callBackSelect: PropTypes?.func,
    AddItemVariant: PropTypes?.any,
    actionLabel: PropTypes?.any,
    tab: PropTypes?.array,
    typePlaceHolder: PropTypes?.any,
    methodPlaceHolder: PropTypes?.any,
    typeOption: PropTypes?.array,
    methodOption: PropTypes?.array,
    size: PropTypes?.any,
    indexName: PropTypes?.any,
};

CustomTableTwo.defaultProps = {
    recordData: [],
    current_page: 1,
    columnNames: [],
    dataItems: [],
    onChange: () => { },
    setFilter: () => { },
    onClickEdit: () => { },
    edit: false,
    onClickDelete: () => { },
    tbl1_heading: false,
    tbl2_heading: false,
    scrollbar: false,
    style: {},
    stateFilter: true,
    addTitle: "",
    addLink: "",
    stateTitle: true,
    Filter: true,
    search: "",
    search_placeholder: "Search",
    className: "",
    bodyClassName: "",
    wrapperClassName: "",
    delete: false,
    onPagination: () => { },
    view: false,
    onView: () => { },
    selectList: [],
    ActionValue: "",
    title: "",
    AddEntry: { Icon: App_url.image.download, },
    ArchiveTitle: "",
    DeleteTitle: "",
    showEnroll: "",
    AddButton: "",
    CancelTitle: "",
    ArchiveButton: "",
    staffDropdown: "",
    onSelectAction: () => { },
    onSelect: () => { },
    onClickButton: () => { },
    callBackSelectAll: () => { },
    onClickIcon: () => { },
    callBackSelect: () => { },
    AddItemVariant: "primary-1",
    actionLabel: "Action",
    typeOption: [],
    methodOption: [],
    typePlaceHolder: "Search",
    methodPlaceHolder: "Search",
    size: "sm",
    indexName: "id",
};

export default CustomTableTwo;