import React from 'react'
import ContentLayout from '../layout/ContentLayout'

export default function OffersPage() {
  return (
    <ContentLayout title={"Offers Page"}>
    <h6>Working on Offers Page...</h6>
    </ContentLayout>
  )
}
