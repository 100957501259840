/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Images from '../../../Common/Image'
import App_url from '../../../Common/Constants'
import { setStoreRingCallModal } from '../../../../store/modules/modals/action';


export default function RingCallModal (){
    const dispatch = useDispatch();
    const { ModalPopup } = useSelector(App_url.modalReducers);
    const handleClose = async () => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(ModalPopup?.data?.item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreRingCallModal())
    }

    if (ModalPopup?.show !== "RING_CALL_MODAL") {
        return null
    }
    return (
        <div>
            <Modal show={true} onHide={CloseData} centered size='md' className='callmodal'>
                <Modal.Body>
                    <div className="card">
                        <Images width={10} height={10} src={App_url.image.RingCallBg} alt={'User Image'} />
                        <div className="card-img-overlay">
                           <div className='card-overly-content'>
                            <div className='user-img-box'>

                        <Images className={"user-image mb-4"} width={10} height={10} src={App_url.image.RingCallUserImg} alt={'User Image'} />
                            </div>
                           <h6 className='user-name'>Rayna Korsgaard</h6>
                            <h6 className='ring-call'>Ring Call</h6>
                            <Button onClick={CloseData} className={"call-btn"} >Call End</Button>
                           </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

RingCallModal.propTypes = {
    handleClose: PropTypes?.func,
    show: PropTypes?.bool,
}
RingCallModal.defaultProps = {
    handleClose: () => { },
    show: false,
}