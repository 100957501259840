/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../../../Common/Button';
import Icon from '../../../Common/Icon';
import App_url from '../../../Common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import InputGroup from '../../../Common/InputGroup';
import { setScheduleCallModal, setStoreCallScheduleSuccessfulModal } from '../../../../store/modules/modals/action';
import UtilsGetList from '../../../Common/UtilsGetList';
import { WebSocketContext } from '../../../../WSContext';

export default function ScheduleCallModal({ isEditing }) {

  const [filter, setFilter] = useState({ status: true, })
  const [timeAmPM, setTimeAmPm] = useState("AM");
  // const [timePm, setTimePm] = useState(false);
  const ws = useContext(WebSocketContext);
  const dispatch = useDispatch()
  const { ModalPopupThree } = useSelector(App_url.modalReducers);
  const { scheduleList, customerDetails } = useSelector(App_url.customerReducers);
  const { device_id } = useSelector(App_url.userDataReducers);
  const scheduleDate = isEditing ? new Date(ModalPopupThree?.data?.item?.schedule_date) : null;
  const normalizedScheduleDate = scheduleDate ? new Date(scheduleDate.getFullYear(), scheduleDate.getMonth(), scheduleDate.getDate()) : null;
  const [previouslySelectedDate, setPreviouslySelectedDate] = useState(normalizedScheduleDate);


  // const [selectedDate, setSelectedDate] = useState(null);


  const [formData, setFormData] = useState({
    customer_id: "",
    remark: "",
    schedule_date: new Date(),
    schedule_time: "",
    hour: "11",
    minute: "00",
  })
  const [error, setError] = useState({
    remark: "",
    schedule_date: "",
    schedule_time: "",
    hour: "",
    minute: "",
  });
  function parseTime(date, timeString) {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');

    if (modifier === 'PM' && hours !== '12') {
      hours = String(Number(hours) + 12);
    }
    if (modifier === 'AM' && hours === '12') {
      hours = '00';
    }

    // Create a new Date object
    const dateObj = new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes
    ));

    // Return time in 'HH:MM[:ss[.uuuuuu]]' format in UTC
    return dateObj.toISOString().substring(11, 19); // HH:MM:SS
  }

  function convertToAmPm(time) {
    const [hour, minute, second] = time.split(':');
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;

    return `${formattedHour}:${minute} ${ampm}`;
  }
  function splitAmPmTime(time) {
    const [timePart, ampm] = time.split(' '); // Splits "3:04 PM" into "3:04" and "PM"
    const [hour, minute] = timePart.split(':'); // Splits "3:04" into "3" and "04"

    return {
      hour,
      minute,
      ampm
    };
  }


  useEffect(() => {
    if (ModalPopupThree?.show === 'SCHEDULE_CALL_MODAL' && ModalPopupThree?.data?.item) {
      console.log("DataEdit", ModalPopupThree?.data);

      const hrmin = convertToAmPm(ModalPopupThree?.data?.item?.schedule_time);
      const { hour, minute, ampm } = splitAmPmTime(hrmin);

      const scheduleDate = new Date(ModalPopupThree?.data?.item?.schedule_date);

      // Update form data with the fetched data
      setFormData((data) => ({
        ...data,
        remark: ModalPopupThree?.data?.item?.remark,
        schedule_date: scheduleDate,
        schedule_time: "",
        hour: hour,
        minute: minute,
        // hour: String(hour).padStart(2, '0'),
        // minute: String(scheduleMinute).padStart(2, '0'),
      }));
      // console.log("ModalPopupThree?.data?.item?.schedule_date", ModalPopupThree?.data?.item?.schedule_date)
      setTimeAmPm(ampm);

    }
  }, [ModalPopupThree?.data?.item]);

  useEffect(()=>{
    if(ws && ModalPopupThree?.show === "SCHEDULE_CALL_MODAL" && ModalPopupThree?.data?.customerId){
        console.log("ModalPopupThree?.data?.customerId", ModalPopupThree?.data?.customerId)
        UtilsGetList.callCustomerDetailsApi({ws, customer_id:ModalPopupThree?.data?.customerId, device_id})
    }
  },[ws,  ModalPopupThree?.data?.customerId])


  const remarkOptions = [
    { label: 'Talk about offer', value: 'Talk about offer' },
    { label: 'Fix Appointment', value: 'Fix Appointment' },
    { label: 'Send a tender', value: 'Send a tender' }
  ]


  const validation = () => {
    let val = true;
    if (!formData?.schedule_date) {
      error.schedule_date = "Please select date"
      val = false;
    }
    // if (!formData?.schedule_time) {
    //   error.schedule_time = "Please select time"
    //   val = false;
    // }
    // if (!formData?.remark) {
    //   error.remark = "Please enter remark"
    //   val = false;
    // }
    setError((data) => ({
      ...data,
      ...error
    }))
    return val;
  }


  const onChange = (e) => {
    const { name, value } = e.target;
    // Allow partial matches
    const hourRegex = /^(0?[1-9]?)$|^(1[0-2]?)$/;
    const minuteRegex = /^([0-5]?[0-9]?)$/;

    if (name === 'hour' && !hourRegex.test(value)) return;
    if (name === 'minute' && !minuteRegex.test(value)) return;

    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
    setError((data) => ({
      ...data,
      [e.target.name]: "",
    }))

  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  const handleClose = async () => {
    if (ModalPopupThree?.callBackModal) {
      const timeString = `${formData?.hour}:${formData?.minute} ${timeAmPM}`;
      const payload = {
        remark: formData?.remark ? formData?.remark : remarkOptions[0]?.value,
        schedule_date: formatDate(formData?.schedule_date),
        schedule_time: parseTime(formData?.schedule_date, timeString),
      }
      await ModalPopupThree?.callBackModal(payload, ModalPopupThree?.data?.item);
    }
    CloseData()
  };
  const CloseData = () => {
    dispatch(setScheduleCallModal())
    setFormData({
      customer_id: "",
      remark: "",
      schedule_date: new Date(),
      schedule_time: "00",
      hour: "11",
      minute: "00",
    })
  }


  if (ModalPopupThree?.show !== "SCHEDULE_CALL_MODAL") {
    return null
  }

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      const today = new Date();
      const normalizedToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const normalizedSelectedDate = formData.schedule_date
        ? new Date(formData.schedule_date.getFullYear(), formData.schedule_date.getMonth(), formData.schedule_date.getDate())
        : null;

      // Disable dates before today when adding, except today itself
      if (!isEditing && date < normalizedToday) {
        return date.getTime() !== normalizedToday.getTime(); 
      }

      // Disable dates before the selected date when editing
      if (isEditing && normalizedSelectedDate && date < normalizedSelectedDate) {
        return false; 
      }
    }
    return false;
  };

  console.log("formdata", formData);
  const onSubmit = (e) => {
    e.preventDefault()
    if (validation()) {
      // setPreviouslySelectedDate(formData.schedule_date);
      ModalPopupThree?.data?.item ? handleClose(formData, ModalPopupThree?.data?.item) : handleClose(formData)

    } else {
      console.log("error in validation...")
    }
  }

  // const handScheduleCall = () => {
  //   dispatch(setStoreCallScheduleSuccessfulModal({
  //     show: "CALL_SCHEDULED_SUCCESSFUL_MODAL"
  //   }))
  // }

  const handleDateChange = (selectedDate) => {
    const normalizedSelectedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());

    if (isEditing) {
      setPreviouslySelectedDate(formData.schedule_date);
    }

    // Update form data with the new selected date
    setFormData((prevData) => ({ ...prevData, schedule_date: normalizedSelectedDate }));
  };



  return (
    <div>
      <Modal show={true} centered size='lg' className='callSchedule'>
        <Modal.Body className=''>
          <div className="modal-grid" onSubmit={onSubmit}>
            <div className="calendar-section">
              <div className='custom-calendar'>
                <Calendar
                  name="schedule_date"
                  value={formData.schedule_date}
                  onChange={handleDateChange}
                  tileClassName={({ date, view }) => {
                    const normalizedTileDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                    const normalizedSelectedDate = new Date(formData.schedule_date.getFullYear(), formData.schedule_date.getMonth(), formData.schedule_date.getDate());

                    if (normalizedTileDate.getTime() === normalizedSelectedDate.getTime()) {
                      return 'highlighted-date';
                    }

                    if (previouslySelectedDate && normalizedTileDate.getTime() === previouslySelectedDate.getTime()) {
                      return 'previously-selected-date';
                    }

                    return null;
                  }}
                  tileDisabled={tileDisabled}
                />


                <div className='custom-calendar-hr'>
                  <hr />
                </div>
                <div className='callSchedule-time-box'>
                  <InputGroup
                    formClassName={'hour col-6'}
                    name={"hour"}
                    value={formData?.hour}
                    error={error?.hour}
                    onChange={onChange}
                    number

                  />
                  <span className='colon'>:</span>
                  <InputGroup
                    formClassName={'minute col-6'}
                    name={"minute"}
                    error={error?.minute}
                    value={formData?.minute}
                    onChange={onChange}
                    number
                  />
                  <div className='am-pm'>
                    <div onClick={() => { setTimeAmPm("AM") }} className={`am ${timeAmPM === "AM" ? "active" : ""}`}>AM</div>
                    <div onClick={() => { setTimeAmPm("PM") }} className={`pm ${timeAmPM === "PM" ? "active" : ""}`}>PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-section">
              <div className='call-schedule-close-btn'>
                <Icon className={"close-icon"} onClick={CloseData} image attrIcon={App_url.img.RedCrossIcon} />
              </div>
              <div className='form-section-box'>
                <div className='info '>
                  <span className='' ><Icon className='close' attrIcon={App_url.img.BuildingIcon2} image /></span>
                  <h6 className='cmnData'>{customerDetails[0]?.company_name}</h6>
                </div>
                <div className='info mb-3'>
                  <span className='' ><Icon className='close' attrIcon={App_url.img.UserIcon} image /></span>
                  <h6 className='cmnData'>{customerDetails[0]?.customer_name}</h6>
                </div>
                <div className=''>
                  <h6 className='mb-2'>Contact Number</h6>
                  <div className='companyinfo mb-3'>
                    <h6>Company</h6>
                    <h6 className='phone-number'>{customerDetails[0]?.company_mobile}</h6>
                  </div>
                  <div className='companyinfo mt-2'>
                    <h6>{customerDetails[0]?.customer_name}</h6>
                    <h6 className='phone-number'>{customerDetails[0]?.mobile}</h6>
                  </div>
                  <div className='mt-4 add-remark'>
                    <h6 className='mb-2'>Add Remark</h6>
                    <InputGroup
                      name={"remark"}
                      placeholder={"Select"}
                      formClassName={"add-remark-select "}
                      value={formData?.remark ? formData?.remark : remarkOptions[0]?.value}
                      error={error?.remark}
                      onChange={onChange}
                      type="select"
                      select
                      option={remarkOptions}
                      isClearable={false}
                    />
                  </div>
                </div>
              </div>
              <div className='px-3'>
                <Button onClick={onSubmit} variant="primary" className='col-lg-12 mt-5 mb-4 rounded-3'>
                  Schedule call
                </Button>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  )
}