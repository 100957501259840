/* eslint-disable */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, createContext, useState } from "react"
import { Ws_OnMessage } from "./socketIO/onMessage";
import { useNavigate } from "react-router";

const WebSocketContext = createContext()

function WebSocketProvider(props) {
    const { children } = props;
    const navigate = useNavigate();
    const {access_token, device_id, ws, setWebSocket} = props
    const dispatch = useDispatch()
    useEffect(() => {
        if(access_token){
            const CallConnectSocket = () =>{
                const ws_socket = new WebSocket(`wss://${process.env.REACT_APP_API_URL}/ws/${access_token}/`);
                ws_socket.onopen = () => { console.log('WS open') }
                ws_socket.onclose = () => { CallConnectSocket(); }
                ws_socket.onmessage = (message) => {
                    dispatch(Ws_OnMessage({evt:message, ws:ws, device_id:device_id, navigate:navigate}))
                }
                setWebSocket(ws_socket)
            }
            CallConnectSocket();
        }
        return () => { 
            if(ws){
                ws.close()
            }
        }
    }, [access_token])

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}

export { WebSocketContext, WebSocketProvider }