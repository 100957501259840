/* eslint-disable */
import React, { useState } from 'react'
import CustomTableTwo from '../Common/CustomTableTwo'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import ContentLayout from '../layout/ContentLayout'
import { useNavigate } from 'react-router-dom'

const OpenCalculation = () => {
    const navigate = useNavigate()
    const [filter, setFilter] = useState({
        search:"",
    })
    const SavedCalculation = [
        { service_category: "Maintenance Cleaning", customer_name: "Indu Gandhi", last_edit: '28 Feb 2024, 10:10 AM', action: "Action" },
        { service_category: "Carpet Cleaning", customer_name: "Farid Chabra", last_edit: '28 Feb 2024, 10:10 AM', action: "Action" },
        { service_category: "Maintenance Cleaning", customer_name: "Akhilesh Iyer", last_edit: '28 Feb 2024, 10:10 AM', action: "Action" },
        { service_category: "Glass Cleaning", customer_name: "Geet Ghosh", last_edit: '28 Feb 2024, 10:10 AM', action: "Action" },
        { service_category: "Carpet Cleaning", customer_name: "Anshu Ghosh", last_edit: '28 Feb 2024, 10:10 AM', action: "Action" },
        { service_category: "Maintenance Cleaning", customer_name: "Anjali Ghosh", last_edit: '28 Feb 2024, 10:10 AM', action: "Action" },

    ];

    const handleView = () =>{
        console.log("item"  )
    }

    const RenderBody = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment className='dashTitle'>
                <td>{item?.service_category}</td>
                <td>{item?.customer_name}</td>
                <td>{item?.last_edit}</td>
                <td><span className='view-icon-box' onClick={() => handleView(item, "viewRemark")}><Icon className={"bg-primary"} attrIcon={App_url.img.DeleteIcon} /></span></td>
            </React.Fragment>
        )
    }

    return (
        <ContentLayout title={'Calculation'}>
            <CustomTableTwo
                title={{ text: "Saved Calculation", size: "fs-20", textTwo: `Total Saved Calculation ${SavedCalculation.length}`, sizeTwo:"fs-14" }}
                columnNames={["Service Category", "Cuatomer Name ", "Last Edit", "Action"]}
                recordData={SavedCalculation}
                renderBody={RenderBody}
                search_placeholder={"Search Company Name"}
                //  callBack={callBack}
                filter={filter}
            />
        </ContentLayout>
    )
}

export default OpenCalculation