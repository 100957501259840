/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import App_url from '../../Common/Constants';
import { Modal } from 'react-bootstrap';
import Images from '../../Common/Image';
import Icon from '../../Common/Icon';
import Button from '../../Common/Button';
import { setStoreComposeMailModal, setViewDocumentModal } from '../../../store/modules/modals/action';
import { GetRequestCallAPI } from '../../../Api/api/GetRequest';
import { toast } from 'react-toastify';
import { PostRequestAPI } from '../../../Api/api/PostRequest';

const ViewDocumentModal = () => {
    const dispatch = useDispatch()
    const { ModalPopupTwo } = useSelector(App_url.modalReducers);
    const {access_token} = useSelector(App_url.userDataReducers);
    const {customerDetails} = useSelector(App_url.customerReducers);
    const [fileUrl, setFileUrl] = useState(null);
    const [fileType, setFileType] = useState(null);


    const ModalData = ModalPopupTwo?.data?.item

    // console.log("ModalPopupTwo:", ModalData);

    const handleClose = async () => {
        if (ModalPopupTwo?.callBackModal) {
            await ModalPopupTwo?.callBackModal(ModalPopupTwo?.data?.item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setViewDocumentModal())
    }


    const handleSendMailDocument = () =>{
        console.log("ModalPopupTwo", ModalPopupTwo);
        dispatch(setViewDocumentModal());
        dispatch(setStoreComposeMailModal({
            show:"COMPOSE_MAIL_MODAL",
            data:{documentImage:[ModalPopupTwo?.data?.item]},
            callBackModal: (mailData) => handleComposeMailAPI(mailData),
        }))
    }
    const handleComposeMailAPI = async (mailData) => {
        console.log("mailData", mailData);
        const payload = {
            subject: mailData?.subject,
            body: `<body>${mailData?.msg}</body>`,
            document: mailData?.document || [],
            inline_ids: mailData?.inline_ids || [],
            to: mailData?.email,
            cc: mailData?.cc,
            bcc: mailData?.bcc,
            customer: [customerDetails[0]?.id],
            content_type: "Html"
        };
        console.log('payload', payload)
        const response = await PostRequestAPI(App_url.api.COMPOSE_MAIL, payload, access_token)
        console.log("responseEmail", response);
        if (response?.status === 200) {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.data?.error || response.data?.detail);
        }
    }

    if (ModalPopupTwo?.show !== "VIEW_DOCUMENT_MODAL") {
        return null
    }
    return (
        <Modal show={true} className='viewDoc'>
            <Modal.Body className='' style={{ minHeight:"240px" }}>
                <div className='header'>
                    <h6 className='title ellipsis2'>{ModalData?.document_name}</h6>
                    <div className='subheader'>
                        <Button onClick={handleSendMailDocument} variant="primary" className='' size={"sm"}>Send Mail</Button>
                        <Icon onClick={CloseData} className={"ms-2 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                    </div>
                </div>
                <div>
                    <Images width={100} height={100} auth src={`${process.env.REACT_APP_API_DOMAIN_URL}/file/${ModalData?.view_file_url}`} alt="Document" />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewDocumentModal