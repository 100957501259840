/* eslint-disable */
import React, { useState } from 'react'
import CustomTableTwo from '../Common/CustomTableTwo'
import ContentLayout from '../layout/ContentLayout'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import InputGroup from '../Common/InputGroup'
import { useNavigate } from 'react-router-dom'

const NewCalculation = () => {
    const navigate = useNavigate()
    const [filter, setFilter] = useState({
        search:"",
        city:"",
        postcode:"",
    })
    const newCalculation = [
        { sr_no: 1, industry: "Hospitality", comp_name: "Tata Group", person_name: "Anjali Gandhi", email: "Anjali@gmail.com", phone_no: "+49 15 1234 5678", street_no: "1213 Banner,RD Indian", city: "Kamarhati", action: 'view' },
        { sr_no: 2, industry: "Hospitality", comp_name: "Tata Group", person_name: "Deshad Garg", email: "Deshad@gmail.com", phone_no: "+49 15 1234 5678", street_no: "1213 Banner,RD Indian", city: "Nagaon", action: 'view' },
        // { industry: "Hospitality", comp_name: "Tata Group", person_name: "Akhilesh Iyer", email: "Anjali@gmail.com", phone_no: "", street_no: "", city: "" },
        { sr_no: 3, industry: "Hospitality", comp_name: "Tata Group", person_name: "Neesha Bist", email: "Neesha@gmail.com", phone_no: "+49 15 1234 5678", street_no: "1213 Banner,RD Indian", city: "Dhanbad", action: 'view' },
        { sr_no: 4, industry: "Hospitality", comp_name: "Tata Group", person_name: "Anshu Ghosh", email: "Anshu@gmail.com", phone_no: "+49 15 1234 5678", street_no: "1213 Banner,RD Indian", city: "Chandigarh", action: 'view' },
        { sr_no: 5, industry: "Hospitality", comp_name: "Tata Group", person_name: "Anjali Ghosh", email: "Anshul@gmail.com", phone_no: "+49 15 1234 5678", street_no: "1213 Banner,RD Indian", city: "Uluberia ", action: 'view' },

    ]
    const RenderBody2 = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment className='dashTitle'>
                <td>{item?.sr_no}</td>
                <td>{item?.industry}</td>
                <td>{item?.comp_name}</td>
                <td>{item?.person_name}</td>
                <td>{item?.email}</td>
                <td>{item?.phone_no}</td>
                <td>{item?.street_no}</td>
                <td>{item?.city}</td>
                <td>
                    <span className='view-icon-box' onClick={callBack}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span>
                </td>
            </React.Fragment>
        )
    }

    const callBack = () =>{
        navigate(App_url.link.savedCalculationAdd);
    }

    return (
        <ContentLayout title={'Calculation'}>
            <CustomTableTwo
                title={{ text: "Customer List", size: "fs-18", textTwo: "Total New Calculation 30", sizeTwo:"fs-14"}}
                subtitle={{ text: "Select a customer to proceed further", size:"fs-12"}}
                AddButton3={{ title: "Proceed Without Customer", variant: "grey", size: "sm" }}
                cityPlaceHolder={"Select City"}
                postcodePlaceHolder={"Select Postcode"}
                typePlaceHolder2={"Select City"}
                columnNames={["Sr.No", "Industry", "Company Name", "Person Name", "Email", "Phone No.", "Street No", "City","Action"]}
                recordData={newCalculation}
                filter={filter}
                search_placeholder={"Search Company Name"}
                callBack={callBack}
                renderBody={RenderBody2}
            />

        </ContentLayout>
    )
}

export default NewCalculation