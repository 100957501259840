/* eslint-disable */
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import { toast } from "react-toastify";
import Icon from "./Icon";
import App_url from "./Constants";
import { setStoreCurrentUser } from "../../store/modules/users_data/action";

/* eslint-disable eqeqeq */
export function uuidv4() { // Public Domain/MIT
  let d = new Date().getTime();//Timestamp
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export function getCurrentURL(context) {
  const { req } = context;

  const host = req?.headers?.host;
  const protocol = req?.headers['x-forwarded-proto'] || 'http';
  return `${protocol}://${host}`;
}
function checkEmail(emailAddress) {
  return String(emailAddress)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
function ValidateEmail(value, error1, error2) {
  const emailRegex = checkEmail(value)
  if (emailRegex) {
    return false;
  } else {
    if (value == "") {
      return error1 || "Please enter email address";
    }
    return error2 || 'Invalid email address!';
  }
}
const checkPasswordValidity = (value) => {
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value)) {
    return "Password must not contain Whitespaces.";
  }

  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
  if (!isContainsUppercase.test(value)) {
    return "Password must have at least one Uppercase Character.";
  }

  const isContainsLowercase = /^(?=.*[a-z]).*$/;
  if (!isContainsLowercase.test(value)) {
    return "Password must have at least one Lowercase Character.";
  }

  const isContainsNumber = /^(?=.*[0-9]).*$/;
  if (!isContainsNumber.test(value)) {
    return "Password must contain at least one Digit.";
  }

  const isContainsSymbol =
    /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
  if (!isContainsSymbol.test(value)) {
    return "Password must contain at least one Special Symbol.";
  }

  const isValidLength = /^.{8,16}$/;
  if (!isValidLength.test(value)) {
    return "Password must be 8-16 Characters Long.";
  }
  return null;
}

export const AuthenticateResponse = (response, dispatch, state, dataReturn) => {
  // console.log("response", response);
  const errorData = {};

  if (response?.status === 400) {
    toast.error(response?.data?.message || response?.data?.error);
  } else if (response?.status === 403) {
    toast.error(response?.data?.error || response?.data?.detail);
    
  }else if(response?.status === 401){
    toast.error(response?.data?.message || response?.data?.error || "Unauthorized Access")
    dispatch(setStoreCurrentUser());
  }else if(response?.status === 404){
    toast.error(response?.data?.message || response?.data?.error);
  }

  if (dataReturn) {
    return errorData;
  }
};

const validateMobileNumber = (number) => {
  const regex = /^[0-9]{10}$/;
  if (!regex.test(number)) {
    return "Please enter a valid 10-digit mobile number."
  } else {
    return null;
  }
};

// For showing schedule time in schedule call list
function formatDateTime(dateStr = '', timeStr = '') {
  if (!dateStr || !timeStr) {
    return 'Invalid date or time';
  }
  const [year, month, day] = dateStr.split('-').map(Number);
  const [hours, minutes] = timeStr.split(':').map(Number);

  const date = new Date(year, month - 1, day, hours, minutes);

  const dayOfMonth = date.getDate();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthName = monthNames[date.getMonth()];
  const yearNumber = date.getFullYear();

  let hours12 = date.getHours() % 12 || 12; // Convert to 12-hour format
  const minutesFormatted = String(date.getMinutes()).padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  // Combine into final string
  return `${dayOfMonth} ${monthName} ${yearNumber}, ${hours12}:${minutesFormatted} ${ampm}`;
}
function formatDateTime2(dateString) {
  if (!dateString) {
    return 'Invalid date or time';
  }
 // This converts "2024-09-03T10:00:00Z" to "3 Sep 2024, 10:00 AM" (local time)

 const date = new Date(dateString);

 const day = date.getUTCDate();
 const month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
 const year = date.getUTCFullYear();

 let hours = date.getUTCHours();
 const minutes = String(date.getUTCMinutes()).padStart(2, '0');
 const ampm = hours >= 12 ? 'PM' : 'AM';

 hours = hours % 12;
 hours = hours ? hours : 12; // the hour '0' should be '12'

 return `${day} ${month} ${year}, ${hours}:${minutes} ${ampm}`;
}
function formatDateRange(isoString, time) {

  if (!isoString) {
    return 'Invalid date or time';
  }

  const date = new Date(isoString);
  // Format date
  const dayOfWeekNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const dayOfWeek = dayOfWeekNames[date.getDay()];
  const dayOfMonth = date.getDate();
  const monthName = monthNames[date.getMonth()];


  // Combine into final string without year
  return `${dayOfWeek}, ${monthName} ${dayOfMonth}`;

}
function formatDate(dateString) {
  if (!dateString) {
    return 'NA';
  }
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
}
function formatTime(timeString) {
  // Create a Date object from the time string
  const date = new Date(`1970-01-01T${timeString}Z`); // Using 'Z' to avoid timezone issues

  // Extract hours and minutes
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  // Determine AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  // Construct the final time string
  return `${hours}:${minutes}${period}`;
}
function formatDateTimeCreatedAt(dateStr){
  const date = new Date(dateStr);

  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  // Get the formatted date string
  let formattedDate = date.toLocaleString('en-GB', options).replace(',', '');
  
  // Convert 'am/pm' to 'AM/PM'
  return formattedDate.replace(/am|pm/g, match => match.toUpperCase());
}
function formatFullMonthDate(dateString){
  if (!dateString) {
    return 'NA';
  }
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
   return date.toLocaleDateString('en-GB', options);
}
function formatTimeMinutes(utcTime) {
  const date = new Date(utcTime);

  // Get today's date without time
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Get the local time string in the format of 'hh:mm AM/PM'
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  const localTimeString = date.toLocaleTimeString('en-US', options);

  // Get the difference in minutes between the current time and the provided time
  const now = new Date();
  const timeDiffMinutes = Math.floor((now - date) / 60000);

  // Calculate hours and minutes
  const hours = Math.floor(timeDiffMinutes / 60);
  const minutes = timeDiffMinutes % 60;

  // Format the difference as 'X hr Y min ago'
  let timeAgoString = '';
  if (hours > 0) {
    timeAgoString += `${hours} hr${hours > 1 ? 's' : ''}`;
    if (minutes > 0) {
      timeAgoString += ` ${minutes} min`;
    }
    timeAgoString += ' ago';
  } else {
    timeAgoString = `${minutes} min ago`;
  }

  // If the date is today, return time with time ago
  if (date >= today) {
    return `${localTimeString} (${timeAgoString})`;
  } else {
    // If the date is not today, return the date in 'dd MMM yyyy' format
    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const localDateString = date.toLocaleDateString('en-GB', dateOptions).replace(',', '');
    return `${localDateString}`;
  }
}
function convertToISOString(dateString) {
  // Create a new Date object from the input date string
  const date = new Date(dateString);
  
  // Convert the date to ISO 8601 format and return it
  return date.toISOString();
}
function convertToDateOnly(dateString) {
  // Create a new Date object from the input date string
  const date = new Date(dateString);
  
  // Extract the year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  
  // Return the formatted date in YYYY-MM-DD format
  return `${year}-${month}-${day}`;
}
function ensureISOString(date) {
  // Convert the date to ISO string
  const isoString = date.toISOString();

  // Convert the date to a local time string and compare it with the ISO string
  if (date.toString() === new Date(isoString).toString()) {
      return isoString; // Already in ISO format
  } else {
      // If the date is not in ISO format, convert it to ISO string
      return new Date(date).toISOString();
  }
}

// new appointment dateTime conversions
function formatOnlyDate(dateString) {
  // this: Thu Sep 05 2024 18:48:33 GMT+0530 (India Standard Time)
  // to this: 2024-09-05

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
function formatOnlyTime(dateString) {
  // this: Thu Sep 05 2024 18:48:33 GMT+0530 (India Standard Time)
  // to this: 18:48:33

  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}
function formatToISOLocal(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}
function concatDateTime(date, time) {
  return `${date}T${time}Z`;
}
function extractTime(dateString) {
  return dateString.split('T')[1].split('Z')[0];
}
function extractDate(dateString) {
  return dateString.split('T')[0];
}
function getOnlyMonthFromDate(dateString) {
  // "Fri Sep 06 2024 12:18:13 GMT+0530 (India Standard Time)";
  // Output: 9 (for September)

  const date = new Date(dateString);
  const month = date.getMonth() + 1; // Adding 1 because getMonth() returns a zero-based index
  return month;
}
function getOnlyYearFromDate(dateString) {
  // "Fri Sep 06 2024 12:18:13 GMT+0530 (India Standard Time)";
  // Output: 2024
  const date = new Date(dateString);
  const year = date.getFullYear();
  return year;
}
function formatTimeAmToPM(startISO, endISO) {
  const startDate = new Date(startISO);
  const endDate = new Date(endISO);

  const formatTime = (date) => {
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // If hours are 0, set to 12
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  };

  const startTime = formatTime(startDate);
  const endTime = formatTime(endDate);

  return `${startTime} To ${endTime}`;
}


// view appointment
function formatISOToFullDayMonthTime(dateString) {
  // This converts "2024-09-01T20:34:00Z" to "Tuesday, July 10, 9:00 AM"
  
  const date = new Date(dateString);

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = daysOfWeek[date.getUTCDay()];

    const month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
    const day = date.getUTCDate();

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${dayOfWeek}, ${month} ${day}, ${hours}:${minutes} ${ampm}`;
}


const formatNameSurnameFirstLetter = (item) => {
  const nameParts = item.split(' ');
  const formattedParts = nameParts.map(part => part.charAt(0).toUpperCase());
  return formattedParts.join('');
}

const getIconsByExtension = (attachment) => {
  const extension = attachment?.split('.').pop();
  // console.log("attachment", extension);
  if (extension === "pdf") {
      return (
          <Icon attrIcon={App_url.img.PdfIcon2} image className='sm me-1 ms-1 d-flex-center' />
      )
  } else if (extension === "jpg" || extension === "jpeg" || extension === "png") {
      return (
          <Icon attrIcon={App_url.img.DocIcon} image className='sm me-1 ms-1 d-flex-center' />
      )
  } else if (extension === "xlsx" || extension === "csv") {
      return (
          <Icon attrIcon={App_url.img.ExcelIcon} image className='sm me-1 ms-1 d-flex-center' />
      )
  } else {
      return (
          <Icon attrIcon={App_url.img.TEXTIcon} image className='sm me-1 ms-1 d-flex-center' />
      )
  }
}



const Utils = {
    uuidv4:uuidv4,
    getCurrentURL:getCurrentURL,
    ValidateEmail:ValidateEmail,
    checkPasswordValidity:checkPasswordValidity,
    AuthenticateResponse:AuthenticateResponse,
    formatDateTime:formatDateTime,
    formatDateTime2:formatDateTime2,
    formatDateRange: formatDateRange,
    formatDate:formatDate,
    formatTime:formatTime,
    formatTimeMinutes:formatTimeMinutes,
    formatDateTimeCreatedAt:formatDateTimeCreatedAt,
    formatFullMonthDate:formatFullMonthDate,
    convertToISOString:convertToISOString,
    convertToDateOnly:convertToDateOnly,
    ensureISOString,ensureISOString,
    formatOnlyDate:formatOnlyDate,
    formatOnlyTime:formatOnlyTime,
    formatToISOLocal:formatToISOLocal,
    concatDateTime:concatDateTime,
    extractDate:extractDate,
    extractTime:extractTime,
    formatISOToFullDayMonthTime:formatISOToFullDayMonthTime,
    validateMobileNumber:validateMobileNumber,
    getOnlyMonthFromDate:getOnlyMonthFromDate,
    getOnlyYearFromDate:getOnlyYearFromDate,
    formatTimeAmToPM:formatTimeAmToPM,
    formatNameSurnameFirstLetter:formatNameSurnameFirstLetter,
    getIconsByExtension:getIconsByExtension,
}

export default Utils;