/* eslint-disable no-unused-vars */


import React from 'react'
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap'
import Icon from '../../../Common/Icon'
import App_url from '../../../Common/Constants'
import { useDispatch, useSelector } from 'react-redux';
import { setStoreDiscardChangesModal, setStoreRingCallModal, setStoreViewScheduledCallModal } from '../../../../store/modules/modals/action';
import Utils from '../../../Common/Utils.js';


export default function ViewScheduledCallModal() {
    const dispatch = useDispatch();
    const { ModalPopup } = useSelector(App_url.modalReducers);
    // console.log("ModalPopup", ModalPopup.data)

    const handleClose = async (state) => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(state, ModalPopup?.data?.item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreViewScheduledCallModal())
    }

    if (ModalPopup?.show !== "VIEW_SCHEDULED_CALL_MODAL") {
        return null
    }

    const handleRingCall = () => {
        dispatch(setStoreRingCallModal({
            show: "RING_CALL_MODAL",
        }))
    }
    // const handleDiscardModal = () => {
    //     dispatch(setStoreDiscardChangesModal({
    //         show: "DISCARD_CHANGES_MODAL",
    //     }))
    // }


    return (
        <div>
            <Modal show={true} variant="secondary" centered className='call-view-modal'>
                <Modal.Body className='custom-modal-body'>
                    <div className='header'>
                        <h6 className='title'>Schedule Call</h6>
                        <div className='close-icon-box'>
                            <React.Fragment>
                                <Icon onClick={() => handleClose("editScheduledCall")} className={'edit-icon'} image attrIcon={App_url.img.EditIcon} />
                                <Icon onClick={() => handleClose("deleteScheduledCall")} className={'delete-icon'} image attrIcon={App_url.img.DeleteIcon} />

                            </React.Fragment>
                            <Icon onClick={CloseData} className={'red-close-icon ms-1'} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                    <div className='remark'>
                        <span >{Utils.formatDateRange(ModalPopup?.data?.item?.schedule_date)}, {Utils.formatTime( ModalPopup?.data?.item?.schedule_time)}</span>
                    </div>
                    <div className='user-info-remark-box'>
                        <div className='user-info mb-0  border-bottom '>
                            <div className='company '>
                                <span className='' ><Icon className='' attrIcon={App_url.img.BuildingIcon2} image /></span>
                                <h6 className='cmnData'>{ModalPopup?.data?.item?.company_name}</h6>
                            </div>
                            <div className='username '>
                                <span className='' ><Icon className='' attrIcon={App_url.img.UserIcon} image /></span>
                                <h6 className='cmnData'>{ModalPopup?.data?.item?.customer_name}</h6>
                            </div>
                        </div>
                        <div className='remark-box'>
                            <h6 className=' customHeaderColor '>Remark</h6>
                            <p className='cmnData mt-2 mb-0'>
                                {ModalPopup?.data?.item?.remark}
                            </p>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className=''>
                    <div className='footerData'>
                        <div className='footerData-content'>
                            <Icon className='' attrIcon={App_url.img.TelephoneIcon} image />
                            <h6 className='mobile-number'>{ModalPopup?.data?.item?.company_mobile}</h6>
                            <Icon onClick={() => handleRingCall()} className='' attrIcon={App_url.img.CallIcon} image />
                        </div>
                        <div className='footerData-content'>
                            <Icon className='' attrIcon={App_url.img.MobileIcon} image />
                            <h6 className='mobile-number'>{ModalPopup?.data?.item?.customer_mobile}</h6>
                            <Icon onClick={() => handleRingCall()} className='' attrIcon={App_url.img.CallIcon} image />
                        </div>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

ViewScheduledCallModal.propTypes = {
    handleClose: PropTypes?.func,
    show: PropTypes?.bool,
}
ViewScheduledCallModal.defaultProps = {
    handleClose: () => { },
    show: false,
}