/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import AnchorLink from '../../../Common/AnchorLink';
import App_url from '../../../Common/Constants';
import Icon from '../../../Common/Icon';
import Button from '../../../Common/Button';
import { setStoreCallScheduleSuccessfulModal } from '../../../../store/modules/modals/action';
import Utils from '../../../Common/Utils';


export default function CallScheduledSuccessfulModal({isEditing}) {
    const dispatch = useDispatch();
    const { ModalPopupThree } = useSelector(App_url.modalReducers);

    const handleClose = async () => {
        if (ModalPopupThree?.callBackModal) {
            await ModalPopupThree?.callBackModal(ModalPopupThree?.data?.item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreCallScheduleSuccessfulModal())
    }

    if (ModalPopupThree?.show !== "CALL_SCHEDULED_SUCCESSFUL_MODAL") {
        return null
    }
    // const successMessage = isEditing
    // ? 'Call Rescheduled Successfully'
    // : 'Call Scheduled Successfully';
    return (
        <Modal show={true} centered className='call-scheduled-successfully' >
            <Modal.Body className='modal-body px-4 pt-4'>
                <div className='main-container'>
                    <Icon className={"success-icon"} image attrIcon={App_url.img.CircleSuccess} />
                    <div className='success-msg-content'>
                        <h6>{ModalPopupThree?.data?.message}</h6>
                               
                            {/* <h6>Call Scheduled</h6>
                                <h6>Successfully</h6>
                           
                                <h6>Call Rescheduled</h6>
                                <h6>Successfully</h6> */}
                    </div>
                    <div className='date-time-box'>
                        <div className='date-content'>
                            <h6 className='title'>Date</h6>
                            <h6 className='subtitle'>{Utils.formatDate(ModalPopupThree?.data?.item?.data?.schedule_date)}</h6>
                        </div>
                        <div className='time-content'>
                            <h6 className='title'>Time</h6>
                            <h6 className='subtitle'>{Utils.formatTime(ModalPopupThree?.data?.item?.data?.schedule_time)}</h6>
                        </div>
                    </div>
                    <Button onClick={CloseData} className={"w-100 mb-2"} variant={"primary"}>Okay..!</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
CallScheduledSuccessfulModal.propTypes = {
    handleClose: PropTypes?.func,
    show: PropTypes?.bool,
}
CallScheduledSuccessfulModal.defaultProps = {
    handleClose: () => { },
    show: false,
}