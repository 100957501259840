/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import Icon from '../../../Common/Icon';
import App_url from '../../../Common/Constants';
import CustomTableTwo from '../../../Common/CustomTableTwo';
import { useDispatch, useSelector } from 'react-redux';
import { setStoreComposeMailModal, setStoreViewMailModal } from '../../../../store/modules/modals/action';
import { PostRequestAPI } from '../../../../Api/api/PostRequest';
import { toast } from 'react-toastify';
import wsSendRequest from '../../../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../../../WSContext';
import { GetRequestCallAPI } from '../../../../Api/api/GetRequest';
import Utils from '../../../Common/Utils';

const EmailHistoryList = (props) => {
    const dispatch = useDispatch();
    const ws = useContext(WebSocketContext);
    const { device_id } = useSelector(App_url.userDataReducers);
    const { emailHistoryList, sentEmailHistoryList, customerDetails } = useSelector(App_url.customerReducers);
    const {access_token} = useSelector(App_url.userDataReducers)

    const callEmailHistoryListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "inbox_list",
            request: {
                status: true,
                limit: "20",
                page_no: page,
                search: "",
                order_by: "-updated_at",
                customer_id: customerDetails[0]?.id,
                form_date: "",
                to_date: "",
            }
        };
        wsSendRequest(ws, param, device_id);
    };

    // For sent email api call function
    const callSentEmailHistoryListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "sent_list",
            request: {
                status: true,
                limit: "20",
                page_no: page,
                search: "",
                order_by: "-updated_at",
                customer_id: customerDetails[0]?.id,
                form_date: "",
                to_date: "",
            }
        };
        wsSendRequest(ws, param, device_id);
    };

    const callSyncMailApi = async() =>{
        const response = await GetRequestCallAPI(App_url.api.SYNC_SENT_MAIL, access_token);
        const response2 = await GetRequestCallAPI(App_url.api.SYNC_INBOX_MAIL, access_token);
        // console.log("response", response)
        // console.log("response2", response2)

    }

    useEffect(() => {
        if (ws) {
            callSyncMailApi();
            callEmailHistoryListApi(1);
            callSentEmailHistoryListApi(1);
        }
    }, [ws]);

    const [selectData, setSelectData] = useState([]);
    const [star, setStar] = useState(false)

    const customTabs = [
        { label: "OutBox", value: "send_mail" },
        { label: "Inbox", value: "received_mail" },
        { label: "Draft", value: "stared_mail" },
        { label: "Trash  ", value: "trash_mail" },
    ]
    const [activeTab, setActiveTab] = useState(customTabs[1]);

    const handleTabVisibility = (selectedLabel) =>{
        setActiveTab(selectedLabel)
    }

    const handleViewMail = (item) => {
        dispatch(setStoreViewMailModal({
            show: "VIEW_MAIL_MODAL",
            data:{item:item},
        }))
    }

    const handleComposeMailAPI = async (mailData) => {
        console.log("mailData", mailData);
        const payload = {
          subject: mailData?.subject,
          body: `<body>${mailData?.msg}</body>`,
          document: mailData?.document,
          inline_ids: mailData?.inline_ids,
          to: mailData?.email,
          cc: mailData?.cc,
          bcc: mailData?.bcc,
          customer: [customerDetails[0]?.id],
          content_type: "Html"
        };
        const response = await PostRequestAPI(App_url.api.COMPOSE_MAIL, payload, access_token)
        console.log("responseEmail", response);
        if (response?.status === 200) {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.data?.error || response.data?.detail);
        }
    }

    const handleStarSelected = () => {
        setStar(!star)
    }

    const callBack = (e, state, buttonName) => {
        if (state === "AddButton" && buttonName === "composeMail") {
            dispatch(setStoreComposeMailModal({
                show: "COMPOSE_MAIL_MODAL",
                callBackModal: (mailData) => handleComposeMailAPI(mailData)
            }))
        }
    }

    const RenderBody = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment>
                <td className='text-primary'><u>{item?.customer_email}</u></td>
                <td >{item?.subject}</td>
                <td>{Utils.formatDateTime2(item?.sent_date_time)}</td>
                <td><span className='view-icon-box' onClick={()=>handleViewMail(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
            </React.Fragment>
        )
    }
    const RenderBody2 = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment>
                {/* <td className='w-auto'>
                    <Icon image onClick={handleStarSelected} buttonClassName={"email-star-selected-not-selected-icon"} attrIcon={star ? App_url.img.StarSelected : App_url.img.StarNotSelected} button />
                </td> */}
                <td className='text-primary'><u>{item?.from_address}</u></td>
                <td >{item?.subject}</td>
                <td>{item?.dateTime}</td>
                {/* <td>{Utils.formatDateTime(item?.created_at)}</td> */}
                <td><span className='view-icon-box' onClick={()=>handleViewMail(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
            </React.Fragment>
        )
    }
    const RenderBody3 = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment>
                <td className='w-auto'>
                    <Icon image onClick={handleStarSelected} buttonClassName={"email-star-selected-not-selected-icon"} attrIcon={star ? App_url.img.StarSelected : App_url.img.StarNotSelected} button />
                </td>
                <td className='text-primary'><u>{item?.email_id}</u></td>
                <td >{item?.subject}</td>
                <td>{item?.dateTime}</td>
                <td><span className='view-icon-box' onClick={()=>handleViewMail(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
            </React.Fragment>
        )
    }

    return (
        <div>
            <React.Fragment>
                <div className="col-lg-12 mt-0">
                    {activeTab?.value === "send_mail" &&
                        <CustomTableTwo
                            title={{ textTwo: `Total Emails ${sentEmailHistoryList?.data ? sentEmailHistoryList?.data?.length: '0'}` }}
                            columnNames={["Email Id", "Subject", "Date & Time", "View"]}
                            AddButton={{ title: "Compose Mail", variant: "outline-primary", size: "sm" }}
                            recordData={sentEmailHistoryList?.data}
                            renderBody={RenderBody}
                            callBack={(e, state) => callBack(e, state, "composeMail")}
                            customTabs={customTabs}
                            activeTab={activeTab?.value}
                            onCustomTabSelection={(e, item) => handleTabVisibility(item)}
                            search_placeholder={"Search By Name,City,Industry & Postcode"}
                            typePlaceHolder={"Select Status"}
                            scrollbar={sentEmailHistoryList?.data?.length > 10 && true}
                            style={{ height: "350px" }}
                            pagination={sentEmailHistoryList?.pagination}
                            onPagination={(page) => callSentEmailHistoryListApi(page)}
                        />
                    }
                    {activeTab?.value === "received_mail" &&
                        <CustomTableTwo
                            title={{ textTwo: `Total Emails ${emailHistoryList?.data ? emailHistoryList?.data?.length : '0'}` }}
                            columnNames={["Email Id", "Subject", "Date & Time", "View"]}
                            AddButton={{ title: "Compose Mail", variant: "outline-primary", size: "sm" }}
                            recordData={emailHistoryList?.data}
                            // recordData={emailHistoryList?.data}
                            renderBody={RenderBody2}
                            callBack={(e, state) => callBack(e, state, "composeMail")}
                            customTabs={customTabs}
                            activeTab={activeTab?.value}
                            onCustomTabSelection={(e, item) => handleTabVisibility(item)}
                            search_placeholder={"Search By Name,City,Industry & Postcode"}
                            typePlaceHolder={"Select Status"}
                            selected
                            callBackSelectAll={setSelectData}
                            selectList={selectData}
                            indexName={"email_id"}
                            scrollbar={emailHistoryList?.data?.length > 10 && true}
                            style={{ height: "350px" }}
                            pagination={emailHistoryList?.pagination}
                            onPagination={(page) => callEmailHistoryListApi(page)}
                        />
                    }
                    {activeTab?.value === "stared_mail" &&
                        <CustomTableTwo
                            title={{ textTwo: `Total Emails ${[]?.length}` }}
                            columnNames={["", "Email Id", "Subject", "Date & Time", "View"]}
                            AddButton={{ title: "Compose Mail", variant: "outline-primary", size: "sm" }}
                            recordData={[]}
                            renderBody={RenderBody3}
                            callBack={(e, state) => callBack(e, state, "composeMail")}
                            customTabs={customTabs}
                            activeTab={activeTab?.value}
                            onCustomTabSelection={(e, item) => handleTabVisibility(item)}
                            search_placeholder={"Search By Name,City,Industry & Postcode"}
                            typePlaceHolder={"Select Status"}
                        />
                    }
                    {activeTab?.value === "trash_mail" &&
                        <CustomTableTwo
                            title={{ textTwo: `Total Emails 0` }}
                            columnNames={["Email Id", "Subject", "Date & Time", "View"]}
                            AddButton={{ title: "Compose Mail", variant: "outline-primary", size: "sm" }}
                            recordData={[]}
                            renderBody={RenderBody}
                            callBack={(e, state) => callBack(e, state, "composeMail")}
                            customTabs={customTabs}
                            activeTab={activeTab?.value}
                            onCustomTabSelection={(e, item) => handleTabVisibility(item)}
                            search_placeholder={"Search By Name,City,Industry & Postcode"}
                            typePlaceHolder={"Select Status"}
                        />
                    }
                </div>
            </React.Fragment>

        </div>
    )
}

export default EmailHistoryList