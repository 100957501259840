/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactButton from "react-bootstrap/Button";
import { useNavigate } from 'react-router';

export default function Button(props) {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const onClick = async (e) => {
    e.preventDefault();
    if (props?.to) {
      navigate(props?.to, {state: props?.state}); // Replace router.push with window.location.href
    } else {
      setLoader(true);
      await props?.onClick(e);
      setLoader(false);
    }
  }
  useEffect(()=>{
    if(props?.disable != undefined){
      setLoader(props?.disable)
    }
  },[props?.disable])
  return (
    <ReactButton onClick={onClick} disabled={loader} type={props?.type} variant={props?.variant} className={`btn button ${props?.className} btn-${props?.size}`}>
      {props?.children}
    </ReactButton>
  )
}

Button.propTypes = {
  variant: PropTypes.any,
  className: PropTypes.any,
  type: PropTypes.any,
  onClick: PropTypes.func,
  size: PropTypes.any,
  state: PropTypes.any,
  disable: PropTypes.bool,
}

Button.defaultProps = {
  variant: "",
  className: "",
  size: "",
  state: null,
  onClick: () => { },
  type: "button",
}
