/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import { useNavigate, useParams } from 'react-router-dom';
import InputGroup from '../../Common/InputGroup';
import Button from '../../Common/Button';
import { Breadcrumb, Card, Form } from 'react-bootstrap';
import AnchorLink from '../../Common/AnchorLink';
import App_url from '../../Common/Constants';
import Todo from './Todo';
import CallHistory from './CallHistory';
import DocumentList from './DocumentList';
import EmailHistoryList from './EmailHistory/EmailHistoryList';
import { useSelector } from 'react-redux';
import wsSendRequest from '../../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../../WSContext';
import Icon from '../../Common/Icon';

export default function ProspectsDetails(props) {
  const navigate = useNavigate();
  const ws = useContext(WebSocketContext);
  const [activeState, setActiveState] = useState('TODO');
  const { device_id } = useSelector(App_url.userDataReducers);
  const { customerDetails } = useSelector(App_url.customerReducers);
  const { id: customerId } = useParams(); //customer Id
  // console.log("customerId", customerId);

  const [formData, setFormData] = useState({
    company_name: "",
    company_mobile: "",
    customer_name: "",
    email: "",
    mobile: "",
    postal_code: "",
    city: "",
    street_no: "",
    customer_type: "",
    is_active: true,
    customer_position: "",
    industry_type: "",
    company_description: "",
    company_address: "",
    last_name: "",
    status: "",
  });
  

  const callCustomerDetailsApi = () => {
    const param = {
      transmit: "broadcast",
      url: "customer_detail",
      request: { customer_id: customerId }
    };
    wsSendRequest(ws, param, device_id);
  }

  useEffect(() => {
    if (customerId) {
      callCustomerDetailsApi();
    }
  }, [customerId])

  useEffect(() => {
    if (customerDetails && customerDetails.length > 0) {
      setFormData((data) => ({
        ...data,
        ...customerDetails[0],
      }))
    }
  }, [customerDetails])

  // console.log("customerDetails", customerDetails)


  return (
    <React.Fragment>
      {customerId ? (<ContentLayout title={"Prospects Details Page"}>
        <div className=' mb-5'>
          <div className="row m-0">

            <div>
              <Breadcrumb className="breadcrumb-main">
                <Breadcrumb.Item>
                  <AnchorLink to={App_url.link.prospects}>Prospects</AnchorLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Details</Breadcrumb.Item>
              </Breadcrumb>
              <Card className='prospect-details card'>
                <Card.Body>
                  <div className='card-body-header'>
                    <div className='.item1  .flex-item'>
                      <h6 className='card-body-header-title'>{customerDetails[0]?.company_name}</h6>
                    </div>
                    <div className='card-body-filters .item2  .flex-item'>
                      <InputGroup
                        name={"status"}
                        placeholder={"Change Status"}
                        value={formData?.status}
                        formClassName="col-6 col-sm-6 col-md-6 col-lg-4"
                        type="select"
                        option={[{ label: formData?.status, value: formData?.status }]}
                        select
                        disabled
                      />
                      <Button className={""} onClick={() => navigate(`${App_url.link.ProspectsEdit}/${formData?.id}`)} variant={"secondary"}>Edit</Button>
                    </div>
                  </div>
                  <hr className='card-body-header-hr'></hr>
                  <Form className='row'>
                    <div className='col-lg-12 mb-2'>
                      <h6 className='title-info'>Company Details</h6>
                    </div>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <InputGroup
                          label={"Company Name"}
                          placeholder={"Enter company name"}
                          formClassName="col-lg-6 mb-3"
                          name="company_name"
                          value={formData?.company_name}
                          disabled
                        />
                        <InputGroup
                          label={"Phone Number"}
                          placeholder={"Enter phone number"}
                          formClassName="col-lg-6 mb-3"
                          name="company_mobile"
                          value={formData?.company_mobile}
                          rightLabel={<Icon image attrIcon={App_url.img.PhoneRingIcon} />}
                          rightLabelClass="disabled"
                          disabled
                        />
                        <InputGroup
                          label={"Industry Type"}
                          placeholder={"Enter industry type"}
                          formClassName="col-lg-6 mb-3"
                          name="industry_type"
                          value={formData?.industry_type}
                          disabled
                        // select
                        // type={'select'}
                        />
                        <InputGroup
                          label={"Customer Type"}
                          placeholder={"Select prospect customer"}
                          formClassName="col-lg-6 mb-3"
                          name="customer_type"
                          option={[]}
                          value={formData?.customer_type}
                          disabled
                          select
                          type={'select'}
                        />
                      </div>
                    </div>

                    <InputGroup
                      label={"Company Description:"}
                      placeholder={"Enter company description..."}
                      formClassName="mb-3 col-12 col-lg-4"
                      name="company_description"
                      as={"textarea"}
                      rows='5'
                      value={formData?.company_description}
                      disabled
                    />
                    <div className='col-lg-12 mb-2'>
                      <h6 className='title-info'>Contact Person</h6>
                    </div>
                    <InputGroup
                      label={"First Name"}
                      placeholder={"Enter first name"}
                      formClassName="col-lg-4 mb-3"
                      name="customer_name"
                      value={formData?.customer_name}
                      disabled
                    />
                    <InputGroup
                      label={"Last Name"}
                      placeholder={"Enter last name"}
                      formClassName="col-lg-4 mb-3"
                      name="last_name"
                      value={formData?.last_name}
                      disabled
                    />
                    <InputGroup
                      label={"Mobile Number"}
                      placeholder={"Enter mobile number"}
                      formClassName="col-lg-4 mb-3"
                      name="mobile"
                      value={formData?.mobile}
                      rightLabel={<Icon image attrIcon={App_url.img.PhoneRingIcon} />}
                      rightLabelClass="disabled"
                      disabled
                    />
                    <InputGroup
                      label={"Email ID"}
                      placeholder={"Enter email id"}
                      formClassName="col-lg-4 mb-3"
                      name="email"
                      value={formData?.email}
                      rightLabel={<Icon image attrIcon={App_url.img.EmailIcon3} />}
                      rightLabelClass="disabled"
                      disabled
                    />
                    <InputGroup
                      label={"Designation"}
                      placeholder={"Enter designation"}
                      formClassName="col-lg-4 mb-3"
                      name="customer_position"
                      value={formData?.customer_position}
                      disabled
                    />
                    <div className='col-lg-4'></div>
                    <div className='col-lg-12 mb-2'>
                      <h6 className='title-info'>Address Details</h6>
                    </div>

                    <InputGroup
                      label={"Street Number"}
                      placeholder={"Enter street number"}
                      formClassName="col-lg-4 mb-3"
                      name="street_no"
                      value={formData?.street_no}
                      disabled
                    />
                    <InputGroup
                      label={"City"}
                      placeholder={"Enter city"}
                      formClassName="col-lg-4 mb-3"
                      name="city"
                      value={formData?.city}
                      disabled
                    />
                    <InputGroup
                      label={"Post Code"}
                      placeholder={"Enter post code"}
                      formClassName="col-lg-4 mb-3"
                      name="postal_code"
                      value={formData?.postal_code}
                      disabled
                    />
                    <InputGroup
                      label={"Address"}
                      placeholder={"Enter address"}
                      formClassName="col-lg-8 mb-3"
                      name="company_address"
                      value={formData?.company_address}
                      rightLabel={<Icon className={"disabled"} image attrIcon={App_url.img.LocationIcon2} />}
                      rightLabelClass="disabled"
                      disabled
                    />
                  </Form>
                </Card.Body>
              </Card>
            </div>

            <div className='prospects-details tabs my-3'>
              <Button onClick={() => setActiveState("TODO")} style={{  }} className={""} variant={`${activeState === "TODO" ? "primary" : "white"}`} size={""}>To-Do</Button>
              <Button onClick={() => setActiveState("CALL_HISTORY")} className={""} variant={`${activeState === "CALL_HISTORY" ? "primary" : "white"}`} size={""}>Call History</Button>
              <Button onClick={() => setActiveState("EMAIL_HISTORY")} className={""} variant={`${activeState === "EMAIL_HISTORY" ? "primary" : "white"}`} size={""}>Email History</Button>
              <Button onClick={() => setActiveState("DOCUMENTS")} className={""} variant={`${activeState === "DOCUMENTS" ? "primary" : "white"}`} size={""}>Documents</Button>
              {/* <Button onClick={()=>setActiveState("OFFERS")} className={""} variant={`${activeState === "OFFERS" ? "primary":"white"}`} size={""}>Offers</Button> */}
            </div>

            {activeState === "TODO" && <Todo customerId={customerId} />}
            {activeState === "CALL_HISTORY" && <CallHistory />}
            {activeState === "EMAIL_HISTORY" && <EmailHistoryList />}
            {activeState === "DOCUMENTS" && <DocumentList />}
            {/* {activeState == "OFFERS" && ""} */}



          </div>
        </div>
      </ContentLayout>) : (<React.Fragment />)}
    </React.Fragment>
  )
}
