/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import App_url from '../../../Common/Constants';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import Icon from '../../../Common/Icon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Scrollbar from '../../../Common/Scrollbar';
import InputGroup from '../../../Common/InputGroup';
import Utils from '../../../Common/Utils';
import PrimeReactPage from '../../../Common/quill/PrimeReactPage';
import AttachmentEditor from './AttachmentEditor';
import { setNewAppointmentModal, setStoreInviteeInfoModal, setStoreInviteeModalTwoPopup } from '../../../../store/modules/modals/action';
import { PostRequestAPI } from '../../../../Api/api/PostRequest';
import { setStoreCreateAppointment, setStoreUserOutlookDetails } from '../../../../store/modules/users_data/action';
import { useMsal } from '@azure/msal-react';
import ReactSelect from 'react-select';

export default function EditAppointment(props) {
    const dispatch = useDispatch();
    const { selectedInvitees } = useSelector(App_url.userDataReducers);
    const { ModalPopup ,ModalPopupTwo } = useSelector(App_url.modalReducers)
    const [search, setSearch] = useState(props?.filter?.search);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [isAllDay, setIsAllDay] = useState(false);
    const [travelTime, setTravelTime] = useState('None');
    const [repeat, setRepeat] = useState('Never');
    const [endRepeat, setEndRepeat] = useState('Never');
    const [alert, setAlert] = useState('Never');
    const [secondAlert, setSecondAlert] = useState('Never');
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { instance } = useMsal();
    const [showOptions, setShowOptions] = useState(false);
    const customersList = [
        { customer_name: "John", email: "john@example.com" },
        { customer_name: "Smith", email: "jane@example.com" }
    ];
    const [selectedOption, setSelectedOption] = useState(null);

    // console.log("customersList",customersList)
    // console.log("customerDetails",customerDetails)
    const [formData, setFormData] = useState({
        subject: "",
        start_time: "",
        end_time: "",
        location: "",
        attendees: [{ emailAddress: { address: "" }, type: "required" },
        { emailAddress: { address: "" }, type: "required" }],
        customer_id: "",
        is_all_day: false,
        reminder_minutes: 15,
        is_reminder_on: true,
        notes: "",
        web_link: "",
        recurrence: {
            pattern: { type: "weekly", interval: 1, daysOfWeek: ["monday"] },
            range: {
                type: 'endDate', startDate: "", endDate: "", reminder_minutes_second_start: 12,
                assign_appointment: ["", "", "",], attachments_ids: ["", ""]
            }
        }
    })
    useEffect(() => {
        if (ModalPopup?.show === 'EDIT_APPOINTMENT_MODAL' && ModalPopup?.data?.item) {
            console.log("DataEdit", ModalPopup?.data?.item);
            setFormData((data) => ({
                ...data,
                start_time: ModalPopup?.data?.item?.start_time,
                end_time: ModalPopup?.data?.item?.end_time,
            }))
        }
    }, [ModalPopup?.data?.item]);
    
   
    const TravelTimeOptions = [
        { label: "None", value: "None" },
        { label: "5 minutes", value: "5 minutes" },
        { label: "15 minutes ", value: "15 minutes " },
        { label: "30 minutes", value: "30 minutes" },
        { label: "1 hour", value: "1 hour" },
        { label: "1 hour , 30 minutes", value: "1 hour , 30 minutes" },
        { label: "2 hours", value: "2 hours" },
    ]
    const RepeatOptions = [
        { label: "Never", value: "Never" },
        { label: "Every Day", value: "Every Day" },
        { label: "Every Week ", value: "Every Week " },
        { label: "Every 2 Week", value: "Every 2 Week" },
        { label: "Every Month", value: "Every Month" },
        { label: "Every Year", value: "Every Year" },
    ]
    const EndRepeatOptions = [
        { label: "Never", value: "Never" },
        { label: "On Date", value: "On Date" },
    ]
    const AlertOptions = [
        { label: "Never", value: "Never" },
        { label: "At time of event ", value: "At time of event " },
        { label: "5 minutes before", value: "5 minutes before" },
        { label: "15 minutes before", value: "15 minutes before" },
        { label: "30 minutes before", value: "30 minutes before" },
        { label: "1 hour  before", value: "1 hour  before" },
        { label: "2 hour before", value: "2 hour before" },
        { label: "1 day before", value: "1 day before" },
        { label: "2 days before", value: "2 days before" },
        { label: "1 week before", value: "1 week before" },
    ]
    const handleTravelSelect = (eventKey) => {
        setTravelTime(eventKey);
    };
    const handleRepeatSelect = (eventKey) => {
        setRepeat(eventKey);
    };
    const handleEndRepeatSelect = (eventKey) => {
        setEndRepeat(eventKey);
    };
    const handleAlertSelect = (eventKey) => {
        setAlert(eventKey);
    };
    const handleSecondAlertSelect = (eventKey) => {
        setSecondAlert(eventKey);
    };
    const handleClose = async () => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(ModalPopup?.data?.item);
        }
        CloseData()
    };

    const CloseData = () => {
        dispatch(setNewAppointmentModal())
    }

        if (ModalPopup?.show !== "EDIT_APPOINTMENT_MODAL") {
            return null
        }

    const handleInvitee = () => {
        dispatch(setStoreInviteeModalTwoPopup({
            show: "INVITEE_MODAL",

        }))
    }

    const handleInviteeInfo = () => {
        dispatch(setStoreInviteeInfoModal({
            show: "INVITEE_INFO_MODAL",
        }))
    }
    const onChangeFilter = (e) => {
        if (e.target.name === "search") {
            setSearch(e.target.value)
            setShowOptions(true);
        } else {
            props?.setFilter((data) => ({
                ...data,
                [e.target.name]: e.target.value
            }));
        }
    }
    const callSearch = (data) => {
        setSearch(data);
    }

    const handleOptionClick = (option) => {
        setSearch(option);
        setShowOptions(false);
    };
    const handleSelect = (option) => {
        setSelectedOption(option);
        handleOptionClick(option);
        setShowOptions(false);
    };

    return (
        <Modal show={true} centered size="lg" className='new-appointment-modal-dialog'>
            <Scrollbar style={{ height: "calc(100vh - 20px)" }}>
                <Modal.Body className='modal-body new-appointment-modal'>
                    <div className='header-container'>
                        <h6 className='header-title'>{ModalPopup?.data?.AptTitle ? ModalPopup?.data?.AptTitle : "New Appointment"}</h6>
                        <div className='header-button-save-close'>
                            <Button variant={"primary save-appointment-btn"}>Save</Button>
                            <Icon onClick={handleClose} className={"ms-1 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                            {/* <Button onClick={handleClose} className='close-appointment-btn' variant={"primary"}><Icon className={"bg-white close-icon "} attrIcon={App_url.img.CloseIcon} /></Button> */}
                        </div>
                    </div>
                    {ModalPopup?.data?.event === "meeting" &&
                        <div className=' meetingevent '>
                            <Dropdown show={showOptions} onToggle={() => setShowOptions(!showOptions)} className=' meetingevent '>
                                <Dropdown.Toggle className='w-100' id="dropdown-basic">
                                    {selectedOption ? selectedOption.customer_name : "Search by company, person name"}
                                </Dropdown.Toggle>
                                <div className="custom-options-data ">
                                    <Dropdown.Menu>
                                        <h6 className="recent-searches-heading">Recent Searches</h6>
                                        {customersList?.map((option, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleSelect(option)}>
                                                <div className="custom-option">
                                                    <div className="circle-icon">
                                                        {Utils.formatNameSurnameFirstLetter(option.customer_name)}
                                                    </div>
                                                    <div className="option-text">
                                                        <div className="name">{option.customer_name}</div>
                                                        <div className="email">{option.email}</div>
                                                    </div>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </div>
                            </Dropdown>
                        </div>
                    }

                    <div className='company-info-box'>
                        <div className='company-name'>
                            <div className='company-icon'><Icon image attrIcon={App_url.img.CirclePlusIcon} /></div>
                            <div className='company-title'><h6>{customerDetails[0]?.company_name}</h6></div>
                        </div>
                        {ModalPopup?.data?.AptTitle !== "Personal Event" &&
                            <div className='company-email'>
                                <div className='company-icon'><Icon image attrIcon={App_url.img.BuildingIcon} /></div>
                                <div className='company-title'><h6>{customerDetails[0]?.email}</h6></div>
                            </div>
                        }
                        <div className='company-address'>
                            <div className='company-icon'><Icon image attrIcon={App_url.img.WorldLocationIcon} /></div>
                            <div className='company-title'><h6>{customerDetails[0]?.company_address + ' , ' + customerDetails[0]?.street_no + ' , ' + customerDetails[0]?.postal_code}</h6></div>
                        </div>
                    </div>
                    {ModalPopup?.data?.AptTitle !== "Personal Event" &&
                        <div className='user-info-box'>
                            <div className='user-name'>
                                <div className='user-icon'><Icon image attrIcon={App_url.img.UserPlusIcon} /></div>
                                <div className='user-title'><h6>{customerDetails[0]?.customer_name}</h6></div>
                            </div>
                            <div className='user-email'>
                                <div className='user-icon'><Icon image attrIcon={App_url.img.EmailIcon} /></div>
                                <div className='user-title'><h6>{customerDetails[0]?.email}</h6></div>
                            </div>
                        </div>
                    }
                    <div className='date-time-info-box'>
                        <div className='date-time-first'>
                            <div className='date-time-icon'><Icon image attrIcon={App_url.img.CalendarTwoIcon} /></div>
                            <div className='date-time-start-box'>
                                <h6 className='title'>Starts</h6>
                                <div className='custom-date-box'>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd MMM yyyy"
                                        placeholderText={"Start Date"}
                                        name={"start-date"}
                                        value={new Date()}
                                        className={"start-date "}
                                        wrapperClassName='custom-date-time-wrapper'
                                        minDate={new Date()}
                                    />
                                </div>
                                {!isAllDay && (
                                    <div className='custom-time-box'>
                                        <DatePicker
                                            selected={startTime}
                                            onChange={(time) => setStartTime(time)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            placeholderText="Select Time"
                                            className="custom-time-picker"
                                            wrapperClassName="custom-time-picker-wrapper"
                                        />
                                    </div>
                                )}
                                <h6 className='ms-2'>All-day</h6>
                                <InputGroup type="switch" name={"status"} checked={isAllDay} onCheck={(e) => setIsAllDay(!isAllDay)} />
                            </div>
                        </div>
                        <div className='date-time-second'>
                            <div className='date-time-icon'><Icon image attrIcon={App_url.img.CalendarTwoIcon} /></div>
                            <div className='date-time-end-box'>
                                <h6 className='title'>Ends</h6>
                                <div className='custom-date-box'>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="dd MMM yyyy"
                                        placeholderText={"Start Date"}
                                        name={"start-date"}
                                        value={new Date()}
                                        className={"start-date "}
                                        wrapperClassName='custom-date-time-wrapper'
                                        minDate={new Date()}
                                    />
                                </div>
                                {!isAllDay && (
                                    <div className='custom-time-box'>
                                        <DatePicker
                                            selected={endTime}
                                            onChange={(time) => setEndTime(time)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            placeholderText="Select Time"
                                            className="custom-time-picker"
                                            wrapperClassName="custom-time-picker-wrapper"
                                        />
                                    </div>
                                )}
                                <h6 className='ms-2'>Travel Time</h6>
                                <div className='date-time-custom-dropdown'>
                                    <Dropdown onSelect={handleTravelSelect}>
                                        <Dropdown.Toggle variant="light" className='custom-travel-time-dropdown-toggle'>
                                            <span>{travelTime}</span>
                                            <Icon className={"travel-time-arrow-icon"} image attrIcon={App_url.img.UpDownIcon} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {TravelTimeOptions && TravelTimeOptions.map(option => (
                                                <Dropdown.Item key={option?.value} eventKey={option?.value} style={{ paddingRight: '8px' }}>
                                                    <div className='check_menu' style={{ fontSize: '13px' }}>
                                                        {travelTime === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                        <span style={{ marginLeft: travelTime === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                    </div>
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='invitees-info-box'>
                        <div className='invitees-flex-box' onClick={handleInvitee}>
                            <div className='invitees-name'>
                                <div className='invitees-icon'><Icon image attrIcon={App_url.img.UserPlusIcon} /></div>
                                <div className='invitees-title'><h6>Invitees</h6></div>
                            </div>
                            <div className='invitees-count-box'>
                                {selectedInvitees?.length > 0 ? selectedInvitees?.length : "None"}
                                <Icon className={"ms-2"} attrIcon={App_url.img.UpDownIcon} />
                            </div>
                        </div>
                        {selectedInvitees?.length > 0 &&
                            <>
                                {selectedInvitees?.map((item) => (
                                    <div className='invitees-list-box'>
                                        <div className='invitees-name'>
                                            <h6 className='invitees-icon'>{Utils.formatNameSurnameFirstLetter(item?.label)}</h6>
                                        </div>
                                        <div className='invitees-list-i-box'>
                                            <div className='invitees-title'><h6>{item?.label}</h6></div>
                                            <Icon onClick={handleInviteeInfo} className={"ms-2 invitees-info-btn"} attrIcon={App_url.img.CircleInfoIcon} />
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                    </div>
                    <div className='repeat-info-box'>
                        <div className='repeat-flex-box'>
                            <div className='repeat-name'>
                                <div className='repeat-icon'><Icon image attrIcon={App_url.img.RepeatCircleIcon} /></div>
                                <div className='repeat-title'><h6>Repeat</h6></div>
                            </div>
                            <div className='repeat-dropdown-modal-container'>
                                <Dropdown onSelect={handleRepeatSelect}>
                                    <Dropdown.Toggle variant="light" className='custom-repeat-dropdown-toggle'>
                                        <span className='me-1'>{repeat}</span>
                                        <Icon className={"repeat-arrow-icon"} attrIcon={App_url.img.UpDownIcon} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {RepeatOptions && RepeatOptions.map(option => (
                                            <Dropdown.Item key={option?.value} eventKey={option?.value}>
                                                <div className='check_menu' style={{ fontSize: '13px' }}>
                                                    {repeat === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                    <span style={{ marginLeft: repeat === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {repeat !== "Never" &&
                            <>
                                <div className='repeat-hr-box text-center'>
                                    <hr className='repeat-hr' />
                                </div>

                                <div className='repeat-flex-box'>
                                    <div className='repeat-name'>
                                        <div className='repeat-icon'><Icon image attrIcon={App_url.img.RepeatCircleIcon} /></div>
                                        <div className='repeat-title'><h6>End Repeat</h6></div>
                                    </div>
                                    <div className='repeat-dropdown-modal-container'>
                                        <Dropdown onSelect={handleEndRepeatSelect}>
                                            <Dropdown.Toggle variant="light" className='custom-repeat-dropdown-toggle'>
                                                <span className='me-1'>{endRepeat}</span>
                                                <Icon className={"repeat-arrow-icon"} attrIcon={App_url.img.UpDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {EndRepeatOptions && EndRepeatOptions.map(option => (
                                                    <Dropdown.Item key={option?.value} eventKey={option?.value}>
                                                        <div className='check_menu' style={{ fontSize: '13px' }}>
                                                            {endRepeat === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                            <span style={{ marginLeft: endRepeat === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='alert-info-box'>
                        <div className='alert-flex-box'>
                            <div className='alert-name'>
                                <div className='alert-icon'><Icon image attrIcon={App_url.img.AlertIcon} /></div>
                                <div className='alert-title'><h6>Alert</h6></div>
                            </div>
                            <div className='alert-dropdown-modal-container'>
                                <Dropdown onSelect={handleAlertSelect}>
                                    <Dropdown.Toggle variant="light" className='custom-alert-dropdown-toggle'>
                                        <span className='me-1'>{alert}</span>
                                        <Icon className={"alert-arrow-icon"} attrIcon={App_url.img.UpDownIcon} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {AlertOptions && AlertOptions.map(option => (
                                            <Dropdown.Item key={option?.value} eventKey={option?.value}>
                                                <div className='check_menu' style={{ fontSize: '13px' }}>
                                                    {alert === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                    <span style={{ marginLeft: alert === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                        {alert !== "Never" &&
                            <>
                                <div className='alert-hr-box text-center'>
                                    <hr className='alert-hr' />
                                </div>

                                <div className='alert-flex-box'>
                                    <div className='alert-name'>
                                        <div className='alert-icon'><Icon image attrIcon={App_url.img.AlertIcon} /></div>
                                        <div className='alert-title'><h6>Second Alert</h6></div>
                                    </div>
                                    <div className='alert-dropdown-modal-container'>
                                        <Dropdown onSelect={handleSecondAlertSelect}>
                                            <Dropdown.Toggle variant="light" className='custom-alert-dropdown-toggle'>
                                                <span className='me-1'>{secondAlert}</span>
                                                <Icon className={"alert-arrow-icon"} attrIcon={App_url.img.UpDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {AlertOptions && AlertOptions.map(option => (
                                                    <Dropdown.Item key={option?.value} eventKey={option?.value}>
                                                        <div className='check_menu' style={{ fontSize: '13px' }}>
                                                            {secondAlert === option?.value && <Icon image attrIcon={App_url.img.RightIcon} />}
                                                            <span style={{ marginLeft: secondAlert === option?.value ? '0' : '20px' }} >{option?.value}</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='attachments-editor-box'>
                        <AttachmentEditor />
                    </div>

                </Modal.Body>
            </Scrollbar>
        </Modal>
    )
}
