import React from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Card } from 'react-bootstrap'
import Images from '../Common/Image'
import App_url from '../Common/Constants'
import { useNavigate } from 'react-router-dom'

const AddNewCalculation = () => {
    const navigate = useNavigate()
    const callBack = (e, state) => {
        navigate(App_url.link.savedCalculationCategory);
    }
    const fullCleaningCallBack = (e, state) => {
        navigate(App_url.link.savedCalculationFullCleaning);
    }
    const intervalCleaning = (e, state) => {
        navigate(App_url.link.savedCalculationIntervalCleaning);
    }
    return (
        <ContentLayout title={'Select Category'}>
            <div className='addCalculation'>
                <Card className='card m-2 '>
                    <Card.Body>
                        <div className='card-body-header'>
                            <div className='.item1  .flex-item'>
                                <h6 className='card-body-header-title'>Select Category</h6>
                            </div>
                        </div>
                        {/* <hr className='card-body-header-hr'></hr> */}
                        <div className='heading'>
                            <h6>Customer : Tata Motor</h6>
                        </div>
                        <div className='d-flex gap-3'>
                            <div class=" services" onClick={callBack}>
                                <Images className={'glass-cleaning'}  src={App_url.image.GlassCleaningImg} />
                                <div class="content">
                                    <h6>Glass Cleaning</h6>
                                </div>
                            </div>
                            <div class=" services" onClick={intervalCleaning}>

                                <Images className={'glass-cleaning'} src={App_url.image.IntervalCleaningImg} />
                                <div class="content">
                                    <h6>Interval Cleaning</h6>
                                </div>
                            </div>
                            <div class=" services" onClick={fullCleaningCallBack}>
                                <Images className={'glass-cleaning'}  src={App_url.image.FullCleaningImg} />
                                <div class="content">
                                    <h6>Full Cleaning</h6>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex gap-3 mt-4'>

                            <div class=" services">

                                <Images className={'glass-cleaning'} src={App_url.image.BuildingDivisionImg} />
                                <div class="content">
                                    <h6>Building Division</h6>
                                </div>
                            </div>
                            <div class=" services">

                                <Images className={'glass-cleaning'} src={App_url.image.MaintainenceCleaningImg} />
                                <div class="content">
                                    <h6>Maintenance Cleaning</h6>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </ContentLayout>
    )
}

export default AddNewCalculation